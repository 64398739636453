/** @jsxRuntime classic */

/** @jsx jsx */
import { Fragment, useContext, useState } from "react";

import { css, jsx } from "@emotion/react";

import { ReactComponent as AddIcon } from "../../../../../../assets/icons/Add.svg";
import DeleteIcon from "../../../../../../assets/icons/DeleteIcon";
import { ReactComponent as EditIcon } from "../../../../../../assets/icons/EditPen.svg";
import {
    AlertStatus,
    TriggerSwithValues,
} from "../../../../../../constants/constants";
import AlertContext from "../../../../../../context/alert/alertContext";
import LanguageContext from "../../../../../../context/language/languageContext";
import MenuItemContext from "../../../../../../context/menuItem/menuItemContext";
import { Trigger } from "../../../../../../generatedTypes";
import {
    firstLetterToLowercase,
    getDefaultValuesFromIoItem,
    replacePlaceholders,
    toFirstLowerCase,
} from "../../../../../../utils/helpers";
import useDebounce from "../../../../../../utils/useDebounce";
import Button from "../../../../../MuiComponents/Button";
import InputBlockWithActions from "../../../../../MuiComponents/InputBlockWithActions";
import ConfirmModal from "../../../../../MuiComponents/Modals/ConfirmModal";
import TriggerContext from "../context/triggerContext";

import LogicalSwitch from "./LogicalSwitch";
import TriggerModal from "./modals/TriggerModal";

export interface TriggerInputProps {
    data: Trigger;
    canBeRemoved?: boolean;
    index: number;
}

const TriggerInput: React.FunctionComponent<TriggerInputProps> = ({
    data,
    canBeRemoved,
    index,
}) => {
    const { updateParameter, updateParameters, parameters } =
        useContext(MenuItemContext);
    const { t } = useContext(LanguageContext);
    const { sourceCollection, setModalData } = useContext(TriggerContext);
    const { setAlert } = useContext(AlertContext);

    const [triggerModalOpenInfo, setTriggerModalOpenInfo] = useState({
        isOpen: false,
        isEdit: false,
    });

    const [removeConfirmationModalInfo, setRemoveConfirmationModalInfo] =
        useState({
            isOpen: false,
        });

    const isFirstTrigger = !data.active && !data.switchHidden;
    const initialSwitchValue =
        data.active?.parameterValue === TriggerSwithValues.On || isFirstTrigger;

    const [isSwitchChecked, setIsSwitchChecked] = useState(initialSwitchValue);

    const debouncedChangeHandler = useDebounce(updateParameter, 500);

    const isTriggerActive =
        parameters[data.source.parameterId] !== TriggerSwithValues.Off;

    const switchLabel =
        sourceCollection?.collectionItems?.find(
            (item: any) => item.value === parameters[data.source.parameterId],
        )?.text ?? "";

    const handleRemove = () => {
        const defaultValues = getDefaultValuesFromIoItem(data);
        try {
            updateParameters(defaultValues);
            setRemoveConfirmationModalInfo({ isOpen: false });
            setAlert(
                AlertStatus.Success,
                `${data?.name} ${toFirstLowerCase(t.SuccessfullyRemoved || "")}`,
            );
        } catch (err) {
            setAlert(AlertStatus.Critical, t.FailedToRemove);
        }
    };

    const actions = [
        {
            icon: <EditIcon />,
            label: t.Edit,
            onClick: () => {
                setModalData(data);
                setTriggerModalOpenInfo({ isOpen: true, isEdit: true });
            },
            disabledTooltipText: "",
        },
        ...(!isFirstTrigger
            ? [
                  {
                      icon: (
                          <DeleteIcon
                              css={css({
                                  width: "18px",
                                  height: "18px",
                              })}
                          />
                      ),
                      label: t.Remove,
                      onClick: () =>
                          setRemoveConfirmationModalInfo({ isOpen: true }),
                      disabledTooltipText: canBeRemoved
                          ? ""
                          : replacePlaceholders(
                                t.TriggerNeedsToBeRemoved,
                                index + 2,
                                data.name,
                            ),
                      isDisabled: !canBeRemoved,
                  },
              ]
            : []),
    ];

    const handleSwitchChange = async () => {
        if (data.active?.parameterId) {
            debouncedChangeHandler(
                data.active.parameterId,
                isSwitchChecked
                    ? TriggerSwithValues.Off
                    : TriggerSwithValues.On,
            );
            setIsSwitchChecked(!isSwitchChecked);
        }
        return () => {};
    };

    const renderContent = () => {
        if (isTriggerActive) {
            return (
                <Fragment>
                    {data.logic && <LogicalSwitch data={data.logic} />}
                    <InputBlockWithActions
                        actions={actions}
                        label={data.name ?? ""}
                        switchLabel={switchLabel}
                        checkedValue={isSwitchChecked}
                        onSwitchChange={
                            !data.switchHidden ? handleSwitchChange : undefined
                        }
                        isSwitchDisabled={isFirstTrigger}
                        disabledSwitchTooltip={
                            isFirstTrigger
                                ? `${data.name} ${t.CanNotBeDisabled}`
                                : ""
                        }
                        info={data.tooltip ?? ""}
                        valueLabel={data.switchHidden ? switchLabel : ""}
                    />
                </Fragment>
            );
        }
        return (
            <div
                css={css({
                    width: "100%",
                    display: "flex",
                    gap: "8px",
                    flexDirection: "column",
                    margin: "16px 0px",
                })}
            >
                <Button
                    size="normal"
                    color="secondary"
                    idForTesting="trigger"
                    variant="iconLeft"
                    icon={<AddIcon />}
                    onClick={() => {
                        setModalData(data);
                        setTriggerModalOpenInfo({
                            isOpen: true,
                            isEdit: false,
                        });
                    }}
                    fullWidth
                >
                    {t.Add + " " + data.name}
                </Button>
            </div>
        );
    };

    return (
        <Fragment>
            {renderContent()}

            {triggerModalOpenInfo.isOpen && (
                <TriggerModal
                    isOpen={triggerModalOpenInfo.isOpen}
                    onClose={() =>
                        setTriggerModalOpenInfo({
                            isOpen: false,
                            isEdit: false,
                        })
                    }
                    data={data}
                    isEdit={triggerModalOpenInfo.isEdit}
                />
            )}
            {removeConfirmationModalInfo.isOpen && (
                <ConfirmModal
                    open={removeConfirmationModalInfo.isOpen}
                    close={() =>
                        setRemoveConfirmationModalInfo({
                            isOpen: false,
                        })
                    }
                    submit={handleRemove}
                    title={`${t.Remove} ${firstLetterToLowercase(data.name)}`}
                    description={replacePlaceholders(
                        t.RemoveCustomScenarioItemConfirmation,
                        firstLetterToLowercase(data.name),
                    )}
                    secondaryBtnLabel={t.Close}
                />
            )}
        </Fragment>
    );
};

export default TriggerInput;
