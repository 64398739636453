import React from "react";

import { DialogContent } from "@mui/material";

import CodeInput from "../CodeInput";

interface SharedPinDialogContentProps {
    errorMsg: string | null;
    retriesLeft: number | null;
    step: number;
    explanatoryText: string;
    handlePayload: (value: string | null) => void;
}

const SharedPinDialogContent = ({
    errorMsg,
    retriesLeft,
    step,
    explanatoryText,
    handlePayload,
}: SharedPinDialogContentProps) => {
    return (
        <DialogContent>
            <CodeInput
                error={errorMsg}
                retriesLeft={retriesLeft}
                explanatoryText={explanatoryText}
                step={step}
                setPayloadValue={handlePayload}
            />
        </DialogContent>
    );
};

export default SharedPinDialogContent;
