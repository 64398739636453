/** @jsxRuntime classic */

/** @jsx jsx */
import { useContext } from "react";

import { css, jsx } from "@emotion/react";

import PriorityHighIcon from "@mui/icons-material/PriorityHigh";

import CheckWhiteIcon from "../../../assets/icons/CheckWhiteIcon";
import ThemeContext from "../../../context/theme/themeContext";

export interface CustomStepProps {
    active: boolean;
    stepIsFinished?: boolean;
    stepHasError?: boolean;
}

const CustomStep: React.FunctionComponent<CustomStepProps> = ({
    active,
    stepIsFinished,
    stepHasError,
}) => {
    const {
        colors: { textDarkDisabled, white, blue700, red700 },
    } = useContext(ThemeContext);

    const renderStepContent = () => {
        if (stepHasError) {
            return (
                <div
                    css={css({
                        display: "flex",
                        borderRadius: "50%",
                    })}
                >
                    <PriorityHighIcon css={css({ color: red700 })} />
                </div>
            );
        }
        if (stepIsFinished) {
            return (
                <div
                    css={css({
                        display: "flex",
                        backgroundColor: blue700,
                        borderRadius: "50%",
                    })}
                >
                    <CheckWhiteIcon />
                </div>
            );
        }
        return (
            <span
                css={css({
                    width: "10px",
                    height: "10px",
                    display: "block",
                    backgroundColor: active ? blue700 : textDarkDisabled,
                    borderRadius: "50%",
                })}
            />
        );
    };

    const renderBorderColor = () => {
        if (stepHasError) {
            return red700;
        }
        if (active || stepIsFinished) {
            return blue700;
        }
        return textDarkDisabled;
    };

    const renderBackgroundColor = () => {
        if (stepHasError) {
            return white;
        }
        if (stepIsFinished) {
            return blue700;
        }
        return white;
    };

    return (
        <div
            css={css({
                color: active ? blue700 : textDarkDisabled, // Blue color
                borderColor: renderBorderColor(), // Blue border
                borderWidth: "2px", // Border width
                borderStyle: "solid", // Border style
                padding: "2px", // White space
                backgroundColor: renderBackgroundColor(),
                width: "24px",
                height: "24px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 3,
            })}
        >
            {renderStepContent()}
        </div>
    );
};

export default CustomStep;
