/** @jsxRuntime classic */

/** @jsx jsx */
import { useContext } from "react";

import { css, jsx } from "@emotion/react";

import { DialogActions } from "@mui/material";

import LanguageContext from "../../../../../../../context/language/languageContext";
import { chooseValue } from "../../../../../../../utils/helpers";
import Button from "../../../../../../MuiComponents/Button";

interface SharedPinActionsProps {
    isLoading: boolean;
    isDisabled: boolean;
    submitIdForTesting: string;
    cancelIdForTesting: string;
    btnRef?: React.RefObject<HTMLButtonElement>;
    handleClose: () => void;
    sendPin: () => Promise<void> | void;
    renderButtonText: () => any;
}

const SharedPinActions = ({
    isLoading,
    isDisabled,
    submitIdForTesting,
    cancelIdForTesting,
    btnRef,
    handleClose,
    sendPin,
    renderButtonText,
}: SharedPinActionsProps) => {
    const { t } = useContext(LanguageContext);

    return (
        <DialogActions
            sx={{
                "&.MuiDialogActions-root>:not(:first-of-type)": {
                    marginLeft: "12px",
                },
            }}
        >
            <Button
                variant="textOnly"
                size="normal"
                color="secondary"
                fullWidth
                onClick={handleClose}
                idForTesting={cancelIdForTesting}
            >
                {t.Cancel}
            </Button>

            <Button
                ref={btnRef || null}
                variant="textOnly"
                size="normal"
                color="primary"
                fullWidth
                disabled={isDisabled}
                onClick={sendPin}
                css={css({
                    pointerEvents: chooseValue(isLoading, "none", "auto"),
                })}
                idForTesting={submitIdForTesting}
            >
                {renderButtonText()}
            </Button>
        </DialogActions>
    );
};

export default SharedPinActions;
