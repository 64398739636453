import React, { useContext } from "react";

import MenuItemContext from "../../../../../../context/menuItem/menuItemContext";
import {
    Block,
    Component,
    GenericTable,
    IoRow,
    RowBase,
    Segment,
} from "../../../../../../generatedTypes";
import { arrayToIndexedObject } from "../../../../../../utils/helpers";
import IoTableToolbar, { IoToolbarSettings } from "../IoTable/IoTableToolbar";

export const useIoTableItemRender = () => {
    const { parameters } = useContext(MenuItemContext);

    const renderToolbar = (
        index: number,
        toolbarSettings: IoToolbarSettings,
    ) => {
        if (index === 0) {
            return <IoTableToolbar toolbarSettings={toolbarSettings} />;
        }
        return null;
    };

    const getRegularIoItemData = (block: Block, segment: Segment | IoRow) => {
        if (!segment.ioItem) {
            throw new Error(
                `Cannot render segment in block ${block.name} (index: ${block.index}) because ioItem is required for IoFrame`,
            );
        }

        return {
            name: segment.ioItem.name,
            description: segment.ioItem.description,
            avlId: segment.ioItem.avlId,
            parameterValue: {
                id: segment.ioItem.ioId,
                switchValue:
                    parameters[segment.ioItem.ioId] !== "0" ? "1" : "0",
            },
            eventOnly: segment.ioItem.eventOnly && {
                ...segment.ioItem.eventOnly,
                parameterValue:
                    parameters[segment.ioItem.eventOnly.parameterId],
            },
            highLevel: segment.ioItem.highLevel && {
                ...segment.ioItem.highLevel,
                parameterValue:
                    parameters[segment.ioItem.highLevel.parameterId],
            },
            lowLevel: segment.ioItem.lowLevel && {
                ...segment.ioItem.lowLevel,
                parameterValue: parameters[segment.ioItem.lowLevel.parameterId],
            },
            operand: segment.ioItem.operand && {
                ...segment.ioItem.operand,
                parameterValue: parameters[segment.ioItem.operand.parameterId],
            },
            phone: segment.ioItem.phone && {
                ...segment.ioItem.phone,
                parameterValue: parameters[segment.ioItem.phone.parameterId],
            },
            priority: {
                ...segment.ioItem.priority,
                parameterValue:
                    parameters[segment.ioItem.priority?.parameterId ?? ""],
            },
            text: segment.ioItem.text && {
                ...segment.ioItem.text,
                parameterValue: parameters[segment.ioItem.text.parameterId],
            },
            averagingConstant: segment.ioItem.averagingConstant && {
                ...segment.ioItem.averagingConstant,
                parameterValue:
                    parameters[segment.ioItem.averagingConstant.parameterId],
            },
            canId: undefined,
        };
    };

    const getCanIoItemData = (block: Block, segment: Segment | IoRow) => {
        if (!segment.canItem) {
            throw new Error(
                `Cannot render segment in block ${block.name} (index: ${block.index}) because ioItem is required for IoFrame`,
            );
        }

        return {
            name: segment.canItem.name,
            description: "",
            avlId: segment.canItem.avlId,
            parameterValue: {
                id: segment.canItem.ioId,
                switchValue:
                    parameters[segment.canItem.ioId] !== "0" ? "1" : "0",
            },
            canSrc: segment.canItem.canSrc && {
                ...segment.canItem.canSrc,
                parameterValue: parameters[segment.canItem.canSrc.parameterId],
            },
            canType: segment.canItem.canType && {
                ...segment.canItem.canType,
                parameterValue: parameters[segment.canItem.canType.parameterId],
            },
            canId: segment.canItem.canId && {
                ...segment.canItem.canId,
                parameterValue: parameters[segment.canItem.canId.parameterId],
            },
            dataMask: segment.canItem.dataMask && {
                ...segment.canItem.dataMask,
                parameterValue:
                    parameters[segment.canItem.dataMask.parameterId],
            },
            priority: {
                ...segment.canItem.priority,
                parameterValue:
                    parameters[segment.canItem.priority?.parameterId ?? ""],
            },
            operand: segment.canItem.operand && {
                ...segment.canItem.operand,
                parameterValue: parameters[segment.canItem.operand.parameterId],
            },
        };
    };

    const getGenericTableItemData = (block: Block, tableData: GenericTable) => {
        const rows = tableData.rows;
        if (!tableData) {
            throw new Error(
                `Cannot render segment in block ${block.name} (index: ${block.index}) because genericTable is required for IoFrame`,
            );
        }

        const rowsWithAvlId = rows.map((row: RowBase) => {
            return {
                components: row.components.map((component) => ({
                    ...component,
                    avlId: row.avlId ?? 0,
                })),
            };
        });

        const formattedForTableRows: { [key: string]: any }[] =
            rowsWithAvlId.map((row: { components: Component[] }) => {
                return arrayToIndexedObject(row.components);
            });

        return formattedForTableRows;
    };

    return {
        renderToolbar,
        getCanIoItemData,
        getRegularIoItemData,
        getGenericTableItemData,
    };
};
