import React, {
    ReactNode,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";

import { atom, useSetAtom } from "jotai";

import {
    AlertStatus,
    FirmwareType,
    FirmwareUpdateStages,
} from "../../constants/constants";
import {
    DeviceStatusInformation,
    ErrorMessage,
    TctUpdateInfo,
} from "../../generatedTypes";
import { chooseValue } from "../../utils/helpers";
import { DiscoveredDeviceData, RecordReadStatus } from "../../utils/types";
import useConfiguration from "../../utils/useConfiguration";
import useWebSockets from "../../utils/useWebSockets";
import AlertContext from "../alert/alertContext";
import DeviceAuthorizationContext from "../deviceAuthorization/deviceAuthorizationContext";
import DeviceStatusContext, {
    BundleProgress,
} from "../deviceStatus/deviceStatusContext";
import LanguageContext from "../language/languageContext";
import LayoutContext from "../layout/layoutContext";
import SettingsContext from "../settings/settingsContext";
import UserInfoContext from "../userInfo/userInfoContext";

import WebSocketsContext from "./webSocketsContext";

export interface WebSocketsStateProps {
    children: ReactNode;
}

export type FirmwareUpdateData = {
    successfull: boolean;
    message?: string;
};

export interface FirmwareUpdateResult {
    isSuccess: boolean;
}

export interface TctUpdateAdditionalProps {
    isViewed: boolean;
    isRead: boolean;
}

export interface WebSocketsStateData {
    updateFirmwareAsync: (
        fwImagePath: string,
        progressBarName: string,
    ) => Promise<any>;
    captureDumpAsync: () => Promise<any>;
    recordReadStatus: RecordReadStatus | null;
    setRecordReadStatus: React.Dispatch<
        React.SetStateAction<RecordReadStatus | null>
    >;
    updateDtbAsync: (path: string, progressBarName: string) => Promise<any>;
    updateModemAsync: (path: string, progressBarName: string) => Promise<any>;
}

export const DiscoveredDevicesAtom = atom([] as DiscoveredDeviceData[]);
export const DeviceStatusAtom = atom(null as DeviceStatusInformation | null);
export const IoStatusAtom = atom(null as any);
export const ActiveGsmDataModeAtom = atom(-1);

export const TctUpdateAvailableAtom = atom({
    available: false,
    releaseDate: "",
    size: "",
    version: "",
    isViewed: false,
    isRead: false,
} as TctUpdateInfo & TctUpdateAdditionalProps);

const WebSocketsState = ({ children }: WebSocketsStateProps) => {
    const {
        setUpdateDownloaded,
        setUpdateDownloading,
        setDownloadingPercentage,
        setNotification,
    } = useContext(SettingsContext);

    const { isDeviceDisconnected, setDeviceDisconnected } =
        useContext(UserInfoContext);

    const { setAlert } = useContext(AlertContext);

    const {
        setDumpReadingData,
        setDallasSensorData,
        setLlsSensorData,
        setProgressBar,
        setUpdateFirmwareModalInfo,
        updateFirmwareModalInfo,
        resetProgressBars,
        setBundleProgress,
        setUpdateBundleModalInfo,
    } = useContext(DeviceStatusContext);

    const { setOnlineLayout, layoutData, setLayoutError } =
        useContext(LayoutContext);

    const { t } = useContext(LanguageContext);

    const {
        openEnterPasswordModal: openContextEnterPasswordModal,
        openFreshUnlockDeviceModal,
    } = useContext(DeviceAuthorizationContext);

    const [recordReadStatus, setRecordReadStatus] = useState(
        null as RecordReadStatus | null,
    );

    const updateFirmwareModalInfoRef = useRef(updateFirmwareModalInfo);

    const { connection, startConnection, stopConnection } =
        useWebSockets("/applicationHub");

    const { getConfigurationFromDevice } = useConfiguration();

    updateFirmwareModalInfoRef.current = updateFirmwareModalInfo;

    const setDiscoveredDevices = useSetAtom(DiscoveredDevicesAtom);
    const setDeviceStatus = useSetAtom(DeviceStatusAtom);
    const setActiveGsmDataMode = useSetAtom(ActiveGsmDataModeAtom);
    const setIoCurrentValues = useSetAtom(IoStatusAtom);
    const setIsTctUpdateAvailable = useSetAtom(TctUpdateAvailableAtom);

    const getLayoutData = async (redirect: boolean, reconnect = false) => {
        try {
            await getConfigurationFromDevice(
                chooseValue(layoutData?.port, layoutData?.port, ""),
                Number(layoutData?.connectionType),
                reconnect,
            );
        } catch (error) {
            handleGetLayoutError(error, reconnect, redirect);
        }
    };

    const openEnterPasswordModal = (
        additionalInfo: any,
        shouldRedirectToStartupOnCancel: boolean,
    ) => {
        openContextEnterPasswordModal(
            String(layoutData?.port),
            Number(layoutData?.connectionType),
            getLayoutData,
            additionalInfo,
            shouldRedirectToStartupOnCancel,
        );
    };

    const openDeviceLockedModal = (code: string) => {
        openFreshUnlockDeviceModal(
            String(layoutData?.port),
            Number(layoutData?.connectionType),
            getLayoutData,
            code,
        );
    };

    const handleGetLayoutUnauthorizedError = (
        error: any,
        reconnect: boolean,
    ) => {
        const errorData = error.response.data;
        const authorizationInfo = JSON.parse(errorData.detail);
        errorData.requiresUnlock
            ? openDeviceLockedModal(errorData.unlockCode)
            : openEnterPasswordModal(authorizationInfo, reconnect);
    };

    const handleGetLayoutError = (
        error: any,
        reconnect: boolean,
        redirect: boolean,
    ) => {
        const errorStatus = error.response.status;

        if (errorStatus === 401) {
            handleGetLayoutUnauthorizedError(error, reconnect);
        } else {
            setLayoutError(error);
        }
        // redirect && refreshCurrentPage(); //Is this needed ?
    };

    const handleProgressBar = (data: any) => {
        setProgressBar(data);
        if (
            data.stage === FirmwareUpdateStages.DeviceReboot ||
            data.stage === FirmwareUpdateStages.ModemReboot
        ) {
            setUpdateFirmwareModalInfo({
                isOpen: true,
                path: updateFirmwareModalInfoRef.current?.path,
                step: 2,
                newVersion: updateFirmwareModalInfoRef.current?.newVersion,
                firmwareType: updateFirmwareModalInfoRef.current?.firmwareType,
                currentVersion:
                    updateFirmwareModalInfoRef.current?.currentVersion,
                error: "",
            });
        }
    };

    const handleBundleProgressBar = (data: BundleProgress) => {
        setBundleProgress(data);
    };

    const handleFirmwareUpdateGeneric = async (
        data: FirmwareUpdateData,
        firmwareType: FirmwareType,
        additionalAction?: () => Promise<void>,
    ) => {
        if (additionalAction) {
            await additionalAction();
        }

        const modalInfo = {
            isOpen: true,
            path: data.successfull
                ? ""
                : updateFirmwareModalInfoRef.current?.path,
            step: 3,
            newVersion: data.successfull
                ? ""
                : updateFirmwareModalInfoRef.current?.newVersion,
            firmwareType,
            currentVersion: data.successfull
                ? ""
                : updateFirmwareModalInfoRef.current?.currentVersion,
            error: data.successfull ? "" : data.message,
            ...(data.successfull
                ? {}
                : { stepWithError: updateFirmwareModalInfoRef.current?.step }),
        };

        setUpdateFirmwareModalInfo(modalInfo);
        resetProgressBars();
        if (!data.successfull && data.message) {
            setNotification("error", t.Error, data.message);
        }
    };

    const handleDtbUpdate = (data: FirmwareUpdateData) => {
        handleFirmwareUpdateGeneric(data, FirmwareType.Dtb);
    };

    const handleModemUpdate = (data: FirmwareUpdateData) => {
        handleFirmwareUpdateGeneric(data, FirmwareType.Modem);
    };

    const handleFirmwareUpdate = (data: FirmwareUpdateData) => {
        handleFirmwareUpdateGeneric(data, FirmwareType.Firmware, () =>
            getLayoutData(false, true),
        );
    };

    const handleBundleFirmwareUpdate = async (data: FirmwareUpdateData) => {
        await getLayoutData(false, true);

        setUpdateBundleModalInfo({
            isSuccessful: data.successfull,
            isFailed: !data.successfull,
        } as any);
    };

    useEffect(() => {
        if (connection) {
            if (isDeviceDisconnected) {
                connection.off("NotifyConnectionLost");
            }
            connection.on("AvailableDevicesUpdate", ({ devices }) => {
                setDiscoveredDevices(devices);
            });
            connection.on("AutoUpdatePercentage", (percentage) => {
                setDownloadingPercentage(percentage);
            });
            connection.on("UpdateProgressBar", (data) => {
                handleProgressBar(data);
            });
            connection.on("UpdateBundleProgressBar", (data) => {
                handleBundleProgressBar(data);
            });
            connection.on("DtbUpdateResult", (data) => {
                handleDtbUpdate(data);
            });
            connection.on("ModemUpdateResult", (data) => {
                handleModemUpdate(data);
            });
            connection.on("AutoUpdateDownloaded", () => {
                setUpdateDownloading(false);
                setUpdateDownloaded();

                setIsTctUpdateAvailable((prevState) => ({
                    ...prevState,
                    isViewed: false,
                    isRead: false,
                }));
            });
            connection.on("ReceiveStatusUpdate", (data) =>
                setDeviceStatus(() => data),
            );
            connection.on("ReceiveDataModeUpdate", (columnIndex) => {
                setActiveGsmDataMode(columnIndex);
            });
            connection.on("NotifyConnectionLost", (_data) => {
                setAlert(
                    AlertStatus.Warning,
                    t.ConnectionToDeviceLostTitle,
                    t.ConnectionToDeviceLostDescription,
                );
                setNotification(
                    AlertStatus.Warning,
                    t.ConnectionToDeviceLostTitle,
                    t.ConnectionToDeviceLostDescription,
                );
                setDeviceDisconnected(true);
                setOnlineLayout(false);
            });
            connection.on("FirmwareUpdateResult", (data) => {
                handleFirmwareUpdate(data);
            });
            connection.on("FirmwareBundleUpdateResult", (data) => {
                handleBundleFirmwareUpdate(data);
            });
            connection.on("CaptureDumpResult", (data: string) => {
                if (data) {
                    console.log(
                        "CaptureDumpResult returned with error: ",
                        data,
                    );
                } else {
                    console.log("CaptureDumpResult signal received");
                }
            });
            connection.on("DownloadChunkUpdate", (data: number) => {
                setDumpReadingData({
                    percents: data,
                });
            });
            connection.on(
                "DownloadFileUpdate",
                (data: { currentFile: number; filesCount: number }) => {
                    setDumpReadingData(data);
                },
            );
            connection.on("ReceiveDallasSensorsUpdate", (data) => {
                setDallasSensorData(data);
            });
            connection.on("ReceiveLlsSensorsUpdate", (data) => {
                setLlsSensorData(data);
            });
            connection.on("ReceiveIoUpdate", (ioData) => {
                setIoCurrentValues(JSON.parse(ioData));
            });
            connection.on("RichTextNotification", (data) => {
                const formatedData = JSON.parse(data);
                formatedData &&
                    setNotification(
                        formatedData.type.toLowerCase(),
                        "",
                        "",
                        formatedData.body,
                    );
            });

            connection.on("ReceiveIsTctUpdateAvailable", (data) => {
                data["isRead"] = false;
                data["isViewed"] = false;
                setIsTctUpdateAvailable(data);
            });

            connection.on(
                "RecordReadStatus",
                (recordStatus: RecordReadStatus | null) => {
                    console.log(
                        `downloaded: ${JSON.stringify(recordStatus)}% `,
                    );
                    setRecordReadStatus(recordStatus);
                },
            );

            connection.on("AutoUpdateFailed", (errorMessage: ErrorMessage) => {
                setAlert(
                    AlertStatus.Critical,
                    errorMessage.title,
                    errorMessage.message,
                );
                setUpdateDownloading(false);
                setDownloadingPercentage(0);
            });

            startConnection();
        }

        return () => {
            stopConnection();
            setIoCurrentValues({});
        };

        // eslint-disable-next-line
    }, [connection, isDeviceDisconnected]);

    const updateFirmwareAsync = (
        fwImagePath: string,
        progressBarName: string,
    ) => {
        if (connection) {
            return connection.invoke(
                "UpdateFirmware",
                fwImagePath,
                progressBarName,
            );
        }
        throw new ReferenceError(
            `Cannot invoke 'UpdateFirmware'. Web sockets connection is ${connection}`,
        );
    };

    const captureDumpAsync = () => {
        if (connection) {
            return connection.invoke("CaptureDump");
        }
        throw new ReferenceError(
            `Cannot invoke 'CaptureDump'. Web sockets connection is ${connection}`,
        );
    };

    const updateDtbAsync = (path: string, progressBarName: string) => {
        if (connection) {
            return connection.invoke("UpdateDtb", path, progressBarName);
        }
        throw new ReferenceError(
            `Cannot invoke 'UpdateDtb'. Web sockets connection is ${connection}`,
        );
    };

    const updateModemAsync = (path: string, progressBarName: string) => {
        if (connection) {
            return connection.invoke("UpdateModem", path, progressBarName);
        }
        throw new ReferenceError(
            `Cannot invoke 'UpdateModem'. Web sockets connection is ${connection}`,
        );
    };

    const soketState = useMemo(() => {
        return {
            updateFirmwareAsync,
            updateModemAsync,
            captureDumpAsync,
            recordReadStatus,
            setRecordReadStatus,
            updateDtbAsync,
        };
    }, [
        connection,
        updateFirmwareAsync,
        captureDumpAsync,
        recordReadStatus,
        updateDtbAsync,
        updateModemAsync,
    ]);

    return (
        <WebSocketsContext.Provider value={soketState}>
            {children}
        </WebSocketsContext.Provider>
    );
};

export default WebSocketsState;
