import React, { ReactNode } from "react";

import { SerializedStyles } from "@emotion/react";

import { MenuItem } from "@mui/material";

import { noop } from "../../../../utils/helpers";
import TooltipLarge from "../../TooltipLarge";

interface CollectionMenuItemProps {
    disabled: boolean;
    children: ReactNode;
    value: any;
    tooltipTitle?: string;
    tooltipButtonText?: string;
    css?: SerializedStyles;
    tooltipOnBtnClick?: () => void;
    handleOnClick?: (value: any) => void;
}

const CollectionMenuItem = ({
    disabled,
    children,
    value,
    tooltipTitle,
    tooltipButtonText,
    tooltipOnBtnClick,
    handleOnClick,
    ...props
}: CollectionMenuItemProps) => {
    if (disabled) {
        return (
            <TooltipLarge
                noMargin
                placement="top-start"
                title={tooltipTitle ?? ""}
                buttonText={tooltipButtonText ?? ""}
                onBtnClick={tooltipOnBtnClick || noop}
            >
                <li>
                    <MenuItem
                        disabled={disabled}
                        {...props}
                        {...(handleOnClick && {
                            onClick: () => handleOnClick(value),
                        })}
                        component="div"
                    >
                        {children}
                    </MenuItem>
                </li>
            </TooltipLarge>
        );
    }
    return (
        <MenuItem
            {...props}
            {...(handleOnClick && { onClick: () => handleOnClick(value) })}
        >
            {children}
        </MenuItem>
    );
};
export default CollectionMenuItem;
