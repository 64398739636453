/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx } from "@emotion/react";

import {
    IoTableColumnTitles,
    IoTableColumns,
} from "../../../../../../../constants/constants";
import DataMask from "../components/DataMask";
import TableCollection from "../components/TableCollection";
import TableInput from "../components/TableInput";

import { sharedColumns } from "./sharedColumns";

export const getCanColumns = (name: string, data: any) => {
    const canSpecificColumns = [
        {
            index: 2,
            id: IoTableColumns.canSrc,
            accessor: IoTableColumns.canSrc,
            Header: IoTableColumnTitles.canSrc,
            show: data?.some((item: any) => item.canSrc),
            Cell: ({ row }: any) => {
                return row.original.canSrc ? (
                    <TableCollection
                        data={row.original.canSrc}
                        switchId={row.original.parameterValue.id}
                        key={row.original.canSrc.id}
                        onOptionClick={(e) =>
                            row.setState((state: any) => {
                                return {
                                    cellState: {
                                        ...state?.cellState,
                                        canSrc: e,
                                    },
                                };
                            })
                        }
                    />
                ) : null;
            },
        },

        {
            index: 3,
            id: IoTableColumns.canType,
            accessor: IoTableColumns.canType,
            Header: IoTableColumnTitles.canType,
            show: data?.some((item: any) => item.canType),
            Cell: ({ row }: any) => {
                return row.original.canType ? (
                    <TableCollection
                        data={row.original.canType}
                        switchId={row.original.parameterValue.id}
                        key={row.original.canType.id}
                        onOptionClick={(e) =>
                            row.setState((state: any) => {
                                return {
                                    cellState: {
                                        ...state?.cellState,
                                        canType: e,
                                    },
                                };
                            })
                        }
                    />
                ) : null;
            },
        },
        {
            index: 4,
            id: IoTableColumns.canId,
            accessor: IoTableColumns.canId,
            Header: IoTableColumnTitles.canId,
            show: true,
            Cell: ({ row }: any) => {
                return row.original.canId ? (
                    <TableInput
                        switchId={row.original.parameterValue.id}
                        data={row.original.canId}
                        key={row.original.parameterValue.id}
                        inputType="text"
                    />
                ) : null;
            },
        },
        {
            index: 5,
            id: IoTableColumns.dataMask,
            accessor: IoTableColumns.dataMask,
            Header: IoTableColumnTitles.dataMask,
            show: data?.some((item: any) => item.dataMask),
            minWidth: 340,
            width: "100%",
            maxWidth: 340,
            Cell: ({ row }: any) => {
                return row.original.dataMask ? (
                    <DataMask
                        data={row.original.dataMask}
                        switchId={row.original.parameterValue.id}
                    />
                ) : null;
            },
        },
    ];

    const sortedColumns = [
        ...sharedColumns(name, true, data),
        ...canSpecificColumns,
    ].sort((a, b) => a.index - b.index);

    return sortedColumns.filter((item) => item.show);
};

export const getCanFilters = (activeOperand: any[], activeStatus: string) => [
    {
        id: IoTableColumns.operand,
        value: activeOperand.length ? activeOperand : undefined,
    },
    {
        id: IoTableColumns.name,
        value: activeStatus !== "2" ? activeStatus : undefined,
    },
];
