/** @jsxRuntime classic */

/** @jsx jsx */
import { Row, UseTableRowProps } from "react-table";

import { jsx } from "@emotion/react";

import {
    IoTableColumnTitles,
    IoTableColumns,
} from "../../../../../../../constants/constants";
import { chooseValue } from "../../../../../../../utils/helpers";
import DescriptionWithSwitch from "../components/DescriptionWithSwitch";
import TableCollection from "../components/TableCollection";

const getNameFilterValue = (allRows: Row<any>[], filterValue: string) => {
    return allRows.filter((row: any) => {
        if (row.state?.cellState?.disabled !== undefined) {
            const disabledValue = row.state?.cellState?.disabled ? "0" : "1";
            return disabledValue === filterValue;
        }
        return row.original.parameterValue?.switchValue === filterValue;
    });
};

const getPriorityFilterValue = (
    allRows: UseTableRowProps<any>[],
    filterValue: any,
) => {
    return allRows.filter((row: any) => {
        if (row.state?.cellState?.priorityItem !== undefined) {
            return filterValue.some(
                (value: string) => value === row.state?.cellState?.priorityItem,
            );
        }
        return filterValue.some(
            (value: string) => value === row.original.priority.parameterValue,
        );
    });
};

export const sharedColumns = (
    name: string,
    isCanTable: boolean,
    data?: any,
) => [
    {
        index: 0,
        id: IoTableColumns.name,
        accessor: IoTableColumns.name,
        Header: name,
        minWidth: 470,
        show: isCanTable,
        filter: (allRows: Row<any>[], _: any[], filterValue: string) =>
            getNameFilterValue(allRows, filterValue),
        Cell: ({ row, allColumns, ...props }: any) => {
            return (
                <DescriptionWithSwitch
                    otherProps={props}
                    row={row}
                    allColumns={allColumns}
                    onChange={(e) =>
                        row.setState((state: any) => {
                            const newState = {
                                cellState: {
                                    ...state.cellState,
                                    disabled: !e,
                                },
                            };
                            if (!isCanTable) {
                                newState.cellState.priorityItem = chooseValue(
                                    !e,
                                    "0",
                                    "1",
                                );
                            }
                            return newState;
                        })
                    }
                />
            );
        },
    },

    {
        index: chooseValue(isCanTable, 1, 2),
        id: IoTableColumns.priority,
        accessor: IoTableColumns.priority,
        Header: IoTableColumnTitles.priority,
        ...(isCanTable
            ? {
                  show: data?.some((item: any) => item.priority),
              }
            : {
                  filter: (
                      allRows: UseTableRowProps<any>[],
                      _: any,
                      filterValue: any,
                  ) => getPriorityFilterValue(allRows, filterValue),
              }),
        Cell: ({ row }: any) => {
            return row.original.priority ? (
                <TableCollection
                    data={row.original.priority}
                    switchId={row.original.parameterValue.id}
                    key={row.original.parameterValue.id}
                    onOptionClick={(e) =>
                        row.setState((state: any) => {
                            return {
                                cellState: {
                                    ...state?.cellState,
                                    priorityItem: e,
                                },
                            };
                        })
                    }
                />
            ) : null;
        },
    },

    {
        index: chooseValue(isCanTable, 6, 3),
        id: IoTableColumns.operand,
        accessor: IoTableColumns.operand,
        Header: IoTableColumnTitles.operand,
        show: isCanTable,
        filter: (
            allRows: UseTableRowProps<any>[],
            _: any[],
            filterValue: any,
        ) => {
            return allRows.filter((row: any) => {
                if (row.state?.cellState?.operandType !== undefined) {
                    return filterValue.some(
                        (value: string) =>
                            value === row.state?.cellState?.operandType,
                    );
                }
                return filterValue.some(
                    (value: string) =>
                        value === row.original.operand.parameterValue,
                );
            });
        },
        Cell: ({ row }: any) => {
            return row.original.operand ? (
                <TableCollection
                    data={row.original.operand}
                    switchId={row.original.parameterValue.id}
                    key={row.original.parameterValue.id}
                    onOptionClick={(e) =>
                        row.setState((state: any) => {
                            return {
                                cellState: {
                                    ...state?.cellState,
                                    operandType: e,
                                },
                            };
                        })
                    }
                />
            ) : null;
        },
    },
];
