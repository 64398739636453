/** @jsxRuntime classic */

/** @jsx jsx */
import { useContext } from "react";

import { jsx } from "@emotion/react";

import { AlertStatus } from "../../../../constants/constants";
import AlertContext from "../../../../context/alert/alertContext";
import DeviceStatusContext from "../../../../context/deviceStatus/deviceStatusContext";
import LanguageContext from "../../../../context/language/languageContext";
import { FirmwareBundleUpdateStep } from "../../../../generatedTypes";
import { formatBundleUpdateSteps } from "../../../../utils/helpers";
import useApi from "../../../../utils/useApi";
import FormModal from "../FormModal";

import BundleUpdateContext from "./context/bundleUpdateContext";
import InfoStep from "./items/steps/InfoStep/InfoStep";
import IntroStep from "./items/steps/IntroStep";

const BundleUpdateModal = () => {
    const { selectedLanguage, t } = useContext(LanguageContext);
    const { setError, file } = useContext(BundleUpdateContext);
    const {
        updateBundleModalInfo,
        setUpdateBundleModalInfo,
        setBundleUpdateProgressSteps,
        bundleModalProgressSteps,
    } = useContext(DeviceStatusContext);
    const { setAlert } = useContext(AlertContext);

    const isOpen = updateBundleModalInfo.isOpen;
    const step = updateBundleModalInfo.step;
    const isSuccessful = updateBundleModalInfo.isSuccessful;
    const isFailed = updateBundleModalInfo.isFailed;
    const { postData } = useApi();

    const firmwareStepsWithUpdate = bundleModalProgressSteps.filter(
        (step: FirmwareBundleUpdateStep) => step.shouldUpdate,
    );
    const formatDataForRequest = formatBundleUpdateSteps(
        firmwareStepsWithUpdate,
    );

    const firmwareBundleUpdateInfoAsString = JSON.stringify({
        updateSteps: formatDataForRequest,
    });

    const handleSubmit = async () => {
        if (!file) return;

        try {
            setUpdateBundleModalInfo({
                isOpen: true,
                step: 1,
            });
            setBundleUpdateProgressSteps(null, firmwareStepsWithUpdate);

            const formData = new FormData();

            formData.append("File", file.file.get(file.filename) as Blob);
            formData.append(
                "FirmwareBundleUpdateInfoAsString",
                firmwareBundleUpdateInfoAsString,
            );

            await postData(
                `${selectedLanguage}/device/updatefirmware`,
                formData,
            );
        } catch (error: any) {
            setUpdateBundleModalInfo({
                isSuccessful: false,
                isFailed: true,
            } as any);
            const err = error.response?.data;
            setAlert(AlertStatus.Critical, err.detail);
            setError(err.detail);
        }
    };

    const renderContent = () => {
        if (step === 0) {
            return <IntroStep />;
        }
        return <InfoStep />;
    };

    const isFirmwareUpdateStepSuccessful = isSuccessful;

    const handleClose = () => {
        setUpdateBundleModalInfo({
            isOpen: false,
            step: 0,
            isSuccessful: false,
            isFailed: false,
        });
        setBundleUpdateProgressSteps(null, []);
    };

    const isSecondaryButtonDisabled = () => {
        if (step === 0) {
            return false;
        }
        if (step !== 0 && (isFirmwareUpdateStepSuccessful || isFailed)) {
            return false;
        }
        return true;
    };

    const isPrimaryButtonDisabled = () => {
        if (firmwareStepsWithUpdate.length === 0) {
            return true;
        }
        if (step === 0 && !file) {
            return true;
        }
    };

    const renderTooltip = () => {
        if (step === 0 && !file) {
            return t.SelectFile;
        }
        if (firmwareStepsWithUpdate.length === 0) {
            return t.NoFirmwareSelected;
        }
        return "";
    };

    return (
        <FormModal
            isOpen={isOpen}
            onClose={step === 0 ? handleClose : undefined}
            onSecondaryButtonClick={
                !isSecondaryButtonDisabled() ? handleClose : undefined
            }
            title={t.FirmwareBundleUpdate}
            description={t.FirmwareBundleUpdateDescription}
            primaryButtonText={step === 0 ? t.Update : ""}
            secondaryButtonText={t.Close}
            isPrimaryButtonDisabled={isPrimaryButtonDisabled()}
            isSecondaryButtonDisabled={isSecondaryButtonDisabled()}
            onSubmit={handleSubmit}
            customModalStyles={{ maxWidth: "684px" }}
            disabledTooltip={{ title: renderTooltip() }}
        >
            {renderContent()}
        </FormModal>
    );
};

export default BundleUpdateModal;
