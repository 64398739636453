/** @jsxRuntime classic */

/** @jsx jsx */
import { useContext, useState } from "react";

import { css, jsx } from "@emotion/react";

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";

import ThemeContext from "../../../../../../../context/theme/themeContext";
import InputField from "../../../../../../MuiComponents/InputField";
import Tag from "../../../../../../MuiComponents/Tag";
import Tooltip from "../../../../../../MuiComponents/Tooltip";
import TimeInput from "../TimeInput";
import { ConvertedCellValue, DesktopScheduleTableProps } from "../types";

const DesktopScheduleTable = ({
    columns,
    tableRows,
    getRowValues,
    renderRowIds,
}: DesktopScheduleTableProps) => {
    const {
        colors: { blue100, blue200, gray300, textDark, gray100 },
    } = useContext(ThemeContext);

    const [isDragging, setIsDragging] = useState(false);

    const renderCell = (
        cell: any,
        colIndex: number,
        previousCell: any,
        rowValues: ConvertedCellValue[],
        displayCell: boolean,
    ) => {
        if (cell.parameterId === 0) {
            return (
                <div
                    css={css({
                        display: "flex",
                        alignItems: "center",
                        padding: "6px 16px 6px 18px",
                    })}
                >
                    <span
                        css={css({
                            paddingLeft: "16px",
                            fontWeight: 600,
                            fontSize: "14px",
                            lineHeight: "20px",
                            letterSpacing: "0.1px",
                            minWidth: "109px",
                            textAlign: "left",
                            color: textDark,
                        })}
                    >
                        {cell.label}
                    </span>

                    <Tooltip
                        title={renderRowIds(rowValues)}
                        small
                        placement="top"
                    >
                        <span>
                            <Tag size="tiny" color="white" title="ID" />
                        </span>
                    </Tooltip>
                </div>
            );
        }

        if (!displayCell) {
            return (
                <InputField
                    stacked
                    id={String(cell.parameterId)}
                    fullWidth
                    size="small"
                    disabled
                    borderRadius="0"
                    otherInputSx={{
                        backgroundColor: gray100,
                        "& .MuiOutlinedInput-notchedOutline": {
                            border: 0,
                        },
                    }}
                    additionalInputWrapperStyles={{
                        "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                                boxShadow: "none",
                            },

                            "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                                boxShadow: "none",
                            },
                        },
                    }}
                />
            );
        }

        return (
            <TimeInput
                data={cell}
                isFirstCell={colIndex === 1}
                previousCell={previousCell}
                rowValues={rowValues}
                isDragging={isDragging}
                setIsDragging={setIsDragging}
            />
        );
    };

    return (
        <Table
            sx={{
                border: `1px solid ${gray300}`,
                borderRadius: "8px",
                borderCollapse: "separate",
                borderSpacing: 0,
                "& th": {
                    backgroundColor: blue100,
                    borderBottom: `1px solid ${blue200}`,
                    padding: "10px 16px",
                    lineHeight: "20px",
                },
                "& th:first-of-type": {
                    borderTopLeftRadius: "8px",
                    paddingLeft: "34px",
                },

                "& th:last-of-type": { borderTopRightRadius: "8px" },

                "tbody tr:last-of-type": {
                    borderBottomRightRadius: "8px",
                },
            }}
        >
            <TableHead>
                <TableRow>
                    {columns.map((column) => (
                        <TableCell
                            key={`header-${column.localizationKey}-schedule-table`}
                            css={css({
                                fontWeight: 600,
                                fontSize: "14px",
                            })}
                        >
                            {column.name}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>

            <TableBody>
                {tableRows?.map((row, rowIndex) => {
                    const rowValues = getRowValues(row);

                    const rowKey =
                        rowValues[0]?.parameterId === 0
                            ? rowValues[0]?.label
                            : rowValues[0]?.parameterId;

                    return (
                        <TableRow key={`row-${rowKey}-main-table`}>
                            {row.map((cell, colIndex) => {
                                const previousCell =
                                    row[colIndex - 1] !== undefined
                                        ? row[colIndex - 1]
                                        : undefined;

                                return (
                                    <TableCell
                                        key={`cell-${rowKey}-${cell.parameterId}-main-table-cell`}
                                        css={css({
                                            padding: "0",
                                            textAlign: "center",
                                            border: 0,
                                            borderRight:
                                                columns.length - 1 !== colIndex
                                                    ? `1px solid ${gray300}`
                                                    : "0",
                                            borderBottom:
                                                tableRows.length - 1 !==
                                                rowIndex
                                                    ? `1px solid ${gray300}`
                                                    : 0,
                                        })}
                                    >
                                        {renderCell(
                                            cell,
                                            colIndex,
                                            previousCell,
                                            rowValues,
                                            true,
                                        )}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
};

export default DesktopScheduleTable;
