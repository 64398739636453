/** @jsxRuntime classic */

/** @jsx jsx */
import { useContext } from "react";

import { css, jsx } from "@emotion/react";

import {
    IoTableColumnTitles,
    IoTableColumns,
} from "../../../../../../../constants/constants";
import MenuItemContext from "../../../../../../../context/menuItem/menuItemContext";
import ThemeContext from "../../../../../../../context/theme/themeContext";
import TableCollection from "../components/TableCollection";
import { TableSwitchDefaultValue } from "../components/TableSwitchInput";
import { MobileTableRowProps } from "../types";

export const useMobileTableCards = (
    row: MobileTableRowProps,
    focusedRow: string,
    rowsWithErrors: string[],
) => {
    const {
        colors: { gray700, textDarkDisabled, red700, blue700, white },
        boxShadows: { ioTableCardBoxShadow },
    } = useContext(ThemeContext);

    const { findParameterValueById } = useContext(MenuItemContext);

    const isInputDisabled =
        findParameterValueById(row.parameterValue.id) ===
        TableSwitchDefaultValue.OFF;

    const renderCellBorder = (colName: string) => {
        if (isInputDisabled) {
            return "none";
        }
        if (rowsWithErrors.some((rowName) => rowName === colName)) {
            return `1px solid ${red700}`;
        }
        if (focusedRow === colName) {
            return `1px solid ${blue700}`;
        }
        return "none";
    };

    const renderCellTitle = (title: string) => (
        <div
            css={css({
                fontSize: "10px",
                color: isInputDisabled ? textDarkDisabled : gray700,
                letterSpacing: "1px",
            })}
        >
            {title}
        </div>
    );

    const returnPriorityRow = (
        handleFocus: (isOpen: boolean, colName: string) => void,
    ) => {
        return (
            <div
                css={css({
                    padding: "12px 16px",
                    backgroundColor: white,
                    boxShadow: ioTableCardBoxShadow,
                    border: renderCellBorder(IoTableColumns.priority),
                })}
            >
                {renderCellTitle(IoTableColumnTitles.priority)}
                <div css={css({ fontSize: "14px" })}>
                    <TableCollection
                        data={row.priority}
                        switchId={row.parameterValue.id}
                        onFocus={(isOpen: boolean) =>
                            handleFocus(isOpen, IoTableColumns.priority)
                        }
                        anchorPositionHorizontal={"left"}
                        slotProps={{
                            paper: {
                                style: {
                                    marginLeft: "8px",
                                    marginTop: "13px",
                                },
                            },
                        }}
                        hasNoInputFocus
                        hasNoInputDisabledBackground
                    />
                </div>
            </div>
        );
    };

    const returnOperandRow = (
        handleFocus: (isOpen: boolean, colName: string) => void,
    ) => {
        return (
            <div
                css={css({
                    padding: "12px 16px",
                    backgroundColor: white,
                    boxShadow: ioTableCardBoxShadow,
                    border: renderCellBorder(IoTableColumns.operand),
                })}
            >
                {renderCellTitle(IoTableColumnTitles.operand)}
                <div css={css({ fontSize: "14px" })}>
                    <TableCollection
                        data={row.operand}
                        switchId={row.parameterValue.id}
                        onFocus={(isOpen: boolean) =>
                            handleFocus(isOpen, IoTableColumns.operand)
                        }
                        anchorPositionHorizontal={"left"}
                        slotProps={{
                            paper: {
                                style: {
                                    marginLeft: "8px",
                                    marginTop: "13px",
                                },
                            },
                        }}
                        hasNoInputFocus
                        hasNoInputDisabledBackground
                    />
                </div>
            </div>
        );
    };

    return {
        renderCellBorder,
        renderCellTitle,
        returnOperandRow,
        returnPriorityRow,
    };
};
