import React, { Fragment, useContext } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import MenuItemContext from "../../../../context/menuItem/menuItemContext";
import SettingsContext from "../../../../context/settings/settingsContext";
import { getUniqueListBy } from "../../../../utils/helpers";
import { FrameData, IoContainerProperties } from "../../../../utils/types";
import ExpandableContainer from "../../../MuiComponents/AccordionWithFrames";
import Badge from "../../../MuiComponents/Badge";

const IoContainer = () => {
    const { frames } = useContext(MenuItemContext);
    const {
        isDeviceGuideStepsOpen,
        setDeviceGuideStepIndex,
        closeDeviceGuideSteps,
        isExplanatoryTextsHidden,
        setIsDeviceGuideInProgress,
    } = useContext(SettingsContext);

    const getTurnedOnCount = (frame: FrameData) => {
        return frame.turnedOnCount;
    }
    
    const history = useHistory();
    const { url } = useRouteMatch();
    const allPossibleIoContainerProperties = frames.map((frame: FrameData) => {
        const turnedOnCount = getTurnedOnCount(frame);

        return {
            ...frame.ioContainerProperties,
            IoContainerItemName: frame.name,
            IoContainerDescription: frame.description,
            turnedOnCount: turnedOnCount,
            totalCount: frame.totalCount,
            index: frame.index,
        };
    });

    const ioContainerProperties = getUniqueListBy(
        allPossibleIoContainerProperties,
        "name"
    );

    const expendableContainerItems: any = ioContainerProperties.map(
        (property) => {
            const ioContainerName = property.name;
            return allPossibleIoContainerProperties
                .map((ioItemProperty: IoContainerProperties, index: number) =>
                    ioItemProperty.name === ioContainerName
                        ? {
                              itemName: ioItemProperty.IoContainerItemName,
                              itemDescription: !isExplanatoryTextsHidden
                                  ? ioItemProperty.IoContainerDescription
                                  : "",
                              customItemBadge: (
                                  <Badge
                                      color={
                                          ioItemProperty.turnedOnCount === 0
                                              ? "neutral"
                                              : "success"
                                      }
                                  >
                                      {ioItemProperty.turnedOnCount}/
                                      {ioItemProperty.totalCount}
                                  </Badge>
                              ),
                              onItemClick: () => {
                                  if (isDeviceGuideStepsOpen) {
                                      setIsDeviceGuideInProgress(false);
                                      setDeviceGuideStepIndex(null);
                                      closeDeviceGuideSteps();
                                  }
                                  history.push(`${url}/${ioItemProperty.index}`);
                              },
                              index: ioItemProperty.index,
                          }
                        : null
                )
                .filter(Boolean);
        }
    );

    return (
        <Fragment>
            {ioContainerProperties.map((property, index) => (
                <ExpandableContainer
                    key={property.index}
                    name={property.name}
                    description={
                        !isExplanatoryTextsHidden ? property.description : ""
                    }
                    items={expendableContainerItems[index]}
                    withBadges={false}
                    expanded={true}
                    hasExpandIcon={false}
                    unsetItemMinHeight={isExplanatoryTextsHidden}
                />
            ))}
        </Fragment>
    );
};

export default IoContainer;
