import React, { useContext } from "react";

import LanguageContext from "../../../../../../../../context/language/languageContext";
import MenuItemContext from "../../../../../../../../context/menuItem/menuItemContext";
import SettingsContext from "../../../../../../../../context/settings/settingsContext";
import { Component } from "../../../../../../../../generatedTypes";
import { getIdTooltipText } from "../../../../../../../../utils/helpers";
import Autocomplete from "../../../../../../../MuiComponents/Autocomplete";
import Tag from "../../../../../../../MuiComponents/Tag";
import Tooltip from "../../../../../../../MuiComponents/Tooltip";
import TriggerContext from "../../../context/triggerContext";

interface Option {
    name: string;
    value: string;
    id: number;
    isInvisible?: boolean;
}

interface SourceDropdownProps {
    data: Component;
    onChange: (event: { id: number; value: string }) => void;
}

const SourceDropdown = ({ data, onChange }: SourceDropdownProps) => {
    const { values } = useContext(TriggerContext);
    const { parameters } = useContext(MenuItemContext);
    const { t } = useContext(LanguageContext);
    const { isParameterIdsHidden } = useContext(SettingsContext);

    const sourceValue =
        values[data.parameterId] || parameters[data.parameterId];

    const options = data.collectionConfig?.collectionItems || [];

    const formattedOptions: Option[] = options.map((option) => {
        return {
            name: option.text ?? "",
            value: option.value ?? "",
            id: option.parameterId ?? "",
            isInvisible: option.isInvisible,
        };
    });

    const renderOption = (props: any, option: Option) => {
        if (option.isInvisible) {
            return null;
        }

        return (
            <li
                {...props}
                key={option.value}
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    opacity: 1,
                }}
            >
                {option.name}
            </li>
        );
    };

    const selectedOption = formattedOptions.find(
        (option) => option.value === sourceValue,
    );

    return (
        <Autocomplete
            includeInputInList
            disableClearable
            textFieldParams={{
                placeholder: t.Select,
            }}
            value={selectedOption}
            size="medium"
            options={formattedOptions}
            onChange={(e, item) =>
                onChange({ value: item.value, id: data.parameterId })
            }
            isOptionEqualToValue={(option, val) => {
                return option.value === val;
            }}
            getOptionLabel={(option) => option.name}
            renderOption={renderOption}
            labelLeft={data.label}
            iconRight={
                data?.parameterId && !isParameterIdsHidden ? (
                    <Tooltip
                        title={getIdTooltipText(data?.parameterId, data?.avlId)}
                        small
                        placement="top"
                    >
                        <span>
                            <Tag size="tiny" color="white" title="ID" />
                        </span>
                    </Tooltip>
                ) : null
            }
        />
    );
};

export default SourceDropdown;
