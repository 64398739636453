/** @jsxRuntime classic */

/** @jsx jsx */
import { Fragment, useContext, useState } from "react";

import { css, jsx } from "@emotion/react";

import { InputLabel } from "@mui/material";

import { IoTableColumns } from "../../../../../../../../constants/constants";
import InputOutputContext from "../../../../../../../../context/inputOutput/inputOutputContext";
import LanguageContext from "../../../../../../../../context/language/languageContext";
import LayoutContext from "../../../../../../../../context/layout/layoutContext";
import useMediaQuries from "../../../../../../../../utils/useMediaQueries";
import Checkbox from "../../../../../../../MuiComponents/Checkbox";
import MoreFilters from "../../../../../shared/MoreFilters";
import MoreFiltersPopover from "../../../../../shared/MoreFiltersPopover";

import OperandSelect from "./OperandSelect";
import PrioritySelect from "./PrioritySelect";

export interface MoreFiltersSelectProps {
    columns: any;
}

const MoreFiltersSelect = ({ columns }: MoreFiltersSelectProps) => {
    const { setActiveFilter, activeFilters, resetFilter } =
        useContext(InputOutputContext);

    const { t } = useContext(LanguageContext);

    const { layoutData } = useContext(LayoutContext);

    const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);

    const { toLg } = useMediaQuries();

    const hasRowsWithCurrentValues = activeFilters.some(
        (item) => item === IoTableColumns.currentValue,
    );

    return (
        <Fragment>
            <InputLabel
                css={css({
                    position: "unset",
                    marginLeft: toLg ? "0px" : "16px",
                    display: "inline-flex",
                })}
                onClick={(e) => setMenuAnchorEl(e.currentTarget as HTMLElement)}
            >
                <MoreFilters />
            </InputLabel>

            <MoreFiltersPopover
                menuAnchorEl={menuAnchorEl}
                setMenuAnchorEl={setMenuAnchorEl}
            >
                <PrioritySelect columns={columns} />

                <OperandSelect columns={columns} />

                {layoutData?.isOnline && (
                    <div
                        css={css({
                            marginTop: "12px",
                        })}
                    >
                        <Checkbox
                            checked={hasRowsWithCurrentValues}
                            label={t.CurrentValuesFilter}
                            onChange={() =>
                                hasRowsWithCurrentValues
                                    ? resetFilter(IoTableColumns.currentValue)
                                    : setActiveFilter(
                                          IoTableColumns.currentValue,
                                      )
                            }
                        />
                    </div>
                )}
            </MoreFiltersPopover>
        </Fragment>
    );
};

export default MoreFiltersSelect;
