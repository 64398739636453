/** @jsxRuntime classic */

/** @jsx jsx */
import { useContext, useEffect } from "react";

import { ColumnInstance } from "react-table";

import { jsx } from "@emotion/react";

import { MenuItem } from "@mui/material";

import { statusItems } from "../../../../../../../constants/constants";
import InputOutputContext from "../../../../../../../context/inputOutput/inputOutputContext";
import useMediaQueries from "../../../../../../../utils/useMediaQueries";
import SearchInput from "../../../../../../MuiComponents/SearchInput";
import Select from "../../../../../../MuiComponents/Select";
import TypedGrid from "../../../../../../MuiComponents/TypedGrid";

import FilteredBySection from "./FilteredBySection";
import MoreFiltersSelect from "./MoreFiltersSelect";
import ColumnSelect from "./NewColumnSelect";

export interface IoToolbarSettings {
    hasNoMoreFilters?: boolean;
    hasNoViewColumns?: boolean;
    columnSettings?: {
        activeColumns: ColumnInstance[];
        onColumnChange: (columnValue: string) => void;
        onReset: () => void;
    };
}

export interface IoTableToolbarProps {
    isLoading?: boolean;
    toolbarSettings: IoToolbarSettings;
}

const IoTableToolbar = ({
    isLoading,
    toolbarSettings,
}: IoTableToolbarProps) => {
    const {
        activeFilters,
        activeOperand,
        activePriority,
        setActiveStatus,
        activeStatus,
        setSearchValue,
        searchValue,
        resetAllFilters,
    } = useContext(InputOutputContext);

    const { toMd, toSm } = useMediaQueries();

    useEffect(() => {
        setSearchValue("");
        resetAllFilters();

        return () => {
            if (toolbarSettings.columnSettings?.onReset) {
                toolbarSettings.columnSettings.onReset();
            }
        };
    }, []);

    return (
        <div
            style={{
                padding: toMd ? "16px 12px" : "12px 24px",
                backgroundColor: toMd ? "white" : "transparent",
            }}
        >
            <TypedGrid container alignItems="center" rowSpacing={2}>
                <TypedGrid container item md={12} lg={5} alignItems="center">
                    <TypedGrid item lg={6} md={6} sm={6} xs={12}>
                        <SearchInput
                            onChange={(e) => setSearchValue(e.target.value)}
                            resetValue={() => setSearchValue("")}
                            searchValue={searchValue}
                            disabled={isLoading}
                        />
                    </TypedGrid>

                    <TypedGrid item lg={6} md={6} sm={6} xs={12}>
                        <Select
                            onChange={(event: any) => {
                                const value = event.target.value;
                                setActiveStatus(value);
                            }}
                            sx={{
                                margin: toSm ? "16px 0 0 0" : "0 0 0 16px",
                            }}
                            value={activeStatus}
                            disabled={isLoading}
                        >
                            {statusItems.map((item) => {
                                return (
                                    <MenuItem
                                        key={item.value}
                                        value={item.value}
                                    >
                                        {item.text}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </TypedGrid>
                </TypedGrid>

                <TypedGrid
                    container
                    item
                    lg={7}
                    md={12}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <TypedGrid item lg={5} md={6} sm={6} xs={6}>
                        {!toolbarSettings.hasNoMoreFilters && (
                            <MoreFiltersSelect
                                columns={
                                    toolbarSettings.columnSettings
                                        ?.activeColumns
                                }
                            />
                        )}
                    </TypedGrid>

                    {!toolbarSettings.hasNoViewColumns && (
                        <TypedGrid
                            item
                            lg={5}
                            md={6}
                            sm={6}
                            xs={6}
                            container
                            justifyContent="flex-end"
                        >
                            {toolbarSettings.columnSettings && (
                                <ColumnSelect
                                    activeColumns={
                                        toolbarSettings.columnSettings
                                            .activeColumns
                                    }
                                    onColumnChange={
                                        toolbarSettings.columnSettings
                                            .onColumnChange
                                    }
                                    onReset={
                                        toolbarSettings.columnSettings.onReset
                                    }
                                />
                            )}
                        </TypedGrid>
                    )}
                </TypedGrid>
            </TypedGrid>

            {(!!activeFilters.length ||
                !!activeOperand.length ||
                !!activePriority.length) && <FilteredBySection />}
        </div>
    );
};

export default IoTableToolbar;
