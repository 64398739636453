/** @jsxRuntime classic */

/** @jsx jsx */
import { SyntheticEvent, useContext, useEffect, useRef, useState } from "react";

import { css, jsx } from "@emotion/react";

import { SelectChangeEvent } from "@mui/material";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import MenuItem from "@mui/material/MenuItem";

import LayoutContext from "../../../../../context/layout/layoutContext";
import MenuItemContext from "../../../../../context/menuItem/menuItemContext";
import SettingsContext from "../../../../../context/settings/settingsContext";
import ThemeContext from "../../../../../context/theme/themeContext";
import {
    CollectionItem,
    Component,
    SearchPath,
} from "../../../../../generatedTypes";
import { getIdTooltipText, noop } from "../../../../../utils/helpers";
import useDebounce from "../../../../../utils/useDebounce";
import useNavigation from "../../../../../utils/useNavigation";
import Select from "../../../../MuiComponents/Select";
import Tag from "../../../../MuiComponents/Tag";
import Tooltip from "../../../../MuiComponents/Tooltip";
import TooltipLarge from "../../../../MuiComponents/TooltipLarge";

export interface MultiCollectionProps {
    data: Component;
}
const MultiCollection = ({ data }: MultiCollectionProps) => {
    const {
        label,
        parameterId,
        parameterValue: initialParameterValue,
        avlId,
        isDisabled,
        collectionConfig,
        tooltip = "",
        disabledTooltip,
    } = data;

    const collectionItems = collectionConfig?.collectionItems || [];
    const isRequired = collectionConfig?.isRequired;

    const {
        colors: { blue200, blue700, blue800, textDarkDisabled },
    } = useContext(ThemeContext);

    const {
        updateParameter,
        findDisabledCollectionItemsById,
        findDisabledParameterById,
        setComponentVisibilities,
    } = useContext(MenuItemContext);
    const { isParameterIdsHidden } = useContext(SettingsContext);

    const { setDrawerParameterId } = useContext(LayoutContext);

    const { handleSearchPath } = useNavigation();

    const debouncedChangeHandler = useDebounce(updateParameter, 500);

    const [parameterValue, setParameterValue] = useState(initialParameterValue);
    const [itemValues, setItemValues] = useState<CollectionItem[]>([]);
    const [previousValues, setPreviousValues] = useState<CollectionItem[]>([]);
    const [menuItems, setMenuItems] = useState(collectionItems);
    const [loading, setLoading] = useState(false);
    const [requestError, setRequestError] = useState(false);
    const elementRef = useRef(null);

    // Get initially selected items
    useEffect(() => {
        const getSelectedItems = () => {
            const selected: CollectionItem[] = [];

            collectionItems.forEach((item) => {
                // 1 2 4 8 etc. (not necessarily sorted)
                const bitPosition = 1 << Number(item.value);

                if ((Number(parameterValue) & bitPosition) === bitPosition) {
                    selected.push(item);
                }
            });

            setItemValues(selected);
            setPreviousValues(selected);
        };

        getSelectedItems();
        // eslint-disable-next-line
    }, []);

    // Remove the selected items from the menu items
    useEffect(() => {
        if (isRequired) {
            const updatedMenuItems = collectionItems.filter(
                (item) => !itemValues.includes(item),
            );

            setMenuItems(updatedMenuItems);
        }
        // eslint-disable-next-line
    }, [itemValues]);

    const dependenciesToDisable =
        data.dependentParameterIds &&
        data.dependentParameterIds.length > 0 &&
        data.dependentParameterIds.map((dependant: number) => {
            return {
                parameterId: dependant,
                isDisabled: true,
            };
        });
    const disableDependencies = () => {
        dependenciesToDisable &&
            setComponentVisibilities(dependenciesToDisable);
    };

    const onSuccessfulRequest = (val: any) => {
        requestError && setRequestError(false);
        setPreviousValues(val);
    };

    const onFailedRequest = () => {
        setRequestError(true);
        setItemValues(previousValues);
    };

    const convertArrayToNumber = (values: CollectionItem[]) => {
        let newParameterValue = 0;

        values.forEach((item) => {
            newParameterValue = newParameterValue | (1 << Number(item.value));
        });

        return newParameterValue;
    };

    const handleChange = (e: SelectChangeEvent<any>) => {
        const newValues = e.target.value;
        const numberValue = convertArrayToNumber(newValues);
        setParameterValue(String(numberValue));
        setItemValues(newValues);
        setLoading(true);

        disableDependencies();

        debouncedChangeHandler(
            parameterId,
            numberValue,
            label,
            elementRef,
            () => onSuccessfulRequest(numberValue),
            onFailedRequest,
            () => setLoading(false),
            true,
            noop,
        );
    };

    const handleChipDelete = (chip: CollectionItem) => {
        const updatedChipArray = itemValues.filter(
            (selectedItem) => selectedItem.value !== chip.value,
        );

        const numberValue = convertArrayToNumber(updatedChipArray);

        setParameterValue(String(numberValue));
        setItemValues(updatedChipArray);
        setLoading(true);

        disableDependencies();

        debouncedChangeHandler(
            parameterId,
            numberValue,
            label,
            elementRef,
            () => onSuccessfulRequest(numberValue),
            onFailedRequest,
            () => setLoading(false),
            true,
        );
    };

    const isMultiCollectionDisabled =
        findDisabledParameterById(parameterId) !== undefined
            ? findDisabledParameterById(parameterId)
            : isDisabled;

    const cannotDelete =
        (isRequired && itemValues.length === 1) || isMultiCollectionDisabled;

    return (
        <TooltipLarge
            title={
                isMultiCollectionDisabled && disabledTooltip
                    ? disabledTooltip.title
                    : ""
            }
            buttonText={
                disabledTooltip?.btnTitle ? disabledTooltip.btnTitle : ""
            }
            onBtnClick={() =>
                handleSearchPath(disabledTooltip as { searchPath: SearchPath })
            }
        >
            <span>
                <Select
                    id={String(parameterId)}
                    ref={elementRef}
                    multiple
                    value={itemValues}
                    onInfoIconClick={() => setDrawerParameterId(parameterId)}
                    label={label}
                    iconRight={
                        parameterId && !isParameterIdsHidden ? (
                            <Tooltip
                                title={getIdTooltipText(parameterId, avlId)}
                                small
                                placement="top"
                            >
                                <span>
                                    <Tag size="tiny" color="white" title="ID" />
                                </span>
                            </Tooltip>
                        ) : null
                    }
                    requesting={loading}
                    requestFailed={requestError}
                    info={tooltip}
                    medium
                    disabled={isMultiCollectionDisabled}
                    onChange={handleChange}
                    renderValue={(selected: CollectionItem[]) => (
                        <Box
                            sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                columnGap: 1,
                                rowGap: 0.5,
                                padding: "2px 0",
                            }}
                        >
                            {selected.map((selectedItem) => (
                                <Chip
                                    key={selectedItem.value}
                                    label={selectedItem.text}
                                    onMouseDown={(e: SyntheticEvent) =>
                                        e.stopPropagation()
                                    }
                                    onDelete={
                                        cannotDelete
                                            ? undefined
                                            : () =>
                                                  handleChipDelete(selectedItem)
                                    }
                                    sx={{
                                        backgroundColor: blue200,
                                        padding: "2px 8px",
                                        borderRadius: "4px",
                                        height: "unset",

                                        "& .MuiChip-label": {
                                            color: blue700,
                                            fontSize: "12px",
                                            lineHeight: "16px",
                                            letterspacing: "0.4px",
                                            padding: "0",
                                        },

                                        "& .MuiChip-deleteIcon": {
                                            color: blue700,
                                            margin: "0 0 0 8px !important",
                                            fontSize: "16px",

                                            "&:hover": {
                                                color: blue800,
                                            },
                                        },
                                    }}
                                />
                            ))}
                        </Box>
                    )}
                    wrapperStyle={css`
                        scroll-margin: 16px;
                    `}
                >
                    {menuItems.length > 0 ? (
                        menuItems.map((item) => {
                            const hasDisabledItems =
                                findDisabledCollectionItemsById(parameterId);
                            const isMenuItemDisabled =
                                hasDisabledItems &&
                                hasDisabledItems[item.value];
                            return (
                                <MenuItem
                                    key={item.value}
                                    value={item as any}
                                    disabled={isMenuItemDisabled}
                                >
                                    {item.text}
                                </MenuItem>
                            );
                        })
                    ) : (
                        <li
                            css={css`
                                pointer-events: none;
                                padding: 12px 16px;
                                color: ${textDarkDisabled};
                                font-size: 14px;
                                font-weight: 600;
                                line-height: 20px;
                                letter-spacing: 0.1px;
                            `}
                        >
                            {collectionItems.length > 0
                                ? "All items are selected"
                                : "No items"}
                        </li>
                    )}
                </Select>
            </span>
        </TooltipLarge>
    );
};

export default MultiCollection;
