/** @jsxRuntime classic */

/** @jsx jsx */
import { useContext } from "react";

import { css, jsx } from "@emotion/react";

import CloseIcon from "@mui/icons-material/CloseRounded";
import { CircularProgress } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";

import ThemeContext from "../../../context/theme/themeContext";
import useMediaQueries from "../../../utils/useMediaQueries";
import Button from "../Button";
import Divider from "../Divider";
import Tooltip from "../Tooltip";

interface FormModalProps {
    isOpen: boolean;
    onClose?: () => void;
    onSubmit?: (e: React.FormEvent<HTMLFormElement>) => any;
    title: string;
    description?: string | null;
    secondaryButtonText?: string | null;
    primaryButtonText?: string | null;
    children: React.ReactNode;
    isSubmitting?: boolean;
    customModalStyles?: any;
    isPrimaryButtonDisabled?: boolean;
    disabledTooltip?: { btnTitle?: string; goTo?: number; title: string };
    onSecondaryButtonClick?: (e: any) => void;
    hasNoDivider?: boolean;
    isSecondaryButtonDisabled?: boolean;
    isCloseDisabled?: boolean;
    hasActionsDivider?: boolean;
}

const FormModal = ({
    isOpen,
    onClose,
    onSubmit,
    title,
    description,
    secondaryButtonText,
    primaryButtonText,
    isPrimaryButtonDisabled,
    disabledTooltip,
    isSubmitting,
    customModalStyles = {},
    children,
    onSecondaryButtonClick,
    hasNoDivider,
    isSecondaryButtonDisabled,
    isCloseDisabled,
    hasActionsDivider,
    ...props
}: FormModalProps) => {
    const { toMd, toSm } = useMediaQueries();
    const {
        colors: { white },
    } = useContext(ThemeContext);
    return (
        <Dialog
            onClose={onClose}
            open={isOpen}
            sx={{
                "& .MuiDialog-paper": {
                    maxWidth: !toMd ? "448px" : "unset",
                    height: toSm ? "100vh" : "unset",
                    ...customModalStyles,
                },
            }}
            {...props}
        >
            <DialogTitle
                sx={{
                    fontSize: "20px",
                    lineHeight: "24px",
                    letterSpacing: "0.15px",
                    marginRight: "24px",
                }}
            >
                <div
                    css={css({
                        paddingBottom: description ? "8px" : "12px",
                    })}
                >
                    {title}
                </div>

                {onClose && (
                    <Button
                        variant="iconOnly"
                        size="small"
                        color="white"
                        icon={<CloseIcon onClick={onClose} />}
                        css={css({
                            position: "absolute",
                            top: "4px",
                            right: "4px",
                        })}
                        idForTesting="close-modal"
                        disabled={isCloseDisabled}
                    />
                )}
                {description && (
                    <span
                        css={css({
                            lineHeight: "20px",
                            letterSpacing: "0.1px",
                            padding: "0 16px 12px 0",
                            fontFamily: "Open Sans",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            display: "block",
                        })}
                    >
                        {description}
                    </span>
                )}
            </DialogTitle>
            {!hasNoDivider && <Divider />}
            <form
                noValidate
                onSubmit={onSubmit}
                css={css({
                    overflowY: "auto",
                })}
            >
                {children && (
                    <div
                        css={css({
                            flex: "1",
                            padding: "16px",
                            display: "flex",
                            flexDirection: "column",
                            overflowY: "auto",
                        })}
                    >
                        {children}
                    </div>
                )}
                {(secondaryButtonText || primaryButtonText) && (
                    <div
                        css={css([
                            hasActionsDivider && {
                                paddingTop: "16px",
                                position: "sticky",
                                bottom: 0,
                                background: white,
                            },
                        ])}
                    >
                        {hasActionsDivider && <Divider />}
                        <DialogActions
                            sx={{
                                gap:
                                    secondaryButtonText && primaryButtonText
                                        ? "12px"
                                        : "0px",
                            }}
                        >
                            {secondaryButtonText && (
                                <span css={css({ width: "100%" })}>
                                    <Button
                                        variant="textOnly"
                                        size="normal"
                                        color="secondary"
                                        fullWidth
                                        onClick={
                                            onSecondaryButtonClick || onClose
                                        }
                                        idForTesting="secondary-button"
                                        disabled={isSecondaryButtonDisabled}
                                    >
                                        {secondaryButtonText}
                                    </Button>
                                </span>
                            )}
                            {primaryButtonText && onSubmit && (
                                <Tooltip
                                    title={
                                        isPrimaryButtonDisabled &&
                                        disabledTooltip
                                            ? disabledTooltip.title
                                            : ""
                                    }
                                    placement="top"
                                >
                                    <span css={css({ width: "100%" })}>
                                        <Button
                                            focus
                                            variant="textOnly"
                                            size="normal"
                                            color="primary"
                                            fullWidth
                                            onClick={onSubmit}
                                            disabled={isPrimaryButtonDisabled}
                                            idForTesting="primary-button"
                                        >
                                            {isSubmitting ? (
                                                <CircularProgress
                                                    size={26}
                                                    css={css({
                                                        color: white,
                                                    })}
                                                />
                                            ) : (
                                                primaryButtonText
                                            )}
                                        </Button>
                                    </span>
                                </Tooltip>
                            )}
                        </DialogActions>
                    </div>
                )}
            </form>
        </Dialog>
    );
};

export default FormModal;
