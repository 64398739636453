/** @jsxRuntime classic */

/** @jsx jsx */
import { forwardRef, useContext } from "react";

import { SerializedStyles, css, jsx } from "@emotion/react";

import { Button as ButtonMui } from "@mui/material";

import ThemeContext from "../../../context/theme/themeContext";
import { chooseValue } from "../../../utils/helpers";

import IconLeftButton from "./IconLeftButton";
import IconOnlyButton from "./IconOnlyButton";
import IconRightButton from "./IconRightButton";
import TextOnlyButton from "./TextOnlyButton";
import TwoIconsButton from "./TwoIconsButton";
import { useButtonVariants } from "./hooks/useButtonVariants";
import { ButtonColors, ButtonSizes, ButtonVariants } from "./types";

type Ref = HTMLButtonElement;

interface ButtonProps {
    children?: any;
    variant?: ButtonVariants;
    size: ButtonSizes;
    color: ButtonColors;
    icon?: any;
    isActive?: boolean;
    focus?: boolean;
    disabled?: boolean;
    onClick?: (e: any) => void;
    fullWidth?: boolean;
    type?: "submit" | "reset" | "button";
    css?: SerializedStyles;
    component?: any;
    href?: string;
    target?: string;
    rel?: string;
    idForTesting: string;
}

const Button = forwardRef<Ref, ButtonProps>(
    (
        {
            children,
            variant = "textOnly",
            size,
            color,
            icon,
            isActive,
            focus,
            idForTesting,
            ...props
        },
        ref,
    ) => {
        const {
            colors: {
                white,
                gray100,
                gray200,
                gray300,
                blue100,
                blue200,
                blue400,
                blue700,
                blue800,
                blue900,
                red100,
                red200,
                red300,
                red700,
                red800,
                red900,
                textDark,
                gray700,
                textDarkDisabled,
            },
        } = useContext(ThemeContext);

        const { renderButtonVariant } = useButtonVariants(
            variant,
            icon,
            children,
        );

        const styleVariants: any = {
            colors: {
                primary: {
                    text: white,
                    background: {
                        rest: blue700,
                        hover: blue800,
                        active: blue900,
                    },
                },
                primarySubtle: {
                    text: blue700,
                    background: {
                        rest: blue100,
                        hover: blue200,
                        active: blue400,
                    },
                },
                secondary: {
                    text: textDark,
                    background: {
                        rest: gray100,
                        hover: gray200,
                        active: gray300,
                    },
                },
                white: {
                    text: gray700,
                    background: {
                        rest: white,
                        hover: gray100,
                        active: gray200,
                    },
                },
                transparent: {
                    text: white,
                    background: {
                        rest: "rgba(255, 255, 255, 0.05)",
                        hover: "rgba(255, 255, 255, 0.1)",
                        active: "rgba(255, 255, 255, 0.12)",
                    },
                },
                critical: {
                    text: white,
                    background: {
                        rest: red700,
                        hover: red800,
                        active: red900,
                    },
                },
                criticalSubtle: {
                    text: red800,
                    background: {
                        rest: red100,
                        hover: red200,
                        active: red300,
                    },
                },
            },
            paddings: {
                textOnly: {
                    tiny: "4px 8px",
                    small: "8px 12px",
                    normal: "12px 16px",
                    large: "15px 24px",
                },
                iconLeft: {
                    tiny: "4px 8px 4px 4px",
                    small: "6px 12px 6px 8px",
                    normal: "10px 16px 10px 12px",
                    large: "14px 24px 14px 16px",
                },
                iconRight: {
                    tiny: "4px 4px 4px 8px",
                    small: "6px 8px 6px 12px",
                    normal: "10px 12px 10px 16px",
                    large: "14px 16px 14px 24px",
                },
                twoIcons: {
                    tiny: "4px",
                    small: "6px 8px",
                    normal: "10px 12px",
                    large: "14px 16px",
                },
                iconOnly: {
                    tiny: "4px",
                    small: "6px",
                    normal: "10px",
                    large: "14px",
                },
            },
            typography: {
                tiny: {
                    fontSize: "12px",
                    lineHeight: "16px",
                    letterSpacing: "0.4px",
                },
                small: {
                    fontSize: "14px",
                    lineHeight: "20px",
                    letterSpacing: "0.1px",
                },
                normal: {
                    fontSize: "14px",
                    lineHeight: "20px",
                    letterSpacing: "0.1px",
                },
                large: {
                    fontSize: "16px",
                    lineHeight: "20px",
                    letterSpacing: "0.15px",
                },
            },
        };

        const bgColorRest = chooseValue(
            color,
            styleVariants.colors[color].background.rest,
            styleVariants.colors.primary.background.rest,
        );
        const bgColorHover = chooseValue(
            color,
            styleVariants.colors[color].background.hover,
            styleVariants.colors.primary.background.hover,
        );
        const bgColorActive = chooseValue(
            color,
            styleVariants.colors[color].background.active,
            styleVariants.colors.primary.background.active,
        );

        const getDisabledBgColor = () => {
            if (color === "transparent") {
                return "rgba(255, 255, 255, 0.05)";
            }

            return chooseValue(color === "white", white, gray100);
        };

        const bgColorDisabled = getDisabledBgColor();

        const textColorDisabled = chooseValue(
            color === "transparent",
            "rgba(255, 255, 255, 0.3)",
            textDarkDisabled,
        );
        const textColor = chooseValue(
            color,
            styleVariants.colors[color].text,
            styleVariants.colors.primary.text,
        );
        const padding = chooseValue(
            variant && size,
            styleVariants.paddings[variant][size],
            styleVariants.paddings.textOnly.small,
        );
        const fontSize = chooseValue(
            size,
            styleVariants.typography[size].fontSize,
            styleVariants.typography.small.fontSize,
        );
        const lineHeight = chooseValue(
            size,
            styleVariants.typography[size].lineHeight,
            styleVariants.typography.small.lineHeight,
        );
        const letterSpacing = chooseValue(
            size,
            styleVariants.typography[size].letterSpacing,
            styleVariants.typography.small.letterSpacing,
        );

        return (
            <ButtonMui
                {...props}
                data-test={idForTesting}
                ref={ref}
                autoFocus={focus}
                variant="contained"
                css={css({
                    backgroundColor: chooseValue(
                        isActive,
                        bgColorActive,
                        bgColorRest,
                    ),
                    color: textColor,
                    fontSize,
                    lineHeight,
                    letterSpacing,
                    padding,

                    "& > svg": {
                        fontSize: chooseValue(size === "tiny", "16px", "24px"),
                        path: {
                            fill: props.disabled
                                ? textColorDisabled
                                : textColor,
                        },
                    },

                    "&.Mui-disabled": {
                        backgroundColor: bgColorDisabled,
                        color: textColorDisabled,
                    },

                    "&:hover": {
                        backgroundColor: chooseValue(
                            isActive,
                            bgColorActive,
                            bgColorHover,
                        ),
                    },
                })}
            >
                {renderButtonVariant()}
            </ButtonMui>
        );
    },
);

export default Button;
