/** @jsxRuntime classic */

/** @jsx jsx */
import { useContext } from "react";

import { css } from "@emotion/react";

import ThemeContext from "../../../../../context/theme/themeContext";

import { ConvertedCellValue } from "./ScheduleTable/types";

const useCellStylingAndTooltips = () => {
    const {
        colors: { textSecondary },
    } = useContext(ThemeContext);

    const renderRowIds = (rowValues: ConvertedCellValue[]) => {
        const valuesString = rowValues
            .filter((cell) => cell.parameterId !== 0)
            .map((cell) => {
                const { parameterId, columnLocalizationKey } = cell;

                return `${columnLocalizationKey}:<br> ID: ${parameterId}`;
            })
            .join("<hr>");
        return valuesString;
    };

    const getReadOnlyTableCellStyle = (
        colIndex: number,
        nextCellIsEmpty: boolean,
        isSwitchIdCase: boolean = false,
    ) =>
        css({
            fontWeight: 600,
            fontSize: "14px",
            lineHeight: "20px",
            letterSpacing: "0.1px",
            color: textSecondary,
            textAlign: !isSwitchIdCase ? "center" : undefined,
            paddingRight: colIndex === 6 ? 0 : "6px",
            borderRight:
                !isSwitchIdCase && !nextCellIsEmpty
                    ? `2px solid ${textSecondary}`
                    : 0,
            minWidth: isSwitchIdCase ? "149px" : undefined,
        });

    return {
        renderRowIds,
        getReadOnlyTableCellStyle,
    };
};

export default useCellStylingAndTooltips;
