import {
    RESET_PARAMETERS,
    SET_ACTIVE_FRAME,
    SET_BREADCRUMB_ITEMS,
    SET_COMPONENT_VISIBILITIES,
    SET_ERROR,
    SET_ERRORS,
    SET_FIELD_VALIDATIONS,
    SET_FRAMES,
    SET_MANUAL_GEOFENCE_PARAMETERS,
    SET_PARAMETER,
    SET_PARAMETERS,
} from "./menuItemActions";
import { mapVisibilityPayload, separateVisibilities } from "./menuItemHelpers";

export default (state: any, action: any) => {
    switch (action.type) {
        case SET_FRAMES: {
            return {
                ...state,
                frames: action.payload.allData,
                parameters: action.payload.allParameters,
                errors: {},
            };
        }
        //Rename as this is currently used for manual geofences
        case SET_MANUAL_GEOFENCE_PARAMETERS: {
            return {
                ...state,
                parameters: {
                    ...state.parameters,
                    ...action.payload,
                },
                errors: {},
            };
        }
        case SET_PARAMETERS: {
            const allData = [...action.payload];
            const formatedArrayData: any = allData.forEach((item) => {
                state.parameters[item.id] = item.value;
            });
            return {
                ...state,
                parameters: {
                    ...state.parameters,
                    ...formatedArrayData,
                },
                errors: {},
            };
        }
        case RESET_PARAMETERS: {
            const parameterIds: number[] = action.payload;
            let newParameters = { ...state.parameters };
            parameterIds.forEach((id) => {
                let { [id]: _, ...rest } = newParameters;
                newParameters = rest;
            });
            return {
                ...state,
                parameters: newParameters,
                errors: {},
            };
        }
        case SET_BREADCRUMB_ITEMS: {
            const allData = action.payload;
            return {
                ...state,
                breadcrumbItems: allData,
            };
        }
        case SET_ACTIVE_FRAME: {
            return {
                ...state,
                activeFrame: action.payload.allData,
                parameters: {
                    ...state.parameters,
                    ...action.payload.allParameters,
                },
                errors: {},
            };
        }
        case SET_PARAMETER: {
            const { id, value } = action.payload;

            return {
                ...state,
                parameters: {
                    ...state.parameters,
                    [id]: value,
                },
            };
        }
        case SET_ERROR: {
            const { id, error } = action.payload;

            return {
                ...state,
                errors: {
                    ...state.errors,
                    [id]: error,
                },
            };
        }
        case SET_ERRORS: {
            const errorArray = action.payload;

            return {
                ...state,
                errors: {
                    ...state.errors,
                    ...errorArray,
                },
            };
        }
        case SET_COMPONENT_VISIBILITIES: {
            const { previousDisabledParams, otherDisabledParams } =
                separateVisibilities(
                    state.componentVisibilities || [],
                    action.payload,
                );

            const mappedDisabledParams = mapVisibilityPayload(
                action.payload,
                previousDisabledParams,
            );

            return {
                ...state,
                componentVisibilities: [
                    ...otherDisabledParams,
                    ...mappedDisabledParams,
                ],
            };
        }

        case SET_FIELD_VALIDATIONS: {
            const { validationRules, clearRules } = action.payload;

            const currentValidations = [...state.fieldValidations];

            const combinedValidationsArr = [
                ...validationRules,
                ...currentValidations.filter(
                    (currentValidation: any) =>
                        !validationRules.some(
                            (newValidation: any) =>
                                newValidation.parameterId ===
                                currentValidation.parameterId,
                        ),
                ),
            ];

            return {
                ...state,
                fieldValidations: clearRules ? [] : combinedValidationsArr,
            };
        }

        default:
            return state;
    }
};
