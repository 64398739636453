import { useContext } from "react";

import moment from "moment";

import { TIME_FORMATS } from "../../../../../../../constants/constants";
import LanguageContext from "../../../../../../../context/language/languageContext";
import MenuItemContext from "../../../../../../../context/menuItem/menuItemContext";
import { Component } from "../../../../../../../generatedTypes";
import { validateTextLengthAndRegex } from "../../../../../../../utils/validations";
import { UpdatePayloadObjProps } from "../types";

const useTimeInputFunctions = (
    textMinLength: number,
    textMaxLength: number,
    parameterId: number,
    rowValues: any[],
) => {
    const { t } = useContext(LanguageContext);

    const { findParameterValueById } = useContext(MenuItemContext);

    const convertToTimeString = (value: string) => {
        if (!value) return null;

        if (value) {
            const [hoursStr, minutesStr] = value.split(":");
            const hours = parseInt(hoursStr) || 0;
            const minutes = parseInt(minutesStr) || 0;

            const normalizedHours = Math.min(hours, 23);

            const normalizedMinutes =
                value.length <= 4
                    ? minutes.toString().padStart(2, "0")
                    : Math.min(minutes, 59);

            if (value.length <= 5) {
                const formattedTime = moment()
                    .set({
                        hour: normalizedHours,
                        minute: Number(normalizedMinutes),
                    })
                    .format("HH:mm");

                return formattedTime;
            }
        }
    };

    const checkIfValueIsValid = (
        paramValue: string,
        validateVal: string | undefined,
        validationMessageVal: string | undefined,
    ) => {
        const errorValue = validateTextLengthAndRegex(
            paramValue,
            textMinLength,
            textMaxLength,
            validateVal ?? "",
            validationMessageVal ?? "",
            t,
        );

        return errorValue;
    };

    const formatTimeFromInput = (value: string) => {
        const valueWithNumbersOnly = value.replace(/\D/g, "");

        return valueWithNumbersOnly.length > 2
            ? `${valueWithNumbersOnly.slice(0, 2)}:${valueWithNumbersOnly.slice(2)}`
            : valueWithNumbersOnly;
    };

    const getSortedData = (currentValues: UpdatePayloadObjProps[]) => {
        const checkIfPreviousCellTimeIsEarlier = (
            previousTime: string,
            currentTime: string,
        ) =>
            moment(previousTime, TIME_FORMATS.HOURS_MINUTES).isBefore(
                moment(currentTime, TIME_FORMATS.HOURS_MINUTES),
            );

        const sortedValues = currentValues
            .map((e) => e.value)
            .filter(Boolean)
            .sort((a, b) => (checkIfPreviousCellTimeIsEarlier(a, b) ? -1 : 1));

        return currentValues.map((entry, i) => ({
            ...entry,
            value: sortedValues[i] ?? "",
        }));
    };

    const shiftValuesToPreviousId = (items: UpdatePayloadObjProps[]) => {
        const shiftedItems = items.map((item) => ({ ...item }));

        for (let i = 0; i < shiftedItems.length - 1; i++) {
            shiftedItems[i].value = shiftedItems[i + 1].value;
        }

        shiftedItems[shiftedItems.length - 1].value = "";

        return shiftedItems;
    };

    const getShiftedData = (
        formattedValue: string,
        handleValueChange: (newValue: string) => void,
    ) => {
        const currentColIndex = rowValues.find(
            (item) => item.parameterId === parameterId,
        ).colIndex;

        const getNextValues = () => {
            return rowValues.slice(currentColIndex + 1, 7).map((cell) => {
                return {
                    id: cell.parameterId,
                    value: findParameterValueById(cell.parameterId),
                };
            });
        };

        const nextCellIsEmpty =
            rowValues[currentColIndex + 1]?.parameterValue === "" ||
            currentColIndex === 6;

        if (nextCellIsEmpty) {
            handleValueChange(formattedValue);
            return [];
        }

        const allNextValues = getNextValues();

        const updatePayload = [
            { id: parameterId, value: "" },
            ...allNextValues,
        ];

        const shiftedValues = shiftValuesToPreviousId(updatePayload);

        const finalArr = getSortedData(shiftedValues);

        return finalArr;
    };

    const handleSortingOnBlur = (
        parameterId: number,
        convertedTime: string,
    ) => {
        const getAllCellValues = () => {
            return rowValues.slice(1, 7).map((cell) => {
                if (cell.parameterId === parameterId) {
                    return { id: cell.parameterId, value: convertedTime };
                }

                return {
                    id: cell.parameterId,
                    value: findParameterValueById(cell.parameterId),
                };
            });
        };

        const allCellValues = getAllCellValues();
        const sortedArr = getSortedData(allCellValues);

        return sortedArr;
    };

    const compareCells = (
        cellValues: Component[],
        sortedData: UpdatePayloadObjProps[],
        parameterId: number,
    ): boolean => {
        return cellValues
            .filter((cell) => cell.parameterId === parameterId)
            .every((item1) => {
                const matchingItem = sortedData
                    .filter((cell) => cell.id === parameterId)
                    .find((item2) => item2.id === item1.parameterId);

                return matchingItem?.value === item1.parameterValue;
            });
    };

    return {
        checkIfValueIsValid,
        formatTimeFromInput,
        convertToTimeString,
        getShiftedData,
        handleSortingOnBlur,
        compareCells,
    };
};

export default useTimeInputFunctions;
