import { useContext } from "react";

import AppTypeContext from "../context/appType/appTypeContext";
import LayoutContext from "../context/layout/layoutContext";
import MenuItemContext from "../context/menuItem/menuItemContext";

import useTct from "./useTct";

interface FileData {
    filePath?: string;
}
interface DeviceData {
    portName: string;
    connectionType: number;
    firmwareVersion?: string;
}

const useConfiguration = () => {
    const { fileId } = useContext(AppTypeContext);
    const { setLayoutDataWithSettings } = useContext(LayoutContext);
    const { setComponentVisibilities } = useContext(MenuItemContext);

    const {
        getOfflineConfigurationAsync,
        getConfigurationFromFileAsync,
        connectAsync,
        reconnectAsync,
        getConfigurationFromFotaAsync,
    } = useTct();

    const getConfigurationFromDevice = async (
        portName: string,
        connectionType: number,
        reconnect?: boolean,
        firmwareVersion?: string,
    ) => {
        const data = await (reconnect
            ? reconnectAsync()
            : connectAsync(portName, connectionType));

        await updateLayoutContext(data, {} as FileData, {
            portName,
            connectionType,
            firmwareVersion,
        });
    };

    const getConfigurationFromFile = async (selectedFile: File) => {
        const data = await getConfigurationFromFileAsync(selectedFile);

        await updateLayoutContext(data, { filePath: selectedFile.name });
    };

    const getConfigurationFromOffline = async (
        hwVersion: string,
        specId?: number,
    ) => {
        const data = await getOfflineConfigurationAsync(hwVersion, specId);

        await updateLayoutContext(data);
    };

    const getConfigurationFromFota = async () => {
        const data = await getConfigurationFromFotaAsync(fileId);

        await updateLayoutContext(data);
    };

    const updateLayoutContext = async (
        data: any,
        fileData?: FileData,
        onlineDeviceData?: DeviceData,
    ) => {
        data?.initialValues.visibilities &&
            setComponentVisibilities(data.initialValues.visibilities);
        setLayoutDataWithSettings(
            data.hwVersion,
            data.specId,
            !!onlineDeviceData,
            {
                ...data,
                title: onlineDeviceData?.firmwareVersion ?? data.title,
                isOnline: !!onlineDeviceData,
                fromFile: !!fileData?.filePath,
                path: fileData?.filePath,
                port: onlineDeviceData?.portName,
                connectionType: onlineDeviceData?.connectionType,
            },
        );
    };

    return {
        getConfigurationFromDevice,
        getConfigurationFromFile,
        getConfigurationFromOffline,
        getConfigurationFromFota,
    };
};

export default useConfiguration;
