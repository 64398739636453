/** @jsxRuntime classic */

/** @jsx jsx */
import { SyntheticEvent, useContext } from "react";

import { ColumnInstance } from "react-table";

import { jsx } from "@emotion/react";

import {
    IoTableColumns,
    NameValueStringsPair,
    priorityColumns,
} from "../../../../../../../../constants/constants";
import InputOutputContext from "../../../../../../../../context/inputOutput/inputOutputContext";
import LanguageContext from "../../../../../../../../context/language/languageContext";
import FilterDropdown from "../../../../../../../MuiComponents/FilterDropdown";

interface PrioritySelectProps {
    columns: ColumnInstance[];
}
const PrioritySelect = ({ columns }: PrioritySelectProps) => {
    const { setActivePriority, activePriority } =
        useContext(InputOutputContext);

    const { t } = useContext(LanguageContext);

    const handleChange = (
        event: SyntheticEvent<any>,
        newValues: NameValueStringsPair[],
    ) => {
        event.preventDefault();

        const newActivePriorities = newValues.map(
            (newValue: NameValueStringsPair) => {
                return newValue.value;
            },
        );

        setActivePriority(newActivePriorities);
    };

    const isDisabled = !columns.some(
        (item) => item.id === IoTableColumns.priority,
    );

    const getValues = () => {
        return activePriority.map((prio: string) => {
            return priorityColumns.find((prioCol) => prioCol.value === prio);
        });
    };

    return (
        <FilterDropdown
            checkbox
            currentValue={getValues()}
            placeholder={t.Priority}
            options={priorityColumns}
            isDisabled={isDisabled}
            handleChange={handleChange}
            customCSS={{
                marginBottom: "12px",
            }}
        />
    );
};

export default PrioritySelect;
