import { useContext } from "react";

import ThemeContext from "../context/theme/themeContext";

const useSharedCSS = () => {
    const {
        colors: { blue700, red700, textDark },
    } = useContext(ThemeContext);

    const getMuiPaperStyles = (toMd: boolean, additionalStyles?: any) => ({
        "& .MuiPaper-root": {
            maxHeight: toMd ? "unset" : "626px",
            height: toMd ? "100%" : "unset",
            maxWidth: toMd ? "unset" : "448px",
            width: toMd ? "100%" : "calc(100% - 284px)",
            marginTop: toMd ? "0" : "10px",
            overflowY: toMd ? "auto" : "hidden",
            ...additionalStyles,
        },
    });

    const getModalTitleStyles = (justifyContent?: boolean) => ({
        display: "flex",
        ...(justifyContent && { justifyContent: "space-between" }),
        padding: "16px 30px 16px 24px",

        "& > h2": {
            color: textDark,
            fontSize: "20px",
            lineHeight: "24px",
            fontWeight: "600",
            letterSpacing: "0.15px",
            alignSelf: "flex-end",
        },
    });

    const renderBorderColor = (
        hasNoInputFocus: boolean | undefined,
        requestError: boolean,
        isOpen: boolean,
    ) => {
        if (hasNoInputFocus) {
            return "unset";
        }

        if (requestError) {
            return `1px solid ${red700}`;
        }

        if (isOpen) {
            return `1px solid ${blue700}`;
        }

        return "unset";
    };

    return { getMuiPaperStyles, getModalTitleStyles, renderBorderColor };
};

export default useSharedCSS;
