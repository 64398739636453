/** @jsxRuntime classic */

/** @jsx jsx */
import { Fragment, useContext, useReducer } from "react";

import { css, jsx } from "@emotion/react";

import CircularProgress from "@mui/material/CircularProgress";
import DialogTitle from "@mui/material/DialogTitle";

import { AlertStatus } from "../../../../../../../constants/constants";
import AlertContext from "../../../../../../../context/alert/alertContext";
import LanguageContext from "../../../../../../../context/language/languageContext";
import ThemeContext from "../../../../../../../context/theme/themeContext";
import { chooseValue } from "../../../../../../../utils/helpers";
import useApi from "../../../../../../../utils/useApi";
import DeviceLocked from "../DeviceLocked";
import SharedPinActions from "../SharedContent/SharedPinActions";
import SharedPinDialogContent from "../SharedContent/SharedPinDialogContent";
import {
    HANDLE_CURRENT_PIN,
    SET_LOADING,
    WRONG_CURRENT_PIN,
} from "../context/pinActions";
import enableDisablePinReducer from "../context/pinReducer";
import { SimPinModalTypes } from "../index";

interface EnableDisablePinProps {
    simId: number;
    close: () => void;
    type: SimPinModalTypes;
}

const EnableDisablePin = ({ simId, close, type }: EnableDisablePinProps) => {
    const {
        colors: { white },
    } = useContext(ThemeContext);

    const { selectedLanguage, t } = useContext(LanguageContext);
    const { setAlert } = useContext(AlertContext);

    const { postData } = useApi();

    const initialState = {
        isLoading: false,
        isDisabled: true,
        currentPin: null,
        errorMsg: null,
        retriesLeft: null,
    };

    const [state, dispatch] = useReducer(enableDisablePinReducer, initialState);

    const { isLoading, isDisabled, currentPin, errorMsg, retriesLeft } = state;

    const setLoading = (isLoadingSet: boolean) =>
        dispatch({ type: SET_LOADING, payload: isLoadingSet });

    const handlePayload = (value: string | null) =>
        dispatch({ type: HANDLE_CURRENT_PIN, payload: value });

    const sendPin = async () => {
        try {
            setLoading(true);

            const resource = chooseValue(
                type === SimPinModalTypes.EnablePin,
                "enablesimpin",
                "disablesimpin",
            );

            const { data } = await postData(
                `${selectedLanguage}/command/${resource}`,
                {
                    simId: simId,
                    pin: currentPin,
                },
            );

            if (data.success) {
                const message = chooseValue(
                    type === SimPinModalTypes.EnablePin,
                    t.PinSuccessfullyEnabled,
                    t.PinSuccessfullyDisabled,
                );

                setAlert(AlertStatus.Success, message);
                close();
            } else {
                dispatch({
                    type: WRONG_CURRENT_PIN,
                    payload: data.retriesLeft,
                });
            }
        } catch (error) {
            const message = chooseValue(
                type === SimPinModalTypes.EnablePin,
                t.ErrorOccurredEnablingPin,
                t.ErrorOccurredDisablingPin,
            );

            setAlert(AlertStatus.Critical, message);
            setLoading(false);
        }
    };

    const renderTitle = () =>
        chooseValue(
            type === SimPinModalTypes.EnablePin,
            t.EnablePinCode,
            t.DisablePinCode,
        );

    const renderButtonText = () => {
        const buttonTitle = chooseValue(
            type === SimPinModalTypes.EnablePin,
            t.Enable,
            t.Disable,
        );

        return isLoading ? (
            <CircularProgress
                size={20}
                css={css({
                    color: white,
                })}
            />
        ) : (
            buttonTitle
        );
    };

    const deviceLocked = () => retriesLeft && retriesLeft + 1 <= 1;

    return deviceLocked() ? (
        <DeviceLocked type={type} close={close} />
    ) : (
        <Fragment>
            <DialogTitle>{renderTitle()}</DialogTitle>

            <SharedPinDialogContent
                step={1}
                handlePayload={handlePayload}
                errorMsg={errorMsg}
                retriesLeft={retriesLeft}
                explanatoryText={t.EnterPinCode}
            />

            <SharedPinActions
                isLoading={isLoading}
                isDisabled={isDisabled}
                cancelIdForTesting="cancelButton"
                submitIdForTesting="submitButton"
                handleClose={close}
                sendPin={sendPin}
                renderButtonText={renderButtonText}
            />
        </Fragment>
    );
};

export default EnableDisablePin;
