/** @jsxRuntime classic */

/** @jsx jsx */
import { useContext } from "react";

import { css, jsx } from "@emotion/react";

import LanguageContext from "../../../../../context/language/languageContext";
import SettingsContext from "../../../../../context/settings/settingsContext";
import { LanguageInfo } from "../../../../../generatedTypes";
import useSharedCSS from "../../../../../utils/useSharedCSS";
import TextLink from "../../../../MuiComponents/TextLink";
import SharedSettingsPopover from "../SharedSettingsPopover";

import SingleLanguage from "./SingleLanguage";

const InterfaceSettingsModal = () => {
    const { languages } = useContext(LanguageContext);

    const {
        anchorEl,
        isLanguageSettingsModalOpen,
        closeLanguageSettingsModal,
        openSettingsModal,
    } = useContext(SettingsContext);

    const { getMuiPaperStyles, getModalTitleStyles } = useSharedCSS();

    return (
        <SharedSettingsPopover
            isOpen={isLanguageSettingsModalOpen}
            anchorEl={anchorEl}
            handleClose={closeLanguageSettingsModal}
            getMuiPaperStyles={getMuiPaperStyles}
        >
            <div css={css({ ...getModalTitleStyles() })}>
                <TextLink
                    size="normal"
                    onClick={() => openSettingsModal()}
                    idForTesting="language-settings-modal-back-button"
                    startIcon
                >
                    Go back
                </TextLink>
            </div>
            <div>
                {languages.map((lang: LanguageInfo) => (
                    <SingleLanguage data={lang} key={lang.shortName} />
                ))}
            </div>
        </SharedSettingsPopover>
    );
};

export default InterfaceSettingsModal;
