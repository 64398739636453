/** @jsxRuntime classic */

/** @jsx jsx */
import { useContext } from "react";

import { css, jsx } from "@emotion/react";

import { Popover } from "@mui/material";

import LanguageContext from "../../../../context/language/languageContext";
import ThemeContext from "../../../../context/theme/themeContext";

interface MoreFiltersPopoverProps {
    children: React.ReactNode;
    menuAnchorEl: HTMLElement | null;
    setMenuAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
}

const MoreFiltersPopover = ({
    children,
    menuAnchorEl,
    setMenuAnchorEl,
}: MoreFiltersPopoverProps) => {
    const {
        colors: { gray700 },
    } = useContext(ThemeContext);

    const { t } = useContext(LanguageContext);

    return (
        <Popover
            onClose={() => setMenuAnchorEl(null)}
            open={Boolean(menuAnchorEl)}
            anchorEl={menuAnchorEl}
            anchorPosition={{ top: 0, left: 0 }}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
            sx={{
                "& .MuiPaper-root": {
                    padding: "16px",
                    maxWidth: "211px",
                    width: "100%",
                },
            }}
        >
            <div
                css={css({
                    marginBottom: "16px",
                    fontWeight: 600,
                    fontSize: "14px",
                    lineHeight: "20px",
                    color: gray700,
                })}
            >
                {t.Filters}
            </div>
            {children}
        </Popover>
    );
};

export default MoreFiltersPopover;
