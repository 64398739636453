/** @jsxRuntime classic */

/** @jsx jsx */
import { Fragment, useContext } from "react";

import { css, jsx } from "@emotion/react";

import { ReactComponent as ChangeLog } from "../../../../../assets/icons/ChangeLog.svg";
import { ReactComponent as ComputerInfo } from "../../../../../assets/icons/ComputerInfo.svg";
import LanguageContext from "../../../../../context/language/languageContext";
import SettingsContext from "../../../../../context/settings/settingsContext";
import useSharedCSS from "../../../../../utils/useSharedCSS";
import TextLink from "../../../../MuiComponents/TextLink";
import SharedSettingsPopover from "../SharedSettingsPopover";

import SystemInformationModal from "./SystemInformationModal";
import VersionHistoryModal from "./VersionHistoryModal";
import VersionInfoItem from "./VersionInfoItem";

const VersionInfoModal = () => {
    const {
        anchorEl,
        isVersionInfoModalOpen,
        closeVersionInfoModal,
        openSettingsModal,
        openVersionHistoryModal,
        openSystemInformationModal,
    } = useContext(SettingsContext);
    const { t } = useContext(LanguageContext);

    const { getMuiPaperStyles, getModalTitleStyles } = useSharedCSS();

    const menuItems = [
        {
            title: t.ReleaseChangeLog,
            icon: <ChangeLog />,
            onClick: () => openVersionHistoryModal(),
        },
        {
            title: t.SystemInformation,
            icon: <ComputerInfo />,
            onClick: () => openSystemInformationModal(),
        },
    ];
    return (
        <Fragment>
            <SharedSettingsPopover
                isOpen={isVersionInfoModalOpen}
                anchorEl={anchorEl}
                handleClose={closeVersionInfoModal}
                getMuiPaperStyles={getMuiPaperStyles}
            >
                <div css={css({ ...getModalTitleStyles() })}>
                    <TextLink
                        size="normal"
                        onClick={() => openSettingsModal()}
                        idForTesting="settings-modal-back-button"
                        startIcon
                    >
                        Go back
                    </TextLink>
                </div>
                <div>
                    {menuItems.map((item: any, index: number) => (
                        <VersionInfoItem item={item} key={index} />
                    ))}
                </div>
            </SharedSettingsPopover>

            <VersionHistoryModal />
            <SystemInformationModal />
        </Fragment>
    );
};

export default VersionInfoModal;
