/** @jsxRuntime classic */

/** @jsx jsx */
import { Fragment, useContext, useState } from "react";

import { SerializedStyles, css, jsx } from "@emotion/react";

import ExportIcon from "@mui/icons-material/FileDownloadRounded";
import CircularProgress from "@mui/material/CircularProgress";

import { useAtom } from "jotai";

import {
    AlertStatus,
    FirmwareType,
    Loaders,
} from "../../../../../constants/constants";
import AlertContext from "../../../../../context/alert/alertContext";
import DeviceStatusContext from "../../../../../context/deviceStatus/deviceStatusContext";
import LanguageContext from "../../../../../context/language/languageContext";
import LayoutContext from "../../../../../context/layout/layoutContext";
import WebSocketsContext from "../../../../../context/webSockets/webSocketsContext";
import { DeviceStatusAtom } from "../../../../../context/webSockets/webSocketsState";
import {
    DeviceStatusInformation,
    DeviceStatusWindow,
} from "../../../../../generatedTypes";
import { extractEXIMFile } from "../../../../../utils/helpers";
import useApi from "../../../../../utils/useApi";
import useDesktopHost from "../../../../../utils/useDesktopHost";
import Badge, { BadgeColors } from "../../../../MuiComponents/Badge";
import Button from "../../../../MuiComponents/Button";
import Card from "../../../../MuiComponents/Card";
import Divider from "../../../../MuiComponents/Divider";
import UpdateFirmwareModal from "../../../../MuiComponents/Modals/UpdateFirmwareModal/UpdateFirmwareModal";
import ProgressBar from "../../../../MuiComponents/ProgressBar";
import TypedGrid from "../../../../MuiComponents/TypedGrid";

import FirmwareUpdate from "./FirmwareUpdate";
import FormatAgreementModal from "./FormatAgreementModal";
import RebootAgreementModal from "./RebootAgreementModal";
import RecordModal from "./RecordModal";

interface DeviceInformationProps {
    keyBaseStyles: SerializedStyles;
    valueBaseStyles: SerializedStyles;
    visibilitySettings: DeviceStatusWindow;
}

const DeviceInformation = ({
    keyBaseStyles,
    valueBaseStyles,
    visibilitySettings,
}: DeviceInformationProps) => {
    const [deviceStatus] = useAtom(DeviceStatusAtom);

    const {
        deviceName,
        deviceImei,
        firmwareVersion,
        lastStartTime,
        rtcTime,
        upTime,
        powerVoltage,
        storageData,
        batteryVoltage,
        batteryStatus,
    } = (deviceStatus as DeviceStatusInformation).deviceInformation;

    const dtbVersion = deviceStatus?.dtbVersion ?? null;

    const { setAlert } = useContext(AlertContext);

    const { selectedLanguage, t } = useContext(LanguageContext);

    const { layoutData } = useContext(LayoutContext);

    const readRecordsAllowed =
        visibilitySettings.isReadRecordsSupported &&
        layoutData?.readRecordsEnabled;

    const { postData, showErrorMsg, getData } = useApi();

    const { saveFileDialog, openFileDialog } = useDesktopHost();

    const { updateFirmwareModalInfo, setUpdateFirmwareModalInfo } =
        useContext(DeviceStatusContext);

    const { updateDtbAsync, updateFirmwareAsync } =
        useContext(WebSocketsContext);

    const [openedRebootAgreement, setOpenedRebootAgreement] = useState(false);
    const [openedFormatAgreement, setOpenedFormatAgreement] = useState(false);
    const [formatting, setFormatting] = useState(false);
    const [isModalOpen, setOpenedLogModal] = useState(false);
    const [exporting, setExporting] = useState(false);

    const openRebootAgreement = () => setOpenedRebootAgreement(true);
    const closeRebootAgreement = () => setOpenedRebootAgreement(false);

    const openForamtAgreement = () => setOpenedFormatAgreement(true);
    const closeFormatAgreement = () => setOpenedFormatAgreement(false);

    const handleExport = () => {
        saveFileDialog(
            {
                title: t.SaveFile,
                filters: [
                    {
                        extensions: ["html"],
                        name: "HTML file",
                    },
                ],
            },
            (result: string[]) => {
                if (result.length > 0) {
                    const exportToHtml = async () => {
                        setExporting(true);
                        try {
                            await postData(
                                `${selectedLanguage}/dataexport/status`,
                                result,
                            );

                            setAlert(
                                AlertStatus.Success,
                                t.StatusesSuccessfullyExported,
                            );
                        } catch (error) {
                            showErrorMsg(error as any);
                        } finally {
                            setExporting(false);
                        }
                    };

                    exportToHtml();
                }
            },
        );
    };

    const handleOpenLogModal = () => setOpenedLogModal(true);
    const handleCloseLogModal = () => setOpenedLogModal(false);

    const filterName = (isDtbUpdate: boolean) =>
        isDtbUpdate ? t.DeviceTreeDatabaseFile : t.FirmwareFile;

    const handleResult = async (result: string[], isDtbUpdate: boolean) => {
        if (result.length > 0) {
            try {
                let data: any = null;
                if (isDtbUpdate) {
                    const responseData = await getData(
                        `${selectedLanguage}/updatefile/dtb?filePath=${result[0]}`,
                    );
                    data = responseData?.data;
                }
                if (!isDtbUpdate) {
                    data = extractEXIMFile(result[0]);
                }

                setUpdateFirmwareModalInfo({
                    isOpen: true,
                    path: result[0],
                    step: 0,
                    newVersion: data,
                    firmwareType: isDtbUpdate
                        ? FirmwareType.Dtb
                        : FirmwareType.Firmware,
                    currentVersion: isDtbUpdate
                        ? dtbVersion
                        : (firmwareVersion ?? null),
                    error: "",
                });
            } catch (err: any) {
                const error = err.response?.data;
                setAlert(AlertStatus.Critical, error.detail);
                setUpdateFirmwareModalInfo({
                    isOpen: false,
                    path: "",
                    step: 0,
                    newVersion: "",
                    firmwareType: FirmwareType.None,
                    currentVersion: "",
                    error: "",
                });
            }
        }
    };

    const handleUpdate = async (isDtbUpdate: boolean) => {
        openFileDialog(
            {
                title: t.SelectFile,
                filters: [
                    {
                        extensions: ["e.xim"],
                        name: filterName(isDtbUpdate),
                    },
                ],
            },
            async (result: any) => {
                handleResult(result, isDtbUpdate);
            },
        );
    };

    return (
        <Card title={t.DeviceInfo}>
            {deviceName && (
                <Fragment>
                    <div
                        css={css({
                            margin: "8px 0",
                            "@media (max-width: 599px)": {
                                marginTop: "0",
                            },
                        })}
                    >
                        <TypedGrid container columnSpacing={2} rowSpacing={0.5}>
                            <TypedGrid item xs0={12} sm={6}>
                                <div css={css(keyBaseStyles)}>
                                    {t.DeviceName}
                                </div>
                            </TypedGrid>

                            <TypedGrid item xs0={12} sm={6}>
                                <div css={css(valueBaseStyles)}>
                                    {deviceName}
                                </div>
                            </TypedGrid>
                        </TypedGrid>
                    </div>

                    <Divider />
                </Fragment>
            )}

            {deviceImei && (
                <Fragment>
                    <div css={css({ margin: "8px 0" })}>
                        <TypedGrid container columnSpacing={2} rowSpacing={0.5}>
                            <TypedGrid item xs0={12} sm={6}>
                                <div css={css(keyBaseStyles)}>{t.Imei}</div>
                            </TypedGrid>

                            <TypedGrid item xs0={12} sm={6}>
                                <div css={css(valueBaseStyles)}>
                                    {deviceImei}
                                </div>
                            </TypedGrid>
                        </TypedGrid>
                    </div>

                    <Divider />
                </Fragment>
            )}

            <FirmwareUpdate onUpdate={handleUpdate} />

            <Divider />

            {visibilitySettings.isRebootSupported && (
                <Fragment>
                    <div css={css({ margin: "8px 0" })}>
                        <TypedGrid
                            container
                            columnSpacing={2}
                            rowSpacing={1.5}
                            alignItems="center"
                        >
                            <TypedGrid item xs0={12} sm={6}>
                                <div css={css(keyBaseStyles)}>
                                    {t.RebootDevice}
                                </div>
                            </TypedGrid>

                            <TypedGrid item xs0={12} sm={6}>
                                <Button
                                    fullWidth
                                    variant="textOnly"
                                    size="small"
                                    color="primarySubtle"
                                    onClick={openRebootAgreement}
                                    idForTesting="rebootButton"
                                >
                                    {t.Reboot}
                                </Button>
                            </TypedGrid>
                        </TypedGrid>
                    </div>

                    <Divider />
                </Fragment>
            )}
            {visibilitySettings.hasDtb && (
                <Fragment>
                    <div css={css({ margin: "8px 0" })}>
                        <TypedGrid
                            container
                            columnSpacing={2}
                            rowSpacing={1}
                            alignItems="center"
                        >
                            <TypedGrid item xs0={12} sm={6}>
                                <div css={css(keyBaseStyles)}>
                                    {t.DtbVersion}
                                </div>
                            </TypedGrid>

                            <TypedGrid item xs0={12} sm={6}>
                                <div>
                                    {visibilitySettings?.isDtbUpdateSupported && (
                                        <div
                                            css={css([
                                                {
                                                    marginBottom: "8px",
                                                },
                                                valueBaseStyles,
                                            ])}
                                        >
                                            {dtbVersion}
                                        </div>
                                    )}

                                    <Button
                                        fullWidth
                                        variant="textOnly"
                                        size="small"
                                        color="primary"
                                        onClick={() => handleUpdate(true)}
                                        idForTesting="updateDtb"
                                    >
                                        {t.Update}
                                    </Button>
                                </div>
                            </TypedGrid>
                        </TypedGrid>
                    </div>
                    <Divider />
                </Fragment>
            )}
            {lastStartTime && (
                <Fragment>
                    <div
                        css={css({
                            margin: "32px 0 8px 0",
                            "@media (max-width: 1439px)": {
                                marginTop: "24px",
                            },
                            "@media (max-width: 839px)": {
                                marginTop: "32px",
                            },
                            "@media (max-width: 599px)": {
                                marginTop: "24px",
                            },
                        })}
                    >
                        <TypedGrid container columnSpacing={2} rowSpacing={0.5}>
                            <TypedGrid item xs0={12} sm={6}>
                                <div css={css(keyBaseStyles)}>
                                    {t.LastStartTime}
                                </div>
                            </TypedGrid>

                            <TypedGrid item xs0={12} sm={6}>
                                <div css={css(valueBaseStyles)}>
                                    {lastStartTime}
                                </div>
                            </TypedGrid>
                        </TypedGrid>
                    </div>

                    <Divider />
                </Fragment>
            )}

            {rtcTime && (
                <Fragment>
                    <div css={css({ margin: "8px 0" })}>
                        <TypedGrid container columnSpacing={2} rowSpacing={0.5}>
                            <TypedGrid item xs0={12} sm={6}>
                                <div css={css(keyBaseStyles)}>{t.RtcTime}</div>
                            </TypedGrid>

                            <TypedGrid item xs0={12} sm={6}>
                                <div css={css(valueBaseStyles)}>{rtcTime}</div>
                            </TypedGrid>
                        </TypedGrid>
                    </div>

                    <Divider />
                </Fragment>
            )}

            {upTime && (
                <Fragment>
                    <div css={css({ margin: "8px 0" })}>
                        <TypedGrid container columnSpacing={2} rowSpacing={0.5}>
                            <TypedGrid item xs0={12} sm={6}>
                                <div css={css(keyBaseStyles)}>
                                    {t.DeviceUptime}
                                </div>
                            </TypedGrid>

                            <TypedGrid item xs0={12} sm={6}>
                                <div css={css(valueBaseStyles)}>{upTime}</div>
                            </TypedGrid>
                        </TypedGrid>
                    </div>

                    <Divider />
                </Fragment>
            )}

            {powerVoltage && (
                <Fragment>
                    <div
                        css={css({
                            margin: "32px 0 8px 0",
                            "@media (max-width: 599px)": {
                                marginTop: "24px",
                            },
                        })}
                    >
                        <TypedGrid container columnSpacing={2} rowSpacing={0.5}>
                            <TypedGrid item xs0={12} sm={6}>
                                <div css={css(keyBaseStyles)}>
                                    {t.PowerVoltage}
                                </div>
                            </TypedGrid>

                            <TypedGrid item xs0={12} sm={6}>
                                <div css={css(valueBaseStyles)}>
                                    {powerVoltage}
                                </div>
                            </TypedGrid>
                        </TypedGrid>
                    </div>

                    <Divider />
                </Fragment>
            )}

            <div css={css({ margin: "8px 0" })}>
                <TypedGrid
                    container
                    columnSpacing={2}
                    rowSpacing={1}
                    alignItems="center"
                >
                    <TypedGrid item xs0={12} sm={6}>
                        <div css={css(keyBaseStyles)}>
                            {t.ExtStorageUsedTotal}
                        </div>
                    </TypedGrid>
                    {storageData?.map((storage, index) => (
                        <TypedGrid item xs0={12} sm={6} key={index}>
                            <div>
                                <ProgressBar
                                    value={storage.extStoragePercentage}
                                    label={storage.usedTotalExtStorage}
                                />

                                {visibilitySettings.isFormatSupported && (
                                    <Button
                                        fullWidth
                                        variant={
                                            formatting ? "iconLeft" : "textOnly"
                                        }
                                        icon={
                                            formatting ? (
                                                <div
                                                    css={css({
                                                        width: "24px",
                                                        height: "24px",
                                                        display: "flex",
                                                        justifyContent:
                                                            "center",
                                                        alignItems: "center",
                                                    })}
                                                >
                                                    <CircularProgress
                                                        size={20}
                                                    />
                                                </div>
                                            ) : null
                                        }
                                        size="small"
                                        color="primarySubtle"
                                        onClick={openForamtAgreement}
                                        css={css({
                                            marginTop: "8px",
                                            pointerEvents: formatting
                                                ? "none"
                                                : "auto",
                                        })}
                                        idForTesting="formatButton"
                                    >
                                        {t.Format}
                                    </Button>
                                )}
                            </div>
                        </TypedGrid>
                    ))}
                </TypedGrid>
            </div>

            <Divider />

            {batteryVoltage && (
                <Fragment>
                    <div
                        css={css({
                            margin: "32px 0 8px 0",
                            "@media (max-width: 599px)": {
                                marginTop: "24px",
                            },
                        })}
                    >
                        <TypedGrid container columnSpacing={2} rowSpacing={0.5}>
                            <TypedGrid item xs0={12} sm={6}>
                                <div css={css(keyBaseStyles)}>
                                    {t.BatteryVoltage}
                                </div>
                            </TypedGrid>

                            <TypedGrid item xs0={12} sm={6}>
                                <div css={css(valueBaseStyles)}>
                                    {batteryVoltage}
                                </div>
                            </TypedGrid>
                        </TypedGrid>
                    </div>

                    <Divider />
                </Fragment>
            )}

            {batteryStatus && (
                <Fragment>
                    <div
                        css={css({
                            margin: "6px 0",
                            "@media (max-width: 599px)": {
                                margin: "8px 0",
                            },
                        })}
                    >
                        <TypedGrid
                            container
                            columnSpacing={2}
                            rowSpacing={0.5}
                            alignItems="center"
                        >
                            <TypedGrid item xs0={12} sm={6}>
                                <div css={css(keyBaseStyles)}>
                                    {t.InternalBatteryStatus}
                                </div>
                            </TypedGrid>

                            <TypedGrid item xs0={12} sm={6}>
                                <div
                                    css={css({
                                        display: "flex",
                                        alignItems: "center",
                                    })}
                                >
                                    <div
                                        css={css([
                                            {
                                                marginRight: "10px",
                                            },
                                            valueBaseStyles,
                                        ])}
                                    >
                                        {batteryStatus.title}
                                    </div>

                                    {batteryStatus.badge && (
                                        <Badge
                                            color={
                                                batteryStatus.badge
                                                    .color as unknown as BadgeColors
                                            }
                                        >
                                            {batteryStatus.badge.label}
                                        </Badge>
                                    )}
                                </div>
                            </TypedGrid>
                        </TypedGrid>
                    </div>

                    <Divider />
                </Fragment>
            )}

            {readRecordsAllowed && (
                <Fragment>
                    <div css={css({ margin: "8px 0" })}>
                        <TypedGrid
                            container
                            columnSpacing={2}
                            rowSpacing={1.5}
                            alignItems="center"
                        >
                            <TypedGrid item xs0={12} sm={6}>
                                <div css={css(keyBaseStyles)}>
                                    {t.DeviceRecordLogs}
                                </div>
                            </TypedGrid>

                            <TypedGrid item xs0={12} sm={6}>
                                <Button
                                    fullWidth
                                    variant="textOnly"
                                    size="small"
                                    color="primarySubtle"
                                    onClick={handleOpenLogModal}
                                    idForTesting="readRecordsButton"
                                >
                                    {t.ReadRecords}
                                </Button>
                            </TypedGrid>
                        </TypedGrid>
                    </div>

                    <Divider />
                </Fragment>
            )}

            <Button
                fullWidth
                variant="iconLeft"
                size="small"
                color="primarySubtle"
                icon={
                    exporting ? (
                        <div
                            css={css({
                                width: "24px",
                                height: "24px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            })}
                        >
                            <CircularProgress size={20} />
                        </div>
                    ) : (
                        <ExportIcon />
                    )
                }
                onClick={handleExport}
                css={css({
                    margin: "24px 0 8px 0",
                    pointerEvents: exporting ? "none" : "auto",
                })}
                idForTesting="exportButton"
            >
                {t.Export}
            </Button>

            <div
                css={css({
                    fontSize: "12px",
                    lineHeight: "16px",
                    letterSpacing: "0.4px",
                    textAlign: "center",
                })}
            >
                {t.ExportCurrentStatuses}
            </div>

            {updateFirmwareModalInfo.isOpen &&
                updateFirmwareModalInfo.firmwareType ===
                    FirmwareType.Firmware && (
                    <UpdateFirmwareModal
                        isOpen={
                            updateFirmwareModalInfo.isOpen &&
                            updateFirmwareModalInfo.firmwareType ===
                                FirmwareType.Firmware
                        }
                        onUpdateStart={() =>
                            updateFirmwareAsync(
                                updateFirmwareModalInfo?.path,
                                Loaders.DeviceFirmwareUpdate,
                            )
                        }
                        title={t.FirmwareUpdate}
                        description={t.FirmwareUpdateDescription}
                        extensions={["e.xim"]}
                        loaderName={Loaders.DeviceFirmwareUpdate}
                    />
                )}
            {updateFirmwareModalInfo.isOpen &&
                updateFirmwareModalInfo.firmwareType === FirmwareType.Dtb && (
                    <UpdateFirmwareModal
                        isOpen={
                            updateFirmwareModalInfo.isOpen &&
                            updateFirmwareModalInfo.firmwareType ===
                                FirmwareType.Dtb
                        }
                        onUpdateStart={() =>
                            updateDtbAsync(
                                updateFirmwareModalInfo?.path,
                                Loaders.DtbUpdate,
                            )
                        }
                        title={t.DtbVersionUpdate}
                        description={t.DtbVersionUpdateDescription}
                        extensions={["e.xim"]}
                        loaderName={Loaders.DtbUpdate}
                    />
                )}
            <RebootAgreementModal
                open={openedRebootAgreement}
                close={closeRebootAgreement}
            />

            <FormatAgreementModal
                open={openedFormatAgreement}
                close={closeFormatAgreement}
                startFormatting={() => setFormatting(true)}
                stopFormatting={() => setFormatting(false)}
            />

            {isModalOpen && (
                <RecordModal
                    isOpen={isModalOpen}
                    onClose={handleCloseLogModal}
                />
            )}
        </Card>
    );
};

export default DeviceInformation;
