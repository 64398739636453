/** @jsxRuntime classic */

/** @jsx jsx */
import { useContext } from "react";

import { css, jsx } from "@emotion/react";

import { NOT_AVAILABLE_VALUE } from "../../../../../../../constants/constants";
import ThemeContext from "../../../../../../../context/theme/themeContext";
import Tooltip from "../../../../../../MuiComponents/Tooltip";

interface CurrentValueProps {
    props: any;
    formatDataForSwitch: {
        original: any;
    };
}

const CurrentValue = ({ props, formatDataForSwitch }: CurrentValueProps) => {
    const {
        colors: { gray100 },
    } = useContext(ThemeContext);

    const rowValue =
        props.state.currentValues[
            formatDataForSwitch.original.parameterValue.id
        ];
    const maxShownStringLength = 14;

    return (
        <div
            css={css({
                display: "flex",
                alignItems: "center",
                fontWeight: 600,
                fontSize: "14px",
                backgroundColor: gray100,
                height: "100%",
                width: "100%",
            })}
        >
            {props.state.currentValues && !!rowValue?.trim().length ? (
                <Tooltip
                    title={
                        rowValue.length > maxShownStringLength ? rowValue : ""
                    }
                    small
                    placement="top"
                >
                    <span
                        css={css({
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            paddingLeft: "16px",
                            paddingRight: "16px",
                        })}
                    >
                        {rowValue}
                    </span>
                </Tooltip>
            ) : (
                <span
                    css={css({
                        paddingLeft: "16px",
                        paddingRight: "16px",
                    })}
                >
                    {NOT_AVAILABLE_VALUE}
                </span>
            )}
        </div>
    );
};

export default CurrentValue;
