/** @jsxRuntime classic */

/** @jsx jsx */
import React, { Fragment, useContext, useEffect, useState } from "react";

import { generatePath, useHistory, useParams } from "react-router-dom";

import { css, jsx } from "@emotion/react";

import { Settings } from "@mui/icons-material";

import DeviceStatusIcon from "../../../../assets/icons/DeviceStatusIcon";
import AccessoriesIcon from "../../../../assets/settingsIcons/AccessoriesIcon";
import BluetoothIcon from "../../../../assets/settingsIcons/BluetoothIcon";
import FeaturesIcon from "../../../../assets/settingsIcons/FeaturesIcon";
import InputOutputIcon from "../../../../assets/settingsIcons/InputOutputIcon";
import MobileNetworkIcon from "../../../../assets/settingsIcons/MobileNetworkIcon";
import SmsCallSettingsIcon from "../../../../assets/settingsIcons/SmsCallSettingsIcon";
import SpecIdIcon from "../../../../assets/settingsIcons/SpecIdIcon";
import SystemIcon from "../../../../assets/settingsIcons/SystemIcon";
import TrackingSettingsIcon from "../../../../assets/settingsIcons/TrackingSettingsIcon";
import { EDITOR_MENU_ITEM, EDITOR_STATUS } from "../../../../constants/routes";
import LanguageContext from "../../../../context/language/languageContext";
import LayoutContext from "../../../../context/layout/layoutContext";
import UserInfoContext from "../../../../context/userInfo/userInfoContext";
import { TranslatableName } from "../../../../generatedTypes";
import { noop } from "../../../../utils/helpers";
import { DeviceFamily } from "../../../../utils/types";
import useTct from "../../../../utils/useTct";
import DialogModal from "../../../MuiComponents/Modals/DialogModal";
import NavigationListButton from "../../../MuiComponents/NavigationListButton";
import { MenuItemViewParams } from "../../MenuItemView";

export interface MenuItemSettingsProps {
    onNavigationItemClick: () => void;
}

const BaseSpecIdentifier = 1;

const MenuItemSettings = ({ onNavigationItemClick }: MenuItemSettingsProps) => {
    const { t, selectedLanguage } = useContext(LanguageContext);
    const history = useHistory();
    const { menuItem } = useParams<MenuItemViewParams>();
    const { translateAsync } = useTct();

    const {
        layoutData,
        actionLoading,
        navigationDisabled,
        disableNavigationBetweenMenuItems,
        setNextMenuItemIndex,
        menuItemIndex,
    } = useContext(LayoutContext);
    const { isDesktopApplication } = useContext(UserInfoContext);

    const [isNavigationDisabledModalOpen, setNavigationDisabledModal] =
        useState(false);

    const [navigationNenuItems, setNavigationMenuItems] = useState<
        TranslatableName[]
    >(layoutData?.menuItems || []);

    useEffect(() => {
        const getUpdatedMenuTranslations = async () => {
            const messageKeys =
                layoutData?.menuItems.map((item) => item.name) ?? [];
            const result = await translateAsync(
                layoutData?.id ?? 0,
                messageKeys,
                layoutData?.deviceFamily ?? DeviceFamily.Undefined,
                layoutData?.specId ?? BaseSpecIdentifier,
            );
            setNavigationMenuItems(result);
        };

        layoutData?.menuItems.length && getUpdatedMenuTranslations();
    }, [selectedLanguage]);

    const icons: { [key: string]: React.JSX.Element } = {
        "Device status": <DeviceStatusIcon />, // change this one to device status
        "Mobile network": <MobileNetworkIcon />,
        System: <SystemIcon />,
        "Tracking settings": <TrackingSettingsIcon />,
        Features: <FeaturesIcon />,
        "SMS / Call settings": <SmsCallSettingsIcon />,
        "Input / Output (I/O)": <InputOutputIcon />,
        Bluetooth: <BluetoothIcon />,
        Accessories: <AccessoriesIcon />,
        "Spec ID": <SpecIdIcon />,
    };

    const handleOnNavigationClick = (index: number) => {
        onNavigationItemClick();
        if (navigationDisabled) {
            setNextMenuItemIndex(index);
            setNavigationDisabledModal(true);
        } else {
            history.push(generatePath(EDITOR_MENU_ITEM, { menuItem: index }));
        }
    };
    const handleOnModalClose = () => setNavigationDisabledModal(false);
    const handleOnModalSubmit = () => {
        disableNavigationBetweenMenuItems(false);
        history.push(
            generatePath(EDITOR_MENU_ITEM, {
                menuItem: menuItemIndex as number,
            }),
        );
    };

    const handleOnStatusClick = () => {
        if (!isDesktopApplication) {
            noop();
        }
        history.push(EDITOR_STATUS);
    };

    const menuItemsWithStatus = isDesktopApplication
        ? [
              {
                  name: "Device status",
                  translatedName: t["Device status"],
              },
              ...navigationNenuItems,
          ]
        : navigationNenuItems;

    const isActive = (index: number) => {
        if (menuItem === undefined && index === -1) {
            return true;
        }
        return Number(menuItem) === index;
    };

    return (
        <Fragment>
            <div>
                {menuItemsWithStatus.map((item, index: number) => {
                    const menuItemId = index - (isDesktopApplication ? 1 : 0); // -1 because of the status menu item

                    return (
                        <NavigationListButton
                            key={menuItemId}
                            fullWidth
                            isActive={isActive(menuItemId)}
                            icon={icons[item.name] || <Settings />}
                            disabled={actionLoading}
                            onClick={
                                menuItemId === -1
                                    ? handleOnStatusClick
                                    : () => handleOnNavigationClick(menuItemId)
                            }
                            css={css({ textAlign: "left" })}
                            idForTest={"menuItem" + menuItemId}
                        >
                            {item.translatedName}
                        </NavigationListButton>
                    );
                })}
            </div>
            <DialogModal
                open={isNavigationDisabledModalOpen}
                close={handleOnModalClose}
                onPrimaryBtnClick={handleOnModalClose}
                primaryButtonText={"Stay on this page"}
                onSecondaryBtnClick={handleOnModalSubmit}
                secondaryButtonText={"Leave the page anyway"}
                title="Confirm parameter values"
                description="If you leave this page without confirming parameter values, progress will be lost"
                withCancel
            />
        </Fragment>
    );
};

export default MenuItemSettings;
