/** @jsxRuntime classic */

/** @jsx jsx */
import React, { useContext, useState } from "react";

import { css, jsx } from "@emotion/react";

import { AlertStatus } from "../../../../../../../constants/constants";
import AlertContext from "../../../../../../../context/alert/alertContext";
import LanguageContext from "../../../../../../../context/language/languageContext";
import MenuItemContext from "../../../../../../../context/menuItem/menuItemContext";
import { Component, Modal } from "../../../../../../../generatedTypes";
import { toFirstLowerCase } from "../../../../../../../utils/helpers";
import Collection from "../../../Component/Collection";
import NumberInput from "../../../Component/NumberInput";
import SharedFormModal from "../../../SharedFormModal";

export interface NotificationModalProps {
    isOpen: boolean;
    data?: Modal;
    onClose?: () => void;
    isEdit?: boolean;
}

const NotificationModal: React.FunctionComponent<NotificationModalProps> = ({
    isOpen,
    onClose,
    data,
    isEdit,
}) => {
    const { t } = useContext(LanguageContext);
    const { updateParameters } = useContext(MenuItemContext);
    const { setAlert } = useContext(AlertContext);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [values, setValues] = useState<{ [key: string]: any }>({});
    const [errors, setErrors] = useState<{ [key: string]: any }>({});

    const sourceId =
        data?.components.find(
            (item) => item.localizationKey === "Custom scenario output source",
        )?.parameterId ?? "";

    const hasErrors = Object.values(errors).some((error) => Boolean(error));

    const handleChange = (event: {
        id: number;
        value: string;
        error?: string;
    }) => {
        const { id, value, error } = event;
        if (!error) {
            setValues((prevValues) => ({
                ...prevValues,
                [id]: value,
            }));
        }
        setErrors({ [id]: error });
    };

    const handleSubmit = async () => {
        setSubmitLoading(true);
        try {
            const transformedValues = Object.entries(values).map(
                ([id, value]) => ({
                    id: Number(id),
                    value: value,
                }),
            );
            updateParameters(
                transformedValues,
                () => setSubmitLoading(false),
                () => setSubmitLoading(false),
                false,
            );

            setAlert(
                AlertStatus.Success,
                `${data?.name} ${toFirstLowerCase(!isEdit ? t.SuccessfullyAdded : t.SuccessfullyUpdated || "")}`,
            );
            setSubmitLoading(false);
            onClose?.();
        } catch (error) {
            setSubmitLoading(false);
            setAlert(AlertStatus.Critical, t.FailedToUpdateParameters);
        }
    };

    const renderDynamicComponent = (component: Component) => {
        if (component.componentType === "NumberInput") {
            return (
                <NumberInput
                    data={component}
                    onChange={handleChange}
                    resetErrorOnUnmount
                />
            );
        }
        if (component.componentType === "Collection") {
            return <Collection data={component} onChange={handleChange} />;
        }
    };

    const renderContent = () => {
        return (
            <div
                css={css({
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                })}
            >
                {(data?.components || []).map((value, index) =>
                    renderDynamicComponent(value),
                )}
            </div>
        );
    };

    return (
        <SharedFormModal
            data={data}
            sourceId={sourceId}
            values={values}
            submitLoading={submitLoading}
            hasErrors={hasErrors}
            isEdit={isEdit}
            isOpen={isOpen}
            handleSubmit={handleSubmit}
            handleClose={onClose}
            renderContent={renderContent}
        />
    );
};

export default NotificationModal;
