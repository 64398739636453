/** @jsxRuntime classic */

/** @jsx jsx */
import React from "react";

import { css, jsx } from "@emotion/react";

export interface LabelProps {
    data: { title?: string };
}

const Label: React.FunctionComponent<LabelProps> = ({ data }) => {
    const { title } = data;
    return (
        <div
            css={css({
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "20px",
                textAlign: "center",
                letterSpacing: "0.1px",
            })}
        >
            {title}
        </div>
    );
};

export default Label;
