import React from "react";

import { Cell } from "react-table";

import { Skeleton } from "@mui/material";

const MemoizedRow = React.memo(({ index, style, data }: any) => {
    const { rows, prepareRow, colors, boxShadows, visibleIndexes } = data;

    const row = rows[index];

    prepareRow(row);

    if (
        visibleIndexes &&
        (index < visibleIndexes.start || index > visibleIndexes.end)
    ) {
        return (
            <div
                style={{
                    ...style,
                    display: "flex",
                    width: "100%",
                    boxShadow: boxShadows.grayBoxShadow,
                    zIndex: 10,
                    background: colors.white,
                }}
                key={`skeleton-${index}`}
            >
                {row.cells.map((cell: Cell) => (
                    <div
                        key={cell.column.id}
                        style={{
                            ...cell.getCellProps().style,
                            borderBottom: "1px solid rgba(224, 224, 224, 1)",
                            borderLeft: "1px solid rgba(224, 224, 224, 1)",
                            minHeight: "100%",
                            display: "flex",
                            alignItems: "center",
                            padding: "8px 16px",
                        }}
                    >
                        <Skeleton
                            height="20px"
                            width="100%"
                            variant="rectangular"
                        />
                    </div>
                ))}
            </div>
        );
    }

    return (
        <div
            {...row.getRowProps()}
            style={{
                ...style,
                display: "flex",
                width: "100%",
                boxShadow: boxShadows.grayBoxShadow,
            }}
            key={`row-${row.id}`}
        >
            {row.cells.map((cell: any, cellIndex: number) => (
                <div
                    {...cell.getCellProps()}
                    key={cell.id}
                    style={{
                        ...cell.getCellProps().style,
                        position: cellIndex === 0 ? "sticky" : "unset",
                        left: cellIndex === 0 ? 0 : "unset",
                        zIndex: cellIndex === 0 ? 1 : 0,
                        background: cellIndex === 0 ? colors.white : "unset",
                        borderBottom: "1px solid rgba(224, 224, 224, 1)",
                        minHeight: "100%", // Ensure cell takes full height
                        borderLeft: "1px solid rgba(224, 224, 224, 1)",
                        display: "flex",
                        alignItems: "center",
                        padding: cellIndex === 0 ? "6px 18px" : "inherit",
                    }}
                >
                    {cell.render("Cell")}
                </div>
            ))}
        </div>
    );
});

export default MemoizedRow;
