/** @jsxRuntime classic */

/** @jsx jsx */
import { useContext } from "react";

import { css, jsx } from "@emotion/react";

import ThemeContext from "../../../../../../context/theme/themeContext";

import { ReadOnlyTableProps, RowValuesProps } from "./types";

const ReadOnlyTable = ({
    tableRows,
    toSm,
    getRowValues,
    renderReadOnlyTableCell,
    renderNotAvailableCell,
}: ReadOnlyTableProps) => {
    const {
        colors: { gray300 },
    } = useContext(ThemeContext);

    return (
        <div>
            {tableRows?.map((row, rowIndex) => {
                const rowValues = getRowValues(row);

                const rowKey =
                    rowValues[0]?.parameterId === 0
                        ? rowValues[0]?.label
                        : rowValues[0]?.parameterId;

                const rowValuesWithValues = rowValues.filter(
                    (obj: RowValuesProps) => obj?.parameterValue?.trim(),
                );

                return (
                    <div
                        key={`row-${rowKey}-read-only`}
                        css={css({
                            display: "flex",
                            flexDirection: toSm ? "column" : "row",
                            borderBottom: `1px solid ${gray300}`,
                        })}
                    >
                        {rowValues.map((cell, colIndex) => {
                            if (cell.parameterId === 0) {
                                return renderReadOnlyTableCell(
                                    cell,
                                    rowIndex,
                                    colIndex,
                                    false,
                                    rowValues,
                                );
                            }
                            return null;
                        })}

                        <div
                            css={css({
                                display: "flex",
                                flexWrap: "wrap",
                                flex: 1,
                                gap: "6px",
                                padding: toSm ? "8px 0" : "8px",
                            })}
                        >
                            {rowValuesWithValues.length > 0
                                ? rowValuesWithValues.map((cell, colIndex) => {
                                      const nextCellIsEmpty =
                                          !rowValuesWithValues[colIndex + 1]
                                              ?.parameterValue;

                                      return renderReadOnlyTableCell(
                                          cell,
                                          rowIndex,
                                          colIndex,
                                          nextCellIsEmpty,
                                          rowValuesWithValues,
                                      );
                                  })
                                : renderNotAvailableCell(rowIndex)}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default ReadOnlyTable;
