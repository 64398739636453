/** @jsxRuntime classic */

/** @jsx jsx */
import { Row } from "react-table";

import { css, jsx } from "@emotion/react";

import {
    IoTableColumnTitles,
    IoTableColumns,
    NOT_AVAILABLE_VALUE,
} from "../../../../../../../constants/constants";
import Tooltip from "../../../../../../MuiComponents/Tooltip";
import TableCollection from "../components/TableCollection";
import TableInput from "../components/TableInput";

import { sharedColumns } from "./sharedColumns";

export const getIoTableColumns = (
    name: string,
    currentValues: any,
    gray100: any,
) => {
    const ioTableSpecificColumns = [
        {
            index: 1,
            id: IoTableColumns.currentValue,
            accessor: IoTableColumns.currentValue,
            Header: IoTableColumnTitles.currentValue,
            filter: (allRows: Row<any>[]) => {
                return allRows.filter(
                    (row) =>
                        !!currentValues[
                            row.original?.parameterValue?.id
                        ]?.trim().length,
                );
            },
            Cell: ({ row, ...other }: any) => {
                const rowValue =
                    other.state.currentValues[row.original?.parameterValue?.id];
                const maxShownStringLength = 14;

                return (
                    <div
                        css={css({
                            display: "flex",
                            alignItems: "center",
                            fontWeight: 600,
                            fontSize: "14px",
                            backgroundColor: gray100,
                            height: "100%",
                            width: "100%",
                        })}
                    >
                        {other.state.currentValues &&
                        !!rowValue?.trim().length ? (
                            <Tooltip
                                title={
                                    rowValue.length > maxShownStringLength
                                        ? rowValue
                                        : ""
                                }
                                small
                                placement="top"
                            >
                                <span
                                    css={css({
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                        paddingLeft: "16px",
                                        paddingRight: "16px",
                                    })}
                                >
                                    {rowValue}
                                </span>
                            </Tooltip>
                        ) : (
                            <span
                                css={css({
                                    paddingLeft: "16px",
                                    paddingRight: "16px",
                                })}
                            >
                                {NOT_AVAILABLE_VALUE}
                            </span>
                        )}
                    </div>
                );
            },
        },
        {
            index: 4,
            id: IoTableColumns.lowLevel,
            accessor: IoTableColumns.lowLevel,
            Header: IoTableColumnTitles.lowLevel,
            Cell: ({ row }: any) => {
                return row.original.lowLevel ? (
                    <TableInput
                        switchId={row.original.parameterValue.id}
                        data={row.original.lowLevel}
                        key={row.original.parameterValue.id}
                    />
                ) : null;
            },
        },
        {
            index: 5,
            id: IoTableColumns.highLevel,
            accessor: IoTableColumns.highLevel,
            Header: IoTableColumnTitles.highLevel,
            Cell: ({ row }: any) => {
                return row.original.highLevel ? (
                    <TableInput
                        switchId={row.original.parameterValue.id}
                        data={row.original.highLevel}
                        key={row.original.parameterValue.id}
                    />
                ) : null;
            },
        },
        {
            index: 6,
            id: IoTableColumns.eventOnly,
            accessor: IoTableColumns.eventOnly,
            Header: IoTableColumnTitles.eventOnly,
            Cell: ({ row }: any) => {
                return row.original.eventOnly ? (
                    <TableCollection
                        data={row.original.eventOnly}
                        switchId={row.original.parameterValue.id}
                        key={row.original.parameterValue.id}
                        onOptionClick={(e) =>
                            row.setState((state: any) => {
                                return {
                                    cellState: {
                                        ...state?.cellState,
                                        eventOnly: e,
                                    },
                                };
                            })
                        }
                    />
                ) : null;
            },
        },
    ];

    const sortedColumns = [
        ...sharedColumns(name, false),
        ...ioTableSpecificColumns,
    ].sort((a, b) => a.index - b.index);

    return sortedColumns;
};

export const getIoFilters = (
    activeFilters: any[],
    activePriority: any[],
    activeOperand: any[],
    activeStatus: string,
) => {
    const includesActiveFilter = (filter: string) =>
        activeFilters.includes(filter) ? true : undefined;
    return [
        {
            id: IoTableColumns.currentValue,
            value: includesActiveFilter(IoTableColumns.currentValue),
        },
        {
            id: IoTableColumns.priority,
            value: activePriority.length ? activePriority : undefined,
        },
        {
            id: IoTableColumns.operand,
            value: activeOperand.length ? activeOperand : undefined,
        },
        {
            id: IoTableColumns.name,
            value: activeStatus !== "2" ? activeStatus : undefined,
        },
    ];
};
