/** @jsxRuntime classic */

/** @jsx jsx */
import { Fragment } from "react";

import { jsx } from "@emotion/react";

import InputOutputState from "../../../../../context/inputOutput/inputOutputState";
import { Block, Frame, IoRow, Segment } from "../../../../../generatedTypes";

import GenericTable from "./GenericTable";
import IoTable from "./IoTable";
import { useIoTableItemRender } from "./hooks/useIoTableItemRender";

export interface IoFrameProps {
    data: Frame;
}

const IoFrame = ({ data }: IoFrameProps) => {
    const { blocks } = data;

    const { getCanIoItemData, getRegularIoItemData, getGenericTableItemData } =
        useIoTableItemRender();

    const getTableData = (segments: Segment[], block: Block): any[] => {
        const genericTable = segments[0].genericTable;
        const tableRows = genericTable ? genericTable.rows : segments;
        return (
            tableRows?.map((segment: Segment | IoRow) => {
                if (segment.canItem) {
                    return getCanIoItemData(block, segment);
                }
                if (segment.ioItem) {
                    return getRegularIoItemData(block, segment);
                }
                if (genericTable) {
                    return getGenericTableItemData(block, genericTable);
                }
                return null;
            }) ?? []
        );
    };

    return (
        <Fragment>
            {blocks.map((block, i) => {
                const { name, ioColumnLocalization, index, segments } = block;
                const tableData = getTableData(segments, block);
                if (
                    segments[0].genericTable &&
                    segments[0].genericTable.componentType === "Generic"
                ) {
                    return (
                        <GenericTable
                            key={`${index}_${name}`}
                            tableData={tableData[0]}
                            columns={segments[0].genericTable.columns}
                            ioColumnLocalization={
                                ioColumnLocalization?.columnLocalization || []
                            }
                            index={index}
                        />
                    );
                }

                return (
                    <IoTable
                        index={index}
                        key={index}
                        name={name ?? ""}
                        data={tableData}
                        isFirstTable={i === 0}
                        isCanTable={tableData[0].canId !== undefined}
                        ioColumnLocalization={
                            ioColumnLocalization?.columnLocalization || []
                        }
                    />
                );
            })}
        </Fragment>
    );
};

const IoFrameWithState = ({ data }: IoFrameProps) => {
    return (
        <InputOutputState>
            <IoFrame data={data} />
        </InputOutputState>
    );
};

export default IoFrameWithState;
