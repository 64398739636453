import React from "react";

import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export interface RectangleIconProps extends SvgIconProps {
    css?: React.CSSProperties;
}

const RectangleIcon = ({ css, ...props }: RectangleIconProps) => {
    return (
        <SvgIcon
            style={css}
            width={32}
            height={32}
            viewBox="0 0 32 32"
            {...props}
        >
            <svg
                width="32"
                height="33"
                viewBox="0 0 32 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect
                    y="0.00390625"
                    width="32"
                    height="32"
                    rx="8"
                    fill="#F1F5F9"
                />
                <g clipPath="url(#clip0_17126_69420)">
                    <path
                        d="M9 9.00391L23 23.0039"
                        stroke="#334155"
                        strokeDasharray="2 2"
                    />
                    <mask id="path-3-inside-1_17126_69420" fill="white">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M8 10.0039C9.10457 10.0039 10 9.10848 10 8.00391C10 6.89934 9.10457 6.00391 8 6.00391C6.89543 6.00391 6 6.89934 6 8.00391C6 9.10848 6.89543 10.0039 8 10.0039ZM8 9.00391C8.55228 9.00391 9 8.55619 9 8.00391C9 7.45162 8.55228 7.00391 8 7.00391C7.44772 7.00391 7 7.45162 7 8.00391C7 8.55619 7.44772 9.00391 8 9.00391Z"
                        />
                    </mask>
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8 10.0039C9.10457 10.0039 10 9.10848 10 8.00391C10 6.89934 9.10457 6.00391 8 6.00391C6.89543 6.00391 6 6.89934 6 8.00391C6 9.10848 6.89543 10.0039 8 10.0039ZM8 9.00391C8.55228 9.00391 9 8.55619 9 8.00391C9 7.45162 8.55228 7.00391 8 7.00391C7.44772 7.00391 7 7.45162 7 8.00391C7 8.55619 7.44772 9.00391 8 9.00391Z"
                        fill="#F1F5F9"
                    />
                    <path
                        d="M8.8 8.00391C8.8 8.44573 8.44183 8.80391 8 8.80391V11.2039C9.76731 11.2039 11.2 9.77122 11.2 8.00391H8.8ZM8 7.20391C8.44183 7.20391 8.8 7.56208 8.8 8.00391H11.2C11.2 6.2366 9.76731 4.80391 8 4.80391V7.20391ZM7.2 8.00391C7.2 7.56208 7.55817 7.20391 8 7.20391V4.80391C6.23269 4.80391 4.8 6.2366 4.8 8.00391H7.2ZM8 8.80391C7.55817 8.80391 7.2 8.44573 7.2 8.00391H4.8C4.8 9.77122 6.23269 11.2039 8 11.2039V8.80391ZM7.8 8.00391C7.8 7.89345 7.88954 7.80391 8 7.80391V10.2039C9.21503 10.2039 10.2 9.21893 10.2 8.00391H7.8ZM8 8.20391C7.88954 8.20391 7.8 8.11436 7.8 8.00391H10.2C10.2 6.78888 9.21503 5.80391 8 5.80391V8.20391ZM8.2 8.00391C8.2 8.11436 8.11046 8.20391 8 8.20391V5.80391C6.78497 5.80391 5.8 6.78888 5.8 8.00391H8.2ZM8 7.80391C8.11046 7.80391 8.2 7.89345 8.2 8.00391H5.8C5.8 9.21893 6.78497 10.2039 8 10.2039V7.80391Z"
                        fill="#334155"
                        mask="url(#path-3-inside-1_17126_69420)"
                    />
                    <mask id="path-5-inside-2_17126_69420" fill="white">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M24 26.0039C25.1046 26.0039 26 25.1085 26 24.0039C26 22.8993 25.1046 22.0039 24 22.0039C22.8954 22.0039 22 22.8993 22 24.0039C22 25.1085 22.8954 26.0039 24 26.0039ZM24 25.0039C24.5523 25.0039 25 24.5562 25 24.0039C25 23.4516 24.5523 23.0039 24 23.0039C23.4477 23.0039 23 23.4516 23 24.0039C23 24.5562 23.4477 25.0039 24 25.0039Z"
                        />
                    </mask>
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M24 26.0039C25.1046 26.0039 26 25.1085 26 24.0039C26 22.8993 25.1046 22.0039 24 22.0039C22.8954 22.0039 22 22.8993 22 24.0039C22 25.1085 22.8954 26.0039 24 26.0039ZM24 25.0039C24.5523 25.0039 25 24.5562 25 24.0039C25 23.4516 24.5523 23.0039 24 23.0039C23.4477 23.0039 23 23.4516 23 24.0039C23 24.5562 23.4477 25.0039 24 25.0039Z"
                        fill="#F1F5F9"
                    />
                    <path
                        d="M24.8 24.0039C24.8 24.4457 24.4418 24.8039 24 24.8039V27.2039C25.7673 27.2039 27.2 25.7712 27.2 24.0039H24.8ZM24 23.2039C24.4418 23.2039 24.8 23.5621 24.8 24.0039H27.2C27.2 22.2366 25.7673 20.8039 24 20.8039V23.2039ZM23.2 24.0039C23.2 23.5621 23.5582 23.2039 24 23.2039V20.8039C22.2327 20.8039 20.8 22.2366 20.8 24.0039H23.2ZM24 24.8039C23.5582 24.8039 23.2 24.4457 23.2 24.0039H20.8C20.8 25.7712 22.2327 27.2039 24 27.2039V24.8039ZM23.8 24.0039C23.8 23.8934 23.8895 23.8039 24 23.8039V26.2039C25.215 26.2039 26.2 25.2189 26.2 24.0039H23.8ZM24 24.2039C23.8895 24.2039 23.8 24.1144 23.8 24.0039H26.2C26.2 22.7889 25.215 21.8039 24 21.8039V24.2039ZM24.2 24.0039C24.2 24.1144 24.1105 24.2039 24 24.2039V21.8039C22.785 21.8039 21.8 22.7889 21.8 24.0039H24.2ZM24 23.8039C24.1105 23.8039 24.2 23.8934 24.2 24.0039H21.8C21.8 25.2189 22.785 26.2039 24 26.2039V23.8039Z"
                        fill="#334155"
                        mask="url(#path-5-inside-2_17126_69420)"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M23 9.00391H9L9 23.0039H23V9.00391ZM9 7.00391C8.79502 7.00391 8.59725 7.03474 8.41106 7.09203C8.75832 7.24881 9 7.59814 9 8.00391C9 8.55619 8.55228 9.00391 8 9.00391C7.59423 9.00391 7.24491 8.76223 7.08812 8.41497C7.03084 8.60115 7 8.79893 7 9.00391V23.0039C7 24.1085 7.89543 25.0039 9 25.0039H23C23.205 25.0039 23.4028 24.9731 23.5889 24.9158C23.2417 24.759 23 24.4097 23 24.0039C23 23.4516 23.4477 23.0039 24 23.0039C24.4058 23.0039 24.7551 23.2456 24.9119 23.5928C24.9692 23.4067 25 23.2089 25 23.0039V9.00391C25 7.89934 24.1046 7.00391 23 7.00391H9Z"
                        fill="#334155"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_17126_69420">
                        <rect
                            width="24"
                            height="24"
                            fill="white"
                            transform="translate(4 4.00391)"
                        />
                    </clipPath>
                </defs>
            </svg>
        </SvgIcon>
    );
};

export default RectangleIcon;
