/** @jsxRuntime classic */

/** @jsx jsx */
import { Fragment, useContext } from "react";

import { jsx } from "@emotion/react";

import ManualGeofenceContext from "../../../../../context/manualGeofence/manualGeofenceContext";

import ManualGeofenceEditor from "./ManualGeofenceEditor";
import ManualGeofenceList from "./ManualGeofenceList";
import { EditorState } from "./index";

interface ManualGeofenceTableProps {
    finalGeozoneId: number;
    maxGeozones: number;
}

const ManualGeofenceTable = ({
    finalGeozoneId,
    maxGeozones,
}: ManualGeofenceTableProps) => {
    const { editState } = useContext(ManualGeofenceContext);
    return (
        <Fragment>
            {editState.currentEditorState === EditorState.Start ? (
                <ManualGeofenceEditor index={editState.currentIndex} />
            ) : (
                <ManualGeofenceList
                    finalGeozoneId={finalGeozoneId}
                    maxGeozones={maxGeozones}
                />
            )}
        </Fragment>
    );
};

export default ManualGeofenceTable;
