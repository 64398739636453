import React from "react";

import { Popover } from "@mui/material";

import useMediaQueries from "../../../../../utils/useMediaQueries";

interface SharedSettingsPopoverProps {
    children: React.ReactNode;
    isOpen: boolean;
    anchorEl: HTMLButtonElement | null;
    handleClose: () => void;
    getMuiPaperStyles: (toMd: boolean, additionalStyles?: any) => {};
}

const SharedSettingsPopover = ({
    children,
    isOpen,
    anchorEl,
    handleClose,
    getMuiPaperStyles,
}: SharedSettingsPopoverProps) => {
    const { toMd } = useMediaQueries();

    return (
        <Popover
            open={isOpen}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorReference={toMd ? "anchorPosition" : "anchorEl"}
            anchorPosition={{ top: 0, left: 0 }}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
            marginThreshold={toMd ? 0 : 16}
            sx={{
                ...getMuiPaperStyles(toMd),
            }}
        >
            {children}
        </Popover>
    );
};

export default SharedSettingsPopover;
