/** @jsxRuntime classic */

/** @jsx jsx */
import { useContext, useEffect } from "react";

import { css, jsx } from "@emotion/react";

import { AlertStatus } from "../../../../../constants/constants";
import AlertContext from "../../../../../context/alert/alertContext";
import LayoutContext from "../../../../../context/layout/layoutContext";
import SettingsContext from "../../../../../context/settings/settingsContext";
import useSharedCSS from "../../../../../utils/useSharedCSS";
import useTct from "../../../../../utils/useTct";
import TextLink from "../../../../MuiComponents/TextLink";
import SharedSettingsPopover from "../SharedSettingsPopover";

import SwitchInput from "./SwitchInput";

const InterfaceSettingsModal = () => {
    const { layoutData } = useContext(LayoutContext);
    const { setAlert } = useContext(AlertContext);
    const {
        anchorEl,
        isInterfaceSettingsModalOpen,
        closeInterfaceSettingsModal,
        openSettingsModal,
        hideParameterIds,
        hideExplanatoryTexts,
        isParameterIdsHidden,
        isExplanatoryTextsHidden,
    } = useContext(SettingsContext);

    const { postSettingsHideOptionsAsync } = useTct();
    const { getMuiPaperStyles, getModalTitleStyles } = useSharedCSS();

    useEffect(() => {
        if (layoutData?.settings.hideOptions) {
            hideParameterIds(layoutData?.settings.hideOptions.hideParameterIds);
            hideExplanatoryTexts(layoutData?.settings.hideOptions.hideTexts);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleHideExplanatoryTexts = async (e: any) => {
        try {
            hideExplanatoryTexts(e.target.checked);
            await postSettingsHideOptionsAsync({
                hideTexts: e.target.checked,
                hideParameterIds: isParameterIdsHidden,
            });
            layoutData!.settings.hideOptions.hideTexts = e.target.checked;
        } catch (error) {
            hideExplanatoryTexts(!e.target.checked);
            setAlert(AlertStatus.Critical, "Unable to hide explanatory text");
        }
    };

    const handleHideParameterIds = async (e: any) => {
        try {
            hideParameterIds(e.target.checked);
            await postSettingsHideOptionsAsync({
                hideTexts: isExplanatoryTextsHidden,
                hideParameterIds: e.target.checked,
            });
            layoutData!.settings.hideOptions.hideParameterIds =
                e.target.checked;
        } catch (error) {
            hideParameterIds(!e.target.checked);
            setAlert(AlertStatus.Critical, "Unable to hide parameter ids");
        }
    };

    const interfaceSettingsItems = [
        {
            label: "Hide explanatory texts",
            value: isExplanatoryTextsHidden,
            onChange: handleHideExplanatoryTexts,
        },
        {
            label: "Hide parameter ids",
            value: isParameterIdsHidden,
            onChange: handleHideParameterIds,
        },
    ];

    return (
        <SharedSettingsPopover
            isOpen={isInterfaceSettingsModalOpen}
            anchorEl={anchorEl}
            handleClose={closeInterfaceSettingsModal}
            getMuiPaperStyles={getMuiPaperStyles}
        >
            <div css={css({ ...getModalTitleStyles() })}>
                <TextLink
                    size="normal"
                    onClick={() => openSettingsModal()}
                    idForTesting="interface-settings-modal-back-button"
                    startIcon
                >
                    Go back
                </TextLink>
            </div>
            <div>
                {interfaceSettingsItems.map((item) => (
                    <div
                        css={css({
                            display: "flex",
                            padding: "14px 34px 14px 26px",
                            justifyContent: "space-between",
                            alignItems: "center",
                        })}
                        key={item.label}
                    >
                        <div
                            css={css({
                                display: "flex",
                                alignItems: "center",
                            })}
                        >
                            {item.label}
                        </div>
                        <SwitchInput
                            onChange={item.onChange}
                            isSwitched={item.value}
                        />
                    </div>
                ))}
            </div>
        </SharedSettingsPopover>
    );
};

export default InterfaceSettingsModal;
