/** @jsxRuntime classic */

/** @jsx jsx */
import { useContext, useState } from "react";

import { css, jsx } from "@emotion/react";

import ThemeContext from "../../../../../../../context/theme/themeContext";
import TimeInput from "../TimeInput";
import { ConvertedCellValue, MobileScheduleTableProps } from "../types";

import useMobileScheduleTable from "./useMobileScheduleTable";

const MobileScheduleTable = ({
    tableRows,
    getRowValues,
    renderRowIds,
}: MobileScheduleTableProps) => {
    const {
        colors: { blue200, blue100, gray100, gray300, white, blue700 },
    } = useContext(ThemeContext);

    const [focusedParameterId, setFocusedParameterId] = useState<null | number>(
        null,
    );
    const [isDragging, setIsDragging] = useState(false);

    const {
        checkIfCellCanBeDisplayed,
        checkIfCellExists,
        checkIfNextCellIsDisabled,
        renderMobileCellTitle,
        renderMobileHeader,
    } = useMobileScheduleTable();

    const renderMobileCell = (
        cell: any,
        colIndex: number,
        previousCell: any,
        rowValues: ConvertedCellValue[],
    ) => {
        if (cell.parameterId === 0) {
            return renderMobileHeader(cell, rowValues, renderRowIds);
        }

        return (
            <div
                css={css({
                    display: "flex",
                    justifyContent: "space-between",
                })}
            >
                <div
                    css={css({
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                    })}
                >
                    {renderMobileCellTitle(cell.columnLocalizationKey)}
                    <TimeInput
                        isMobile
                        data={cell}
                        isFirstCell={colIndex === 1}
                        previousCell={previousCell}
                        rowValues={rowValues}
                        isDragging={isDragging}
                        setIsDragging={setIsDragging}
                        setFocusedParameterId={setFocusedParameterId}
                    />
                </div>
            </div>
        );
    };

    const roundedBottomBorders = {
        borderBottomLeftRadius: "8px",
        borderBottomRightRadius: "8px",
    };

    return (
        <div
            css={css({
                marginBottom: "8px",
                fontWeight: 600,
                borderRadius: "8px",
                border: `1px solid ${gray300}`,
            })}
        >
            <div
                css={css({
                    backgroundColor: blue100,
                    height: "44px",
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                    borderBottom: `1px solid ${blue200}`,
                })}
            />

            <div
                css={css({
                    backgroundColor: gray100,
                    ...roundedBottomBorders,
                })}
            >
                {tableRows.map((tableRow) => {
                    const rowValues = getRowValues(tableRow);

                    const rowKey =
                        rowValues[0]?.parameterId === 0
                            ? rowValues[0]?.label
                            : rowValues[0]?.parameterId;

                    const addBottomMargin = tableRow[0].index !== 6;

                    return (
                        <div
                            key={`row-${rowKey}-mobile-main-table`}
                            css={css({
                                backgroundColor: white,
                                ...(addBottomMargin
                                    ? {
                                          marginBottom: "8px",
                                      }
                                    : {
                                          marginBottom: 0,
                                          ...roundedBottomBorders,
                                      }),
                            })}
                        >
                            {tableRow.map((cell, colIndex) => {
                                const previousCell = checkIfCellExists(
                                    tableRow,
                                    colIndex - 1,
                                );

                                const displayCell = checkIfCellCanBeDisplayed(
                                    colIndex,
                                    cell,
                                    previousCell,
                                );

                                if (displayCell) {
                                    const nextCell = checkIfCellExists(
                                        tableRow,
                                        colIndex + 1,
                                    );

                                    const hideBorderBottom =
                                        tableRow[0].index === 6 &&
                                        checkIfNextCellIsDisabled(
                                            cell,
                                            nextCell,
                                        );

                                    return (
                                        <div
                                            key={`cell-${rowKey}-${cell.parameterId}-mobile-main-table-cell`}
                                            css={css([
                                                {
                                                    padding: "8px 16px",
                                                },

                                                focusedParameterId ===
                                                    cell?.parameterId && {
                                                    outline: `1px solid ${blue700}`,
                                                },

                                                hideBorderBottom
                                                    ? roundedBottomBorders
                                                    : {
                                                          borderBottom: `1px solid ${gray300}`,
                                                      },
                                            ])}
                                        >
                                            {renderMobileCell(
                                                cell,
                                                colIndex,
                                                previousCell,
                                                rowValues,
                                            )}
                                        </div>
                                    );
                                }
                            })}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default MobileScheduleTable;
