import React from "react";

import IconLeftButton from "../IconLeftButton";
import IconOnlyButton from "../IconOnlyButton";
import IconRightButton from "../IconRightButton";
import TextOnlyButton from "../TextOnlyButton";
import TwoIconsButton from "../TwoIconsButton";
import { ButtonVariants } from "../types";

export const useButtonVariants = (
    variant: ButtonVariants,
    icon: any,
    children: any,
) => {
    const renderButtonVariant = () => {
        switch (variant) {
            case "iconLeft":
                return <IconLeftButton icon={icon}>{children}</IconLeftButton>;
            case "iconRight":
                return (
                    <IconRightButton icon={icon}>{children}</IconRightButton>
                );
            case "twoIcons":
                return <TwoIconsButton icon={icon}>{children}</TwoIconsButton>;
            case "iconOnly":
                return <IconOnlyButton icon={icon} />;
            default:
                return <TextOnlyButton>{children}</TextOnlyButton>;
        }
    };

    return { renderButtonVariant };
};
