import { useContext, useEffect } from "react";

import InputOutputContext from "../../../../../../../context/inputOutput/inputOutputContext";
import { getCanFilters } from "../columns/canColumns";
import { getIoFilters } from "../columns/ioTableColumns";

interface UseIoTableEffectsProps {
    data: any[];
    setHiddenColumns: (columns: any[]) => void;
    isCanTable: boolean;
    setAllFilters: (filters: any[]) => void;
    setGlobalFilter: (value: string) => void;
}

export const useIoTableEffects = ({
    data,
    setHiddenColumns,
    isCanTable,
    setAllFilters,
    setGlobalFilter,
}: UseIoTableEffectsProps): void => {
    const {
        activeFilters,
        activePriority,
        activeOperand,
        activeStatus,
        searchValue,
    } = useContext(InputOutputContext);

    useEffect(() => {
        const filters = isCanTable
            ? getCanFilters(activeOperand, activeStatus)
            : getIoFilters(
                  activeFilters,
                  activePriority,
                  activeOperand,
                  activeStatus,
              );
        setAllFilters(filters);
    }, [activeFilters, activePriority, activeOperand, activeStatus]);

    // Search management effect
    useEffect(() => {
        setGlobalFilter(searchValue);
    }, [searchValue]);
};
