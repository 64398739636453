/** @jsxRuntime classic */

/** @jsx jsx */
import { useContext, useEffect, useRef, useState } from "react";

import { css, jsx } from "@emotion/react";

import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import { Popover } from "@mui/material";

import LanguageContext from "../../../../../../../context/language/languageContext";
import LayoutContext from "../../../../../../../context/layout/layoutContext";
import MenuItemContext from "../../../../../../../context/menuItem/menuItemContext";
import ThemeContext from "../../../../../../../context/theme/themeContext";
import { Component, SearchPath } from "../../../../../../../generatedTypes";
import { noop } from "../../../../../../../utils/helpers";
import useMediaQueries from "../../../../../../../utils/useMediaQueries";
import useNavigation from "../../../../../../../utils/useNavigation";
import useSharedCSS from "../../../../../../../utils/useSharedCSS";
import Tooltip from "../../../../../../MuiComponents/Tooltip";
import CollectionMenuItem from "../../../../../../MuiComponents/reusableInputs/NewCollection/CollectionMenuItem";

import { TableSwitchDefaultValue } from "./TableSwitchInput";

export interface TableCollectionProps {
    data: Component;
    switchId?: string | number;
    hasNoInputFocus?: boolean;
    anchorPositionHorizontal?: "left" | "center" | "right";
    slotProps?: any;
    hasNoInputDisabledBackground?: boolean;
    onOptionClick?: (value: any) => void;
    onFocus?: (isOpen: boolean) => void;
}

const TableCollection = ({
    data,
    switchId,
    hasNoInputFocus,
    anchorPositionHorizontal,
    slotProps,
    hasNoInputDisabledBackground,
    onOptionClick,
    onFocus,
}: TableCollectionProps) => {
    const {
        label,
        parameterId,
        parameterValue,
        collectionConfig,
        sourceBindToList,
        disabledTooltip,
        isDisabled,
    } = data;

    const { findBindedList, listValues } = useContext(LayoutContext);

    const {
        parameters,
        updateParameter,
        findParameterValueById,
        findDisabledCollectionItemsById,
        findDisabledParameterById,
    } = useContext(MenuItemContext);

    const {
        colors: { gray100, textDarkDisabled },
    } = useContext(ThemeContext);

    const { t } = useContext(LanguageContext);

    const { handleSearchPath } = useNavigation();
    const { fromMd } = useMediaQueries();
    const { renderBorderColor } = useSharedCSS();

    const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLDivElement | null>(
        null,
    );
    const [isClosing, setIsClosing] = useState(false);
    const [value, setValue] = useState<string>(parameterValue);
    const [previousValue, setPreviousValue] = useState(parameterValue);
    const [requestError, setRequestError] = useState(false);
    const [listItems, setListItems] = useState(
        listValues[sourceBindToList ?? ""],
    );

    const closeTimeoutRef = useRef<NodeJS.Timeout>();
    const elementRef = useRef(null);

    const paramValFromContext = parameters[parameterId];
    const isOpen = Boolean(menuAnchorEl) && !isClosing;

    // Cleanup timeouts on unmount
    useEffect(() => {
        return () => {
            if (closeTimeoutRef.current) {
                clearTimeout(closeTimeoutRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if (listValues[sourceBindToList || ""]) {
            setListItems([
                { value: t.NotSet, index: 0 },
                ...listValues[sourceBindToList || ""],
            ]);
        }
    }, [listValues, sourceBindToList]);

    useEffect(() => {
        setValue(paramValFromContext);
        setPreviousValue(paramValFromContext);
    }, [paramValFromContext]);

    const handleClose = () => {
        if (isClosing) return;

        setIsClosing(true);
        closeTimeoutRef.current = setTimeout(() => {
            setMenuAnchorEl(null);
            setIsClosing(false);
            onFocus?.(false);
        }, 100);
    };

    const toggling = (e: React.MouseEvent<HTMLDivElement>) => {
        if (isClosing) {
            e.preventDefault();
            return;
        }

        if (closeTimeoutRef.current) {
            clearTimeout(closeTimeoutRef.current);
        }

        if (menuAnchorEl) {
            handleClose();
        } else {
            setMenuAnchorEl(e.currentTarget);
            onFocus?.(true);
        }
    };

    const onSuccessfulRequest = (val: string) => {
        requestError && setRequestError(false);
        setPreviousValue(val);
    };

    const onFailedRequest = () => {
        setRequestError(true);
        setValue(previousValue);
    };

    const onCanceledRequest = () => {
        setValue(previousValue);
    };

    const onOptionClicked = (value: any) => {
        onOptionClick?.(value);
        setValue(value);
        updateParameter(
            parameterId,
            value,
            label ?? "",
            elementRef,
            () => onSuccessfulRequest(value),
            onFailedRequest,
            noop,
            true,
            onCanceledRequest,
        );
        handleClose();
    };

    const isInputDisabled =
        findParameterValueById(switchId) === TableSwitchDefaultValue.OFF;

    const isCollectionDisabled = (): boolean => {
        if (findDisabledParameterById(parameterId) !== undefined) {
            return findDisabledParameterById(parameterId);
        }
        if (
            findBindedList(sourceBindToList) &&
            !listValues[sourceBindToList || ""]?.length
        ) {
            return true;
        }
        return isInputDisabled || isDisabled;
    };

    const renderSelectValue = () => {
        if (listItems && listItems.length) {
            return (
                listItems?.find((item) => item.index === Number(value))
                    ?.value || ""
            );
        }
        return (
            collectionConfig?.collectionItems.find(
                (item) => item.value === value,
            )?.text || ""
        );
    };

    return (
        <Tooltip
            title={
                isCollectionDisabled() && disabledTooltip
                    ? disabledTooltip.title
                    : ""
            }
            small
            placement="top"
        >
            <span
                css={css({ display: "block", width: "100%", height: "100%" })}
            >
                <div
                    css={css({
                        pointerEvents: isCollectionDisabled() ? "none" : "auto",
                        backgroundColor:
                            isCollectionDisabled() &&
                            !hasNoInputDisabledBackground
                                ? gray100
                                : "unset",
                        color: isCollectionDisabled()
                            ? textDarkDisabled
                            : "unset",
                        width: "100%",
                        cursor: "pointer",
                        height: "100%",
                        fontWeight: 600,
                        fontSize: "14px",
                        display: "flex",
                    })}
                >
                    <div
                        css={css({
                            padding: fromMd ? "14px 16px" : "0px",
                            minWidth: "28px",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            boxSizing: "border-box",
                            border: renderBorderColor(
                                hasNoInputFocus,
                                requestError,
                                isOpen,
                            ),
                        })}
                        onClick={toggling}
                        data-test={String(parameterId)}
                        ref={elementRef}
                    >
                        {renderSelectValue()}
                        <ExpandMoreIcon
                            css={css({
                                transform: isOpen ? "rotate(-180deg)" : "unset",
                                transition: "transform 0.2s ease",
                            })}
                        />
                    </div>

                    <Popover
                        open={isOpen}
                        anchorEl={menuAnchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: anchorPositionHorizontal || "center",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                        }}
                        slotProps={slotProps}
                        sx={{
                            "& .MuiPaper-root": {
                                maxHeight: 200,
                            },
                        }}
                    >
                        {listItems?.map((item) => {
                            if (item.value) {
                                return (
                                    <CollectionMenuItem
                                        key={item.index}
                                        value={item.index}
                                        disabled={false}
                                        handleOnClick={onOptionClicked}
                                    >
                                        {item.value}
                                    </CollectionMenuItem>
                                );
                            }
                            return null;
                        })}
                        {collectionConfig?.collectionItems.map((item) => {
                            const hasDisabledItems =
                                findDisabledCollectionItemsById(parameterId);
                            const isMenuItemDisabled =
                                hasDisabledItems &&
                                hasDisabledItems[item.value];
                            const itemDisabledTooltip = item.disabledTooltip;

                            if (item.isInvisible) {
                                return null;
                            }

                            return (
                                <CollectionMenuItem
                                    key={item.value}
                                    value={item.value}
                                    disabled={isMenuItemDisabled}
                                    tooltipTitle={
                                        isMenuItemDisabled &&
                                        itemDisabledTooltip
                                            ? itemDisabledTooltip.title
                                            : ""
                                    }
                                    tooltipButtonText={
                                        itemDisabledTooltip
                                            ? itemDisabledTooltip.btnTitle
                                            : ""
                                    }
                                    tooltipOnBtnClick={() =>
                                        handleSearchPath(
                                            itemDisabledTooltip as {
                                                searchPath: SearchPath;
                                            },
                                        )
                                    }
                                    handleOnClick={onOptionClicked}
                                    css={css({
                                        minWidth: "220px",
                                    })}
                                >
                                    {item.text}
                                </CollectionMenuItem>
                            );
                        })}
                    </Popover>
                </div>
            </span>
        </Tooltip>
    );
};

export default TableCollection;
