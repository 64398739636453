/** @jsxRuntime classic */

/** @jsx jsx */
import { Fragment, useContext, useState } from "react";

import { css, jsx } from "@emotion/react";

import { InputLabel } from "@mui/material";

import {
    eventStatus,
    geozonesPriorityColumns,
    shapeColumns,
} from "../../../../../../constants/constants";
import ManualGeofenceContext from "../../../../../../context/manualGeofence/manualGeofenceContext";
import MoreFilters from "../../../shared/MoreFilters";
import MoreFiltersPopover from "../../../shared/MoreFiltersPopover";

import FilterSelect from "./FilterSelect";

const MoreFiltersSelect = () => {
    const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);

    const {
        activeShape,
        activeOperand,
        activePriority,
        setActiveShape,
        setActiveOperand,
        setActivePriority,
    } = useContext(ManualGeofenceContext);

    return (
        <Fragment>
            <InputLabel
                css={css({
                    position: "unset",
                    display: "inline-flex",
                })}
                onClick={(e) => setMenuAnchorEl(e.currentTarget as HTMLElement)}
            >
                <MoreFilters />
            </InputLabel>

            <MoreFiltersPopover
                menuAnchorEl={menuAnchorEl}
                setMenuAnchorEl={setMenuAnchorEl}
            >
                <FilterSelect
                    columns={shapeColumns}
                    onChange={(e) => setActiveShape(e.target.value)}
                    value={activeShape}
                    name="Shape"
                />

                <FilterSelect
                    columns={geozonesPriorityColumns}
                    onChange={(e) => setActivePriority(e.target.value)}
                    value={activePriority}
                    name="Priority"
                />

                <FilterSelect
                    columns={eventStatus}
                    onChange={(e) => setActiveOperand(e.target.value)}
                    value={activeOperand}
                    name="Operand"
                />
            </MoreFiltersPopover>
        </Fragment>
    );
};

export default MoreFiltersSelect;
