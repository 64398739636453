/** @jsxRuntime classic */

/** @jsx jsx */
import { Fragment, useContext, useRef, useState } from "react";

import { css, jsx } from "@emotion/react";

import { ReactComponent as ManageScheduleIcon } from "../../../../../assets/icons/ManageScheduleIcon.svg";
import MenuItemContext from "../../../../../context/menuItem/menuItemContext";
import { Component, Modal } from "../../../../../generatedTypes";
import { noop } from "../../../../../utils/helpers";
import useMediaQueries from "../../../../../utils/useMediaQueries";
import Button from "../../../../MuiComponents/Button";
import Card from "../../../../MuiComponents/Card";
import FormModal from "../../../../MuiComponents/Modals/FormModal";
import Collection from "../Component/Collection";

import ScheduleTable from "./ScheduleTable";
import ReadOnlyTable from "./ScheduleTable/ReadOnlyTable";
import useScheduleTableData from "./useScheduleTableData";

export interface RecordsScheduleProps {
    data: Modal;
}

const RecordsSchedule = ({ data }: RecordsScheduleProps) => {
    const { updateParameter } = useContext(MenuItemContext);

    const {
        columns,
        tableRows,
        getRowValues,
        renderReadOnlyTableCell,
        renderRowIds,
        renderNotAvailableCell,
    } = useScheduleTableData(data.table);

    const { toSm, toMd } = useMediaQueries();

    const [isModalOpen, setIsModalOpen] = useState(false);

    const timeZoneRef = useRef(null);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleTimeZoneChange = (e: { id: number; value: string }) => {
        const { id, value } = e;

        updateParameter(
            id,
            String(value),
            "",
            timeZoneRef,
            () => {},
            () => {},
            () => noop,
            true,
            () => {},
        );
    };

    const dataExists = columns && tableRows;

    const tableName = data?.table?.name;

    return (
        <Fragment>
            <Card
                title={tableName}
                isSection
                divider
                boxCSS={css({
                    maxWidth: "518px",

                    "& h4": {
                        lineHeight: "20px",
                        letterSpacing: "0.1px",
                    },
                })}
            >
                {dataExists && (
                    <ReadOnlyTable
                        tableRows={tableRows}
                        toSm={toSm}
                        getRowValues={getRowValues}
                        renderReadOnlyTableCell={renderReadOnlyTableCell}
                        renderNotAvailableCell={renderNotAvailableCell}
                    />
                )}

                {data.modalName && (
                    <Button
                        fullWidth
                        variant="iconLeft"
                        icon={<ManageScheduleIcon />}
                        size={toSm ? "small" : "normal"}
                        color="primarySubtle"
                        onClick={handleOpenModal}
                        idForTesting={data.modalName}
                        css={css({
                            display: "flex",
                            marginTop: "16px",
                        })}
                    >
                        {data.modalName}
                    </Button>
                )}
            </Card>

            <FormModal
                hasActionsDivider
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                title={data.modalName ?? ""}
                description={data.description}
                secondaryButtonText={data.cancelCaption}
                customModalStyles={{
                    maxWidth: "920px",
                    overflowY: "hidden",
                }}
            >
                {data.components.map((component: Component) => (
                    <div
                        key={component.index}
                        css={css({
                            marginBottom: "16px",
                            maxWidth: toMd ? "unset" : "438px",
                        })}
                    >
                        <Collection
                            data={component}
                            onChange={handleTimeZoneChange}
                        />
                    </div>
                ))}

                {dataExists && (
                    <ScheduleTable
                        columns={columns}
                        tableRows={tableRows}
                        toMd={toMd}
                        getRowValues={getRowValues}
                        renderRowIds={renderRowIds}
                    />
                )}
            </FormModal>
        </Fragment>
    );
};

export default RecordsSchedule;
