import React, { useContext } from "react";

import LanguageContext from "../../../../../context/language/languageContext";
import { Modal, Trigger } from "../../../../../generatedTypes";
import FormModal from "../../../../MuiComponents/Modals/FormModal";

interface SharedFormModalProps {
    data: Trigger | Modal | undefined;
    sourceId: number | "";
    values: any;
    submitLoading: boolean;
    hasErrors: boolean;
    isEdit: boolean | undefined;
    isOpen: boolean;
    handleSubmit: () => Promise<void>;
    handleClose: (() => void) | undefined;
    renderContent: () => React.JSX.Element;
}

const SharedFormModal = ({
    data,
    sourceId,
    values,
    submitLoading,
    hasErrors,
    isEdit,
    isOpen,
    handleSubmit,
    handleClose,
    renderContent,
}: SharedFormModalProps) => {
    const { t } = useContext(LanguageContext);

    const isPrimaryButtonDisabled = () => {
        if (
            (values[sourceId] === "0" || values[sourceId] === undefined) &&
            !isEdit
        ) {
            return true;
        }
        if (hasErrors || submitLoading) {
            return true;
        }
        return false;
    };

    const renderDisabledTooltip = () => {
        if (hasErrors) {
            return { title: t.HasErrors };
        }
        if (
            (values[sourceId] === "0" || values[sourceId] === undefined) &&
            !isEdit
        ) {
            return { title: t.SourceIsRequired };
        }
        if (submitLoading) {
            return { title: t.Submitting };
        }
        return undefined;
    };

    return (
        <FormModal
            isOpen={isOpen}
            title={`${isEdit ? t.Edit : t.Add} ${data?.modalName}`}
            description={data?.description}
            onSubmit={handleSubmit}
            onClose={handleClose}
            primaryButtonText={t.Save}
            secondaryButtonText={t.Close}
            isPrimaryButtonDisabled={isPrimaryButtonDisabled()}
            isSubmitting={submitLoading}
            disabledTooltip={renderDisabledTooltip()}
        >
            {renderContent()}
        </FormModal>
    );
};

export default SharedFormModal;
