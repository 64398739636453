interface ComponentVisibility {
    parameterId: number;
    isDisabled: boolean;
    collection: Record<string, any>;
    uiName: string;
    isHidden: boolean;
}

interface VisibilityPayloadItem {
    parameterId: number;
    uiName?: string;
    isDisabled: boolean;
    collection: Record<string, any>;
    isHidden: boolean;
}

export const matchesVisibilityItem = (
    stateItem: ComponentVisibility,
    payloadItem: { parameterId: number; uiName?: string },
): boolean => {
    if (payloadItem.uiName === stateItem.uiName) {
        return true;
    }
    if (
        payloadItem.parameterId === stateItem.parameterId &&
        !payloadItem.uiName
    ) {
        return true;
    }
    return false;
};

export const separateVisibilities = (
    currentVisibilities: ComponentVisibility[],
    payloadItems: VisibilityPayloadItem[],
): {
    previousDisabledParams: ComponentVisibility[];
    otherDisabledParams: ComponentVisibility[];
} => {
    const previousDisabledParams: ComponentVisibility[] = [];
    const otherDisabledParams: ComponentVisibility[] = [];

    currentVisibilities?.forEach((stateItem) => {
        if (
            payloadItems.some((payloadItem) =>
                matchesVisibilityItem(stateItem, payloadItem),
            )
        ) {
            previousDisabledParams.push(stateItem);
        } else {
            otherDisabledParams.push(stateItem);
        }
    });

    return { previousDisabledParams, otherDisabledParams };
};

export const mapVisibilityPayload = (
    payloadItems: VisibilityPayloadItem[],
    previousDisabledParams: ComponentVisibility[],
): ComponentVisibility[] => {
    return payloadItems.map((item) => {
        const previousParam = previousDisabledParams.find(
            (previousItem) => previousItem.parameterId === item.parameterId,
        );

        const collection = previousParam
            ? { ...previousParam.collection, ...item.collection }
            : item.collection;

        return {
            parameterId: item.parameterId,
            isDisabled: item.isDisabled,
            collection,
            uiName: item.uiName ?? "",
            isHidden: item.isHidden,
        };
    });
};
