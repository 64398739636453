import { GeozoneOptions } from "../components/Editor/MenuItemView/Segment/ManualGeofence/ManualGeofenceMap/EditableLayer";
import { GeozoneType } from "../utils/types";

export enum DriveTypes {
    FWD = "FWD",
    RWD = "RWD",
    AWD = "AWD",
}

export enum TransmissionTypes {
    Manual = "Manual",
    Automatic = "Automatic",
    DualClutch = "Dual clutch",
}

export enum DrivingModes {
    Sensitive = "Sensitive",
    Normal = "Normal",
    Insensitive = "Insensitive",
}

export const valueByDriveType = {
    [DriveTypes.FWD]: 1.0,
    [DriveTypes.AWD]: 0.85,
    [DriveTypes.RWD]: 0.9,
};
export const valueByTransmissionType = {
    [TransmissionTypes.Manual]: 1.0,
    [TransmissionTypes.Automatic]: 1.1,
    [TransmissionTypes.DualClutch]: 0.925,
};
export const valueByDrivingMode = {
    ///[Harsh acceleration,Harsh braking,Harsh cornering]
    [DrivingModes.Sensitive]: [0.9, 1.0, 1.2],
    [DrivingModes.Normal]: [1.1, 1.2, 1.4],
    [DrivingModes.Insensitive]: [1.3, 1.4, 1.8],
};

export const enum EcoCalculatorConstants {
    massByKg = 2.2046,
    powerByKw = 1.341,
    kilometersPerHour = 0.9,
    exponentiationValue = 0.75,
}

export const enum SimSecurityStatuses {
    SimReady = "SimReady",
    NeedPin = "NeedPin",
    NeedPuk = "NeedPuk",
    NeedPin2 = "NeedPin2",
    NeedPuk2 = "NeedPuk2",
    Unknown = "Unknown",
    NotPresent = "NotPresent",
}

export interface IoColumns<T> {
    name: T;
    currentValue: T;
    priority: T;
    operand: T;
    lowLevel: T;
    highLevel: T;
    eventOnly: T;
    avgConst: T;
    sendSmsTo: T;
    smsText: T;
    canType: T;
    canId: T;
    dataMask: T;
    canSrc: T;
}

export interface IoColumnStrings extends IoColumns<string> {}

export const IoTableColumns: IoColumnStrings = {
    name: "name",
    currentValue: "currentValue",
    priority: "priority",
    operand: "operand",
    lowLevel: "lowLevel",
    highLevel: "highLevel",
    eventOnly: "eventOnly",
    avgConst: "avgConst",
    sendSmsTo: "sendSmsTo",
    smsText: "smsText",
    canType: "canType",
    canId: "canId",
    dataMask: "dataMask",
    canSrc: "canSrc",
};

export const IoTableColumnTitles: IoColumnStrings = {
    name: "Input name",
    currentValue: "Current value",
    priority: "Priority",
    operand: "Operand",
    lowLevel: "Low level",
    highLevel: "High level",
    eventOnly: "Event Only",
    avgConst: "Avg. const.",
    sendSmsTo: "Send Sms to",
    smsText: "Sms text",
    canType: "Can type",
    canId: "Can ID",
    dataMask: "Data mask",
    canSrc: "Can source",
};

export const BluetoothTableColumns = {
    deviceName: "deviceName",
    deviceType: "deviceType",
    status: "status",
    macAddress: "macAddress",
    signalStrength: "signalStrength",
};

export const BluetoothTableColumnsTitles = {
    deviceName: "Device name",
    deviceType: "Device type",
    status: "Status",
    macAddress: "MAC address",
};

export const FotaKeyTableColumns = {
    type: "type",
    status: "status",
    statusMessage: "statusMessage",
    name: "name",
    created: "created",
    typeLabel: "typeLabel",
    expiration: "expiration",
};

export const FotaKeyTableColumnsTitles = {
    type: "Key type",
    typeLabel: "Key type",
    status: "Status",
    name: "Key name",
    created: "Date added",
};

export const SmsEventTableColumns = {
    smsEvent: "smsEvent",
    smsEventNumber: "smsEventNumber",
    smsEventText: "smsEventText",
};

export const SmsEventTableTitle = {
    smsEvent: "SMS event",
    smsEventNumber: "SMS event number",
    smsEventText: "SMS event text",
};

export interface NameValueStringsPair {
    name: string;
    value: string;
}

export interface TextValueStringsPair {
    text: string;
    value: string;
}

export const priorityColumns: NameValueStringsPair[] = [
    { name: "Low", value: "1" },
    { name: "High", value: "2" },
    { name: "Panic", value: "3" },
];

export const operandColumns = (isFtc: boolean): NameValueStringsPair[] => {
    if (isFtc) {
        return [
            { name: "Monitoring", value: "0" },
            { name: "On Change", value: "1" },
            { name: "On Exit", value: "2" },
            { name: "On Entrance", value: "3" },
            { name: "On Both", value: "4" },
            { name: "On Hysteresis", value: "5" },
            { name: "On Delta Change", value: "6" },
        ];
    }
    return [
        { name: "On Exit", value: "0" },
        { name: "On Entrance", value: "1" },
        { name: "On Both", value: "2" },
        { name: "Monitoring", value: "3" },
        { name: "On Hysteresis", value: "4" },
        { name: "On Change", value: "5" },
    ];
};

export const statusItems: TextValueStringsPair[] = [
    { text: "Both", value: "2" },
    { text: "On", value: "1" },
    { text: "Off", value: "0" },
];

export const shapeColumns: NameValueStringsPair[] = [
    { name: "Circle", value: "Circle" },
    { name: "Rectangle", value: "Rectangle" },
    { name: "Polygon", value: "Polygon" },
];

export const eventStatus: NameValueStringsPair[] = [
    { name: "No Event", value: "NoEvent" },
    { name: "On Exit", value: "OnExit" },
    { name: "On Entrance", value: "OnEntrance" },
    { name: "On Both", value: "OnBoth" },
];

export const geozonesPriorityColumns: NameValueStringsPair[] = [
    { name: "Low", value: "Low" },
    { name: "High", value: "High" },
    { name: "Panic", value: "Panic" },
];

export const defaultListLanguageFlag = "gb";

export const deviceTypesForSelect = [
    { value: "Sensor", label: "Sensor" },
    { value: "Beacon", label: "Beacon" },
    { value: "UniversalBLEDevice", label: "Universal BLE Device" },
    { value: "OBD2dongle", label: "OBD2 dongle" },
    { value: "Headset", label: "Headset" },
];

export enum BluetoothTableStatuses {
    Connected = "Connected",
    Disconnected = "Disconnected",
    Configured = "Configured",
    AllStatuses = "All Statuses",
    Disabled = "Disabled",
}

export enum BluetoothFlowModals {
    AddDevice = "AddDevice",
    EditDevice = "EditDevice",
    EditDeviceWithTable = "EditDeviceWithTable",
}

export enum PathType {
    ConfiguratorLog = "ConfiguratorLog",
    DeviceLog = "DeviceLog",
    Accelerometer = "Accelerometer",
    Presets = "Presets",
    Records = "Records",
}

export enum Templates {
    Blank = "Blank template",
}

export enum CollectionsWithRefetchBluetoothTableName {
    BeaconDetection = "Beacon Detection",
    EyeBeaconConfiguration = "EYE Beacon configuration",
}

export enum BluetoothSensorStatuses {
    Online = "Online",
    Offline = "Offline",
}

export enum UserKeyStatuses {
    Unknown = "Unknown",
    Active = "Active",
    Expired = "Expired",
    NotVerified = "NotVerified",
}

export enum UserKeyTypes {
    FotaApiKey = "FotaApiKey",
    SpecFWKey = "SpecFWKey",
}

export const geozoneOptions: GeozoneOptions = {
    AllowedShapes: [
        GeozoneType.Circle,
        GeozoneType.Rectangle,
        // GeozoneType.Polygon, //TO DO when polygon will be ready
    ],
    MaxPolygonVerticesCount: 5,
};

export const defaultCoordinates: [number, number] = [0, 0];

export const geozoneTypes: any = {
    ["Circle"]: 1,
    ["Rectangle"]: 2,
    ["Polygon"]: 3,
};

export const defaultRadius = 5;

export const polygonMaxVerticles = 10;

export enum LoadStatus {
    Default = "Default",
    FromFile = "FromFile",
    FromDevice = "FromDevice",
}

export enum ConnectionTypes {
    Serial = 0,
    Bluetooth = 1,
}

export enum DeviceGuideType {
    Necessary = "Necessary",
    Optional = "Optional",
}

export enum PasswordTypes {
    Primary = 1,
    Recovery = 2,
}

export enum PortMessageType {
    Message = "Message",
    Command = "Command",
}

export const terminalMessageLength = 200;

export const FROM_FILE_LOAD_STATUS: string = "FromFile";

export enum Loaders {
    DtbUpdate = "dtbUpdate",
    ModemUpdate = "modemUpdate",
    DeviceFirmwareUpdate = "deviceFirmwareUpdate",
}

export enum TableTypes {
    UserKeys = "userkeys",
    Bluetooth = "bluetooth",
    BluetoothScan = "bluetoothScan",
}

export enum HttpStatusCodes {
    BadRequest = 400,
    Conflict = 409,
    UnprocessableEntity = 422,
}

export enum FirmwareType {
    Dtb = "dtb",
    Modem = "modem",
    Firmware = "firmware",
    None = "",
}

export enum FirmwareUpdateStages {
    DeviceReboot = "DeviceReboot",
    ModemReboot = "ModemReboot",
}

export enum PortStates {
    Removed = "Removed",
    Arrived = "Arrived",
}

export const JwtTokenKey = "jwt";
export enum DeviceConfigurableStatus {
    Unsupported = "unsupported",
    Supported = "supported",
}

export enum AlertStatus {
    Success = "success",
    Critical = "critical",
    Warning = "warning",
    Info = "info",
}

export const numberOfSpecsInList = 5;
export const minCountForShowMoreNotifications = 10;

export enum UpdateStages {
    None = "None",
    FileUpload = "FileUpload",
    DeviceReboot = "DeviceReboot",
    ModemReboot = "ModemReboot",
}

export enum UpdateStatusType {
    InProgress = "InProgress",
    Success = "Success",
    Failed = "Failed",
}

export enum DATE_FORMATS {
    ISO_8601 = "YYYY-MM-DDTHH:mm:ssZ",
    LOG_FILE = "YYYY-MM-DD_HH.mm.ss",
}

export enum TIME_FORMATS {
    HOURS_MINUTES = "HH:mm",
}

export const AppBuildDateKey = "APP-BUILD-DATE";
export const ConfigCacheKeySuffix = "CONFIG-VERSION";

export enum LogFilterCommands {
    Default = "DEFAULT",
    Save = "SAVE",
}

export enum TriggerSwithValues {
    On = "1",
    Off = "0",
}

export enum NotificationSwitchValues {
    On = "1",
    Off = "0",
}

export const MAC_ADDRESS_REGEX = "^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$";

export const TOTAL_BLUETOOTH_DEVICE_COUNT = 10;

export const NOT_AVAILABLE_VALUE = "N/A";
