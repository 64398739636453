/** @jsxRuntime classic */

/** @jsx jsx */
import { useContext, useState } from "react";

import { css, jsx } from "@emotion/react";

import parse from "html-react-parser";

import {
    IoTableColumnTitles,
    IoTableColumns,
    NOT_AVAILABLE_VALUE,
} from "../../../../../../../constants/constants";
import LayoutContext from "../../../../../../../context/layout/layoutContext";
import MenuItemContext from "../../../../../../../context/menuItem/menuItemContext";
import ThemeContext from "../../../../../../../context/theme/themeContext";
import { Component } from "../../../../../../../generatedTypes";
import Tooltip from "../../../../../../MuiComponents/Tooltip";
import { useMobileTableCards } from "../hooks/useMobileTableCards";
import { MobileTableCardProps } from "../types";

import TableCollection from "./TableCollection";
import TableInput from "./TableInput";
import TableSwitchInput, { TableSwitchDefaultValue } from "./TableSwitchInput";

const MobileTableCard = ({
    row,
    currentValue,
    allColumns,
}: MobileTableCardProps) => {
    const {
        colors: {
            blue100,
            gray100,
            white,
            textDarkSecondary,
            textDarkDisabled,
        },
        boxShadows: { blueBoxShadow, ioTableCardBoxShadow },
    } = useContext(ThemeContext);

    const { findParameterValueById } = useContext(MenuItemContext);

    const { layoutData } = useContext(LayoutContext);

    const [focusedRow, setFocusedRow] = useState("");
    const [rowsWithErrors, setRowsWithErrors] = useState<string[]>([]);

    const {
        renderCellBorder,
        renderCellTitle,
        returnOperandRow,
        returnPriorityRow,
    } = useMobileTableCards(row, focusedRow, rowsWithErrors);

    const handleFocus = (isOpen: boolean, colName: string) => {
        setFocusedRow(isOpen ? colName : "");
    };

    const handleError = (error: string, colName: string) => {
        setRowsWithErrors((prev) => {
            if (error) {
                if (!prev.includes(colName)) {
                    return [...prev, colName];
                }
                return prev;
            } else {
                return prev.filter((rowName) => rowName !== colName);
            }
        });
    };

    const isInputDisabled =
        findParameterValueById(row.parameterValue.id) ===
        TableSwitchDefaultValue.OFF;

    const hasCurrentValue = layoutData?.isOnline;

    const isVisibleRow = (colName: string) => {
        if (allColumns) {
            return allColumns.some(
                (column) => column.id === colName && column.isVisible,
            );
        }
        return false;
    };

    return (
        <div
            css={css({
                marginBottom: "8px",
                filter: "drop-shadow(0px 1px 4px rgba(15, 23, 42, 0.12))",
                fontWeight: 600,
                color: isInputDisabled ? textDarkDisabled : textDarkSecondary,
                marginLeft: "1px",
                marginRight: "1px",
            })}
        >
            <div
                css={css({
                    backgroundColor: blue100,
                    padding: "12px 16px",
                    boxShadow: blueBoxShadow,
                })}
            >
                {row.name}
            </div>
            <div
                css={css({
                    display: "flex",
                    alignItems: "center",
                    padding: "8px 16px",
                    justifyContent: "space-between",
                    backgroundColor: white,
                    boxShadow: ioTableCardBoxShadow,
                })}
            >
                <div css={css({ maxWidth: "calc(100% - 60px)" })}>
                    <div
                        css={css({
                            marginBottom: "6px",
                            fontWeight: 600,
                            fontSize: "14px",
                            lineHeight: "20px",
                            letterSpacing: "0.1px",
                        })}
                    >
                        {row.name}
                    </div>
                    <Tooltip
                        title={row?.description || ""}
                        placement="bottom"
                        small
                    >
                        <div
                            css={css({
                                fontWeight: 600,
                                fontSize: "12px",
                                lineHeight: "16px",
                                letterSpacing: "0.4px",
                                color: textDarkSecondary,
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                height: "16px",
                            })}
                        >
                            {parse(row?.description || "")}
                        </div>
                    </Tooltip>
                </div>
                <div>
                    <TableSwitchInput
                        id={row.parameterValue.id}
                        label={String(row.parameterValue.id)}
                    />
                </div>
            </div>
            {hasCurrentValue && (
                <div
                    css={css({
                        padding: "12px 16px",
                        backgroundColor: gray100,
                        boxShadow: ioTableCardBoxShadow,
                    })}
                >
                    {renderCellTitle(IoTableColumnTitles.currentValue)}
                    <div css={css({ fontSize: "14px" })}>
                        {currentValue || NOT_AVAILABLE_VALUE}
                    </div>
                </div>
            )}

            {isVisibleRow(IoTableColumns.priority) &&
                returnPriorityRow(handleFocus)}

            {isVisibleRow(IoTableColumns.operand) &&
                returnOperandRow(handleFocus)}

            {isVisibleRow(IoTableColumns.lowLevel) && (
                <div
                    css={css({
                        padding: "12px 16px",
                        backgroundColor: white,
                        boxShadow: ioTableCardBoxShadow,
                        border: renderCellBorder(IoTableColumns.lowLevel),
                    })}
                >
                    {renderCellTitle(IoTableColumnTitles.lowLevel)}
                    <div css={css({ fontSize: "14px" })}>
                        {row.lowLevel ? (
                            <TableInput
                                data={row.lowLevel}
                                switchId={row.parameterValue.id}
                                onFocus={(isFocused) =>
                                    handleFocus(
                                        isFocused,
                                        IoTableColumns.lowLevel,
                                    )
                                }
                                onError={(error) =>
                                    handleError(error, IoTableColumns.lowLevel)
                                }
                                hasNoInputErrorBorder
                                hasNoInputFocus
                                hasNoInputDisabledBackground
                            />
                        ) : null}
                    </div>
                </div>
            )}

            {isVisibleRow(IoTableColumns.highLevel) && (
                <div
                    css={css({
                        padding: "12px 16px",
                        backgroundColor: white,
                        boxShadow: ioTableCardBoxShadow,
                        border: renderCellBorder(IoTableColumns.highLevel),
                    })}
                >
                    {renderCellTitle(IoTableColumnTitles.highLevel)}
                    <div css={css({ fontSize: "14px" })}>
                        {row.highLevel ? (
                            <TableInput
                                data={row.highLevel}
                                switchId={row.parameterValue.id}
                                onFocus={(isFocused) =>
                                    handleFocus(
                                        isFocused,
                                        IoTableColumns.highLevel,
                                    )
                                }
                                onError={(error) =>
                                    handleError(error, IoTableColumns.highLevel)
                                }
                                hasNoInputErrorBorder
                                hasNoInputFocus
                                hasNoInputDisabledBackground
                            />
                        ) : null}
                    </div>
                </div>
            )}

            {isVisibleRow(IoTableColumns.eventOnly) && (
                <div
                    css={css({
                        padding: "12px 16px",
                        backgroundColor: white,
                        boxShadow: ioTableCardBoxShadow,
                        border: renderCellBorder(IoTableColumns.eventOnly),
                    })}
                >
                    {renderCellTitle(IoTableColumnTitles.eventOnly)}
                    <div css={css({ fontSize: "14px" })}>
                        {row.eventOnly ? (
                            <TableCollection
                                data={row.eventOnly}
                                switchId={row.parameterValue.id}
                                onFocus={(isOpen: boolean) =>
                                    handleFocus(
                                        isOpen,
                                        IoTableColumns.eventOnly,
                                    )
                                }
                                anchorPositionHorizontal={"left"}
                                slotProps={{
                                    paper: {
                                        style: {
                                            marginLeft: "8px",
                                            marginTop: "13px",
                                        },
                                    },
                                }}
                                hasNoInputFocus
                                hasNoInputDisabledBackground
                            />
                        ) : null}
                    </div>
                </div>
            )}

            {isVisibleRow(IoTableColumns.avgConst) && (
                <div
                    css={css({
                        padding: "12px 16px",
                        backgroundColor: white,
                        boxShadow: ioTableCardBoxShadow,
                        border: renderCellBorder(IoTableColumns.avgConst),
                    })}
                >
                    {renderCellTitle(IoTableColumnTitles.avgConst)}
                    <div css={css({ fontSize: "14px" })}>
                        {row.averagingConstant ? (
                            <TableInput
                                data={row.averagingConstant}
                                switchId={row.parameterValue.id}
                                onFocus={(isFocused) =>
                                    handleFocus(
                                        isFocused,
                                        IoTableColumns.avgConst,
                                    )
                                }
                                onError={(error) =>
                                    handleError(error, IoTableColumns.avgConst)
                                }
                                hasNoInputErrorBorder
                                hasNoInputFocus
                                hasNoInputDisabledBackground
                            />
                        ) : null}
                    </div>
                </div>
            )}

            {isVisibleRow(IoTableColumns.sendSmsTo) && (
                <div
                    css={css({
                        padding: "12px 16px",
                        backgroundColor: white,
                        boxShadow: ioTableCardBoxShadow,
                        border: renderCellBorder(IoTableColumns.sendSmsTo),
                    })}
                >
                    {renderCellTitle(IoTableColumnTitles.sendSmsTo)}
                    <div css={css({ fontSize: "14px" })}>
                        {row.phone ? (
                            <TableInput
                                data={row.phone}
                                switchId={row.parameterValue.id}
                                onFocus={(isFocused) =>
                                    handleFocus(
                                        isFocused,
                                        IoTableColumns.sendSmsTo,
                                    )
                                }
                                onError={(error) =>
                                    handleError(error, IoTableColumns.sendSmsTo)
                                }
                                hasNoInputErrorBorder
                                hasNoInputFocus
                                hasNoInputDisabledBackground
                            />
                        ) : null}
                    </div>
                </div>
            )}
        </div>
    );
};

export default MobileTableCard;
