/** @jsxRuntime classic */

/** @jsx jsx */
import { Fragment, useContext, useRef } from "react";

import { css, jsx } from "@emotion/react";

import CircularProgress from "@mui/material/CircularProgress";
import DialogTitle from "@mui/material/DialogTitle";

import LanguageContext from "../../../../../../../context/language/languageContext";
import ThemeContext from "../../../../../../../context/theme/themeContext";
import DeviceLocked from "../DeviceLocked";
import SharedPinActions from "../SharedContent/SharedPinActions";
import SharedPinDialogContent from "../SharedContent/SharedPinDialogContent";
import { SimPinModalTypes } from "../index";

import useChangePin from "./useChangePin";

interface ChangePinProps {
    simId: number;
    close: () => void;
}

const ChangePin = ({ simId, close }: ChangePinProps) => {
    const {
        colors: { white },
    } = useContext(ThemeContext);

    const { t } = useContext(LanguageContext);

    const submitBtnRef = useRef<HTMLButtonElement>(null);

    const {
        handlePin,
        handleSubmitBtn,
        deviceLocked,
        isLoading,
        isDisabled,
        step,
        explanatoryText,
        submitButtonText,
        errorMsg,
        retriesLeft,
    } = useChangePin({ simId, close });

    const renderButtonText = () =>
        isLoading ? (
            <CircularProgress
                size={20}
                css={css({
                    color: white,
                })}
            />
        ) : (
            submitButtonText
        );

    return deviceLocked() ? (
        <DeviceLocked type={SimPinModalTypes.ChangePin} close={close} />
    ) : (
        <Fragment>
            <DialogTitle>{t.ChangePinCode}</DialogTitle>

            <SharedPinDialogContent
                step={step}
                handlePayload={handlePin}
                errorMsg={errorMsg}
                retriesLeft={retriesLeft}
                explanatoryText={explanatoryText}
            />

            <SharedPinActions
                btnRef={submitBtnRef}
                isLoading={isLoading}
                isDisabled={isDisabled}
                cancelIdForTesting="cancelBtn"
                submitIdForTesting="submitBtn"
                handleClose={close}
                sendPin={handleSubmitBtn}
                renderButtonText={renderButtonText}
            />
        </Fragment>
    );
};

export default ChangePin;
