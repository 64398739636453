/** @jsxRuntime classic */

/** @jsx jsx */
import { Fragment, useContext, useState } from "react";

import { css, jsx } from "@emotion/react";

import { ReactComponent as AddIcon } from "../../../../../assets/icons/Add.svg";
import DeleteIcon from "../../../../../assets/icons/DeleteIcon";
import EditIcon from "../../../../../assets/icons/EditIcon";
import {
    AlertStatus,
    TriggerSwithValues,
} from "../../../../../constants/constants";
import AlertContext from "../../../../../context/alert/alertContext";
import LanguageContext from "../../../../../context/language/languageContext";
import MenuItemContext from "../../../../../context/menuItem/menuItemContext";
import { Modal } from "../../../../../generatedTypes";
import {
    firstLetterToLowercase,
    getDefaultValuesFromIoItem,
    replacePlaceholders,
    toFirstLowerCase,
} from "../../../../../utils/helpers";
import Button from "../../../../MuiComponents/Button";
import InputBlockWithActions from "../../../../MuiComponents/InputBlockWithActions";
import ConfirmModal from "../../../../MuiComponents/Modals/ConfirmModal";

import NotificationModal from "./components/modals/NotificationModal";

export interface NotificationOutputProps {
    data: Modal;
}

const NotificationOutput: React.FunctionComponent<NotificationOutputProps> = ({
    data,
}) => {
    const [notificationModalOpenInfo, setNotificationModalOpenInfo] = useState({
        isOpen: false,
        isEdit: false,
    });

    const [removeConfirmationModalInfo, setRemoveConfirmationModalInfo] =
        useState({
            isOpen: false,
        });

    const { parameters, updateParameters } = useContext(MenuItemContext);
    const { t } = useContext(LanguageContext);
    const { setAlert } = useContext(AlertContext);

    const outputSource = data.components.find(
        (item) => item.localizationKey === "Custom scenario output source",
    );

    const isOutputNotificationActive =
        parameters[data.switchComponent?.parameterId ?? ""] !==
        TriggerSwithValues.Off;

    const switchLabel =
        outputSource?.collectionConfig?.collectionItems.find(
            (item: any) => item.value === parameters[outputSource.parameterId],
        )?.text ?? "";

    const actions = [
        {
            icon: <EditIcon />,
            label: t.Edit,
            onClick: () =>
                setNotificationModalOpenInfo({
                    isOpen: true,
                    isEdit: true,
                }),
            disabledTooltipText: "",
        },
        {
            icon: (
                <DeleteIcon
                    css={css({
                        width: "18px",
                        height: "18px",
                    })}
                />
            ),
            label: t.Remove,
            onClick: () => setRemoveConfirmationModalInfo({ isOpen: true }),
        },
    ];

    const handleRemove = () => {
        const defaultValues = getDefaultValuesFromIoItem(data);
        try {
            updateParameters(defaultValues);
            setRemoveConfirmationModalInfo({ isOpen: false });
            setAlert(
                AlertStatus.Success,
                `${data.name} ${toFirstLowerCase(t.SuccessfullyRemoved || "")}`,
            );
        } catch (err) {
            setAlert(AlertStatus.Critical, t.FailedToRemove);
        }
    };

    const renderContent = () => {
        if (isOutputNotificationActive) {
            return (
                <InputBlockWithActions
                    actions={actions}
                    label={data.name ?? ""}
                    valueLabel={switchLabel}
                    info={data.tooltip ?? ""}
                />
            );
        }
        return (
            <div
                css={css({
                    width: "100%",
                    display: "flex",
                    gap: "8px",
                    flexDirection: "column",
                    margin: "24px 0px",
                })}
            >
                <Button
                    size="normal"
                    color="primarySubtle"
                    idForTesting="trigger"
                    variant="iconLeft"
                    icon={
                        <AddIcon
                            css={css({
                                path: {
                                    fill: "#003FB4",
                                },
                            })}
                        />
                    }
                    onClick={() =>
                        setNotificationModalOpenInfo({
                            isOpen: true,
                            isEdit: false,
                        })
                    }
                    fullWidth
                >
                    {t.AddOutputNotification}
                </Button>
            </div>
        );
    };
    return (
        <Fragment>
            {renderContent()}
            {notificationModalOpenInfo.isOpen && (
                <NotificationModal
                    isOpen={notificationModalOpenInfo.isOpen}
                    onClose={() =>
                        setNotificationModalOpenInfo({
                            isOpen: false,
                            isEdit: false,
                        })
                    }
                    isEdit={notificationModalOpenInfo.isEdit}
                    data={data}
                />
            )}
            {removeConfirmationModalInfo.isOpen && (
                <ConfirmModal
                    open={removeConfirmationModalInfo.isOpen}
                    close={() =>
                        setRemoveConfirmationModalInfo({
                            isOpen: false,
                        })
                    }
                    submit={handleRemove}
                    title={`${t.Remove} ${firstLetterToLowercase(data.name)}`}
                    description={replacePlaceholders(
                        t.RemoveCustomScenarioItemConfirmation,
                        firstLetterToLowercase(data.name),
                    )}
                    secondaryBtnLabel={t.Close}
                />
            )}
        </Fragment>
    );
};

export default NotificationOutput;
