import React from "react";

import MobileTableCard from "./MobileTableCard";
import MobileTableCardCan from "./MobileTableCardCan";

const VirtualizedMobileCard = React.memo(({ data, index, style }: any) => {
    const { rows, prepareRow, currentValues, allColumns } = data;

    const row = rows[index];

    prepareRow(row);

    const currentValue = currentValues?.[row.original.parameterValue?.id || ""];

    return (
        <div style={style}>
            {row.original.canId ? (
                <MobileTableCardCan
                    key={row.original.parameterValue.id}
                    row={row.original}
                />
            ) : (
                <MobileTableCard
                    key={row.original.parameterValue.id}
                    row={row.original}
                    currentValue={currentValue}
                    allColumns={allColumns}
                />
            )}
        </div>
    );
});

export default VirtualizedMobileCard;
