/** @jsxRuntime classic */

/** @jsx jsx */
import React, { Fragment, useContext, useRef, useState } from "react";

import { ColumnInstance } from "react-table";

import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList, VariableSizeList } from "react-window";

import { css, jsx } from "@emotion/react";

import { IoTableColumns } from "../../../../../../constants/constants";
import LayoutContext from "../../../../../../context/layout/layoutContext";
import SettingsContext from "../../../../../../context/settings/settingsContext";
import ThemeContext from "../../../../../../context/theme/themeContext";
import { ColumnLocalizedBase } from "../../../../../../generatedTypes";
import useMediaQueries from "../../../../../../utils/useMediaQueries";
import useHandleTableScroll from "../hooks/useHandleTableScroll";
import { useIoTableItemRender } from "../hooks/useIoTableItemRender";

import { IoTableHeader } from "./components/IoTableHeader";
import MemoizedRow from "./components/MemoizedRow";
import VirtualizedMobileCard from "./components/VirtualizedMobileCard";
import { useIoTable } from "./hooks/useIoTable";
import { useIoTableEffects } from "./hooks/useIoTableEffects";

interface IoTableProps {
    name: string;
    data: any[];
    isFirstTable: boolean;
    isCanTable?: boolean;
    ioColumnLocalization: ColumnLocalizedBase[];
    index: number;
}

const BASE_HEIGHTS = {
    header: 44, // Header section with name
    standardRow: 62, // Standard row height for most fields
    marginBottom: 8, // Margin between cards
    dataMask: 102, // Data mask row height
};

const DESKTOP_ROW_HEIGHT = 56;

const IoTable: React.FC<IoTableProps> = ({
    name,
    data,
    isFirstTable,
    isCanTable = false,
    ioColumnLocalization,
    index,
}) => {
    const { colors, boxShadows } = useContext(ThemeContext);
    const { isExplanatoryTextsHidden } = useContext(SettingsContext);
    const { layoutData } = useContext(LayoutContext);

    const listRef = useRef<any>(null);
    const headerRef = useRef<HTMLDivElement>(null);

    const { tableInstance, currentValues } = useIoTable({
        name,
        data,
        isCanTable,
    });
    const { renderToolbar } = useIoTableItemRender();

    const { toMd } = useMediaQueries();

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        setHiddenColumns,
        setAllFilters,
        setGlobalFilter,
        toggleHideColumn,
        allColumns,
    } = tableInstance;

    useIoTableEffects({
        data,
        setHiddenColumns,
        setAllFilters,
        setGlobalFilter,
        isCanTable,
    });

    const [visibleIndexes, setVisibleIndexes] = useState<any>(null);

    useHandleTableScroll(listRef, headerRef);

    const getItemHeight = ({ allColumns }: any) => {
        let height = BASE_HEIGHTS.header + BASE_HEIGHTS.marginBottom;

        allColumns.forEach((column: ColumnInstance) => {
            const hasData = column.isVisible;
            if (hasData && column.id === IoTableColumns.dataMask) {
                height += BASE_HEIGHTS.dataMask;
            } else if (hasData) {
                height += BASE_HEIGHTS.standardRow;
            } else {
                height += 0;
            }
        });

        return height;
    };

    const updateListHeight = () => {
        if (listRef.current) {
            listRef.current.resetAfterIndex(0);
        }
    };
    const isCanIdNotUndefined = data[0].canId !== undefined;

    if (toMd) {
        return (
            <Fragment>
                {renderToolbar(index, {
                    hasNoMoreFilters: isCanIdNotUndefined,
                    hasNoViewColumns: isCanIdNotUndefined,
                    columnSettings: {
                        activeColumns: allColumns,
                        onColumnChange: (columnValue: any) => {
                            toggleHideColumn(columnValue);
                            updateListHeight();
                        },
                        onReset: () => setHiddenColumns([]),
                    },
                })}
                <div
                    css={css({
                        marginBottom: "72px",
                        height: "80vh",
                        "& > div > div": {
                            "&::-webkit-scrollbar": {
                                display: "none",
                            },
                            scrollbarWidth: "none", // Firefox
                            msOverflowStyle: "none", // IE and Edge
                            overflow: "scroll",
                        },
                    })}
                >
                    <AutoSizer>
                        {({ height, width }: any) => (
                            <VariableSizeList
                                ref={listRef}
                                height={height}
                                itemCount={rows.length}
                                itemSize={() =>
                                    getItemHeight({
                                        allColumns,
                                    })
                                }
                                width={width}
                                itemData={{
                                    rows,
                                    prepareRow,
                                    currentValues,
                                    allColumns,
                                }}
                            >
                                {VirtualizedMobileCard}
                            </VariableSizeList>
                        )}
                    </AutoSizer>
                </div>
            </Fragment>
        );
    }

    return (
        <Fragment>
            {renderToolbar(index, {
                hasNoMoreFilters: isCanIdNotUndefined,
                hasNoViewColumns: isCanIdNotUndefined,
                columnSettings: {
                    activeColumns: allColumns,
                    onColumnChange: (columnValue: any) => {
                        toggleHideColumn(columnValue);
                    },
                    onReset: () => setHiddenColumns([]),
                },
            })}
            <div css={css({ maxWidth: "100%", marginBottom: "56px" })}>
                <div
                    css={css({
                        margin: isFirstTable ? "unset" : "10px 0px",
                        filter: "drop-shadow(0px 1px 4px rgba(15, 23, 42, 0.12))",
                        backgroundColor: colors.white,
                    })}
                    {...getTableProps()}
                >
                    <IoTableHeader
                        headerGroups={headerGroups}
                        headerRef={headerRef}
                        ioColumnLocalization={ioColumnLocalization}
                    />

                    <div {...getTableBodyProps()}>
                        <div
                            style={{
                                height: "65vh",
                                width: "100%",
                            }}
                        >
                            <AutoSizer>
                                {({ height, width }: any) => (
                                    <FixedSizeList
                                        overscanCount={40}
                                        ref={listRef}
                                        height={height}
                                        itemCount={rows.length}
                                        itemSize={DESKTOP_ROW_HEIGHT}
                                        width={width}
                                        itemData={{
                                            rows,
                                            prepareRow,
                                            colors,
                                            boxShadows,
                                            isExplanatoryTextsHidden,
                                            visibleIndexes,
                                        }}
                                        onItemsRendered={({
                                            visibleStartIndex,
                                            visibleStopIndex,
                                        }) => {
                                            setVisibleIndexes({
                                                start: visibleStartIndex,
                                                end: visibleStopIndex,
                                            });
                                        }}
                                    >
                                        {MemoizedRow}
                                    </FixedSizeList>
                                )}
                            </AutoSizer>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default IoTable;
