/** @jsxRuntime classic */

/** @jsx jsx */
import { useContext } from "react";

import { jsx } from "@emotion/react";

import { SearchRounded } from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";

import LanguageContext from "../../../../../context/language/languageContext";
import ManualGeofenceContext from "../../../../../context/manualGeofence/manualGeofenceContext";
import ThemeContext from "../../../../../context/theme/themeContext";

const SearchInput = () => {
    const {
        colors: { gray300, gray700, textDark, gray400, blue700, white },
        boxShadows: { focusBoxShadow },
    } = useContext(ThemeContext);

    const { t } = useContext(LanguageContext);
    const { geofenceSearchValue, setGeofenceSearchValue } = useContext(
        ManualGeofenceContext,
    );

    return (
        <TextField
            autoFocus
            fullWidth
            variant="filled"
            placeholder={t.Search}
            value={geofenceSearchValue}
            onChange={(e: any) => setGeofenceSearchValue(e.target.value)}
            disabled={false}
            sx={{
                maxWidth: "282px",
                width: "100%",
            }}
            InputProps={{
                spellCheck: "false",
                disableUnderline: true,
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchRounded />
                    </InputAdornment>
                ),
                sx: {
                    backgroundColor: "rgba(255, 255, 255, 0.05)",
                    color: textDark,
                    fontWeight: "600",
                    lineHeight: "20px",
                    letterSpacing: "0.1px",
                    padding: "6px 12px",
                    borderRadius: "6px",
                    fontSize: "14px",
                    border: `1px solid ${gray300}`,
                    "&:hover": {
                        borderColor: gray400,
                        backgroundColor: white,
                    },
                    "&.Mui-focused": {
                        borderColor: blue700,
                        borderWidth: "1px",
                        boxShadow: focusBoxShadow,
                        backgroundColor: white,
                    },

                    "& .MuiFilledInput-input": {
                        padding: "0",
                        "&::placeholder": {
                            color: gray700,
                            lineHeight: "20px",
                            letterSpacing: "0.1px",
                        },
                    },

                    "& .MuiInputAdornment-root.MuiInputAdornment-positionStart.MuiInputAdornment-root:not(.MuiInputAdornment-hiddenLabel)":
                        {
                            marginTop: "0",
                        },

                    "& .MuiInputAdornment-root": {
                        color: gray700,
                        height: "unset",
                    },
                },
            }}
        />
    );
};

export default SearchInput;
