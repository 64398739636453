/** @jsxRuntime classic */

/** @jsx jsx */
import { useContext, useEffect, useRef, useState } from "react";

import { css, jsx } from "@emotion/react";

import CircularProgress from "@mui/material/CircularProgress";

import AlertWarningIcon from "../../../../../../assets/icons/AlertWarningIcon";
import LanguageContext from "../../../../../../context/language/languageContext";
import MenuItemContext from "../../../../../../context/menuItem/menuItemContext";
import SettingsContext from "../../../../../../context/settings/settingsContext";
import ThemeContext from "../../../../../../context/theme/themeContext";
import { Component, SearchPath } from "../../../../../../generatedTypes";
import {
    getIdTooltipText,
    preventENotation,
} from "../../../../../../utils/helpers";
import useDebounce from "../../../../../../utils/useDebounce";
import useNavigation from "../../../../../../utils/useNavigation";
import { validateNumberLength } from "../../../../../../utils/validations";
import InputField from "../../../../../MuiComponents/InputField";
import Tag from "../../../../../MuiComponents/Tag";
import Tooltip from "../../../../../MuiComponents/Tooltip";
import TooltipLarge from "../../../../../MuiComponents/TooltipLarge";

export interface NumberInputProps {
    data: Component;
    isFirstColumnElement: boolean;
    isLastColumnElement: boolean;
    addError: (e: { id: number; error: string | null }) => void;
    hasNoTopBorder?: boolean;
    hasNoBottomBorder?: boolean;
}
const NumberInput = ({
    data,
    isFirstColumnElement,
    isLastColumnElement,
    addError,
    hasNoTopBorder,
    hasNoBottomBorder,
}: NumberInputProps) => {
    const {
        label,
        parameterId,
        parameterValue,
        placeholder,
        avlId,
        min,
        max,
        increment,
        isDisabled,
        disabledTooltip,
    } = data;

    const { t } = useContext(LanguageContext);

    const {
        colors: { blue700 },
    } = useContext(ThemeContext);
    const {
        updateParameter,
        findDisabledParameterById,
        setParameterError,
        setComponentVisibilities,
    } = useContext(MenuItemContext);
    const { isParameterIdsHidden } = useContext(SettingsContext);

    const debouncedChangeHandler = useDebounce(updateParameter, 500);

    const { handleSearchPath } = useNavigation();

    const isNumberInputDisabled =
        findDisabledParameterById(parameterId) !== undefined
            ? findDisabledParameterById(parameterId)
            : isDisabled;
    const [value, setValue] = useState(parameterValue);
    const [previousValue, setPreviousValue] = useState(parameterValue);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [requestError, setRequestError] = useState(false);

    const hasBeenMounted = useRef<boolean>(false);

    useEffect(() => {
        if (hasBeenMounted.current) {
            setParameterError(parameterId, error);
        } else {
            hasBeenMounted.current = true;
        }
        // eslint-disable-next-line
    }, [error]);

    const elementRef = useRef(null);

    const dependenciesToDisable =
        data.dependentParameterIds &&
        data.dependentParameterIds.length > 0 &&
        data.dependentParameterIds.map((dependant: number) => {
            return {
                parameterId: dependant,
                isDisabled: true,
            };
        });
    const disableDependencies = () => {
        dependenciesToDisable &&
            setComponentVisibilities(dependenciesToDisable);
    };

    const onSuccessfulRequest = (val: string) => {
        requestError && setRequestError(false);
        setPreviousValue(val);
    };

    const onFailedRequest = () => {
        setRequestError(true);
        addError && addError({ id: parameterId, error: null });
        setError(null);
        setValue(previousValue);
    };

    const onCanceledRequest = () => {
        setValue(previousValue);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;

        const errorValue = validateNumberLength(newValue, min, max, t);

        setError(errorValue);
        addError && addError({ id: parameterId, error: errorValue });
        setValue(String(newValue));

        if (errorValue) {
            setLoading(false);

            debouncedChangeHandler.cancel();
        } else {
            setLoading(true);

            disableDependencies();

            debouncedChangeHandler(
                parameterId,
                newValue,
                label,
                elementRef,
                () => onSuccessfulRequest(newValue),
                onFailedRequest,
                () => setLoading(false),
                false,
                onCanceledRequest,
            );
        }
    };

    const renderIdLabel = () => {
        if (parameterId && !isParameterIdsHidden) {
            return (
                <Tooltip
                    title={getIdTooltipText(parameterId, avlId)}
                    small
                    placement="top"
                    onMouseDown={(e) => e.stopPropagation()}
                    css={css({
                        zIndex: "1501",
                    })}
                >
                    <span>
                        <Tag size="tiny" color="white" title="ID" />
                    </span>
                </Tooltip>
            );
        }
        return null;
    };

    const renderRequestIndicator = () => {
        return (
            <span>
                {loading ? (
                    <CircularProgress
                        size={13}
                        css={css({
                            color: blue700,
                        })}
                    />
                ) : requestError ? (
                    <Tooltip
                        title="Changes that were made recently could not be saved due to an error."
                        placement="top"
                    >
                        <span
                            css={css({
                                display: "inline-flex",
                            })}
                        >
                            <AlertWarningIcon />
                        </span>
                    </Tooltip>
                ) : (
                    ""
                )}
            </span>
        );
    };

    return (
        <TooltipLarge
            title={
                isNumberInputDisabled && disabledTooltip
                    ? disabledTooltip.title
                    : ""
            }
            buttonText={
                disabledTooltip?.btnTitle ? disabledTooltip.btnTitle : ""
            }
            onBtnClick={() =>
                handleSearchPath(disabledTooltip as { searchPath: SearchPath })
            }
        >
            <span>
                <InputField
                    id={String(parameterId)}
                    ref={elementRef}
                    fullWidth
                    size="medium"
                    iconRight={renderIdLabel()}
                    iconLeft={renderRequestIndicator()}
                    requesting={loading}
                    requestFailed={requestError}
                    placeholder={placeholder ? placeholder : ""}
                    type="number"
                    inputProps={{ min, max, step: increment ? increment : 1 }}
                    value={value}
                    disabled={isNumberInputDisabled}
                    onChange={handleChange}
                    onKeyDown={preventENotation}
                    wrapperStyle={css({
                        scrollMargin: "16px",
                    })}
                    borderRadius={
                        isFirstColumnElement
                            ? "6px 6px 0px 0px"
                            : isLastColumnElement
                              ? "0px 0px 6px 6px"
                              : "unset"
                    }
                    hasNoTopBorder={hasNoTopBorder}
                    hasNoBottomBorder={hasNoBottomBorder}
                    error={error}
                    stacked={true}
                />
            </span>
        </TooltipLarge>
    );
};

export default NumberInput;
