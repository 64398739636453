/** @jsxRuntime classic */

/** @jsx jsx */
import { Fragment, useContext, useState } from "react";

import { css, jsx } from "@emotion/react";

import { AlertStatus } from "../../../../../constants/constants";
import AlertContext from "../../../../../context/alert/alertContext";
import LayoutContext from "../../../../../context/layout/layoutContext";
import { Component, Modal } from "../../../../../generatedTypes";
import useTct from "../../../../../utils/useTct";
import FormModal from "../../../../MuiComponents/Modals/FormModal";
import Collection from "../Component/Collection";
import TextBox from "../Component/TextBox";

import CardSection from "./CardSection";

export interface SetSmsModalSectionProps {
    data: Modal;
}

const SetSmsModalSection = ({ data }: SetSmsModalSectionProps) => {
    const { layoutData } = useContext(LayoutContext);
    const { setAlert } = useContext(AlertContext);

    const [isLoading, setLoading] = useState(false);
    const [isModalOpen, setOpenModal] = useState(false);
    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);
    const [frameData, setFrameData] = useState(data);
    const [collectionInputValue, setCollectionInputValue] = useState<any>(null);
    const [textInputValue, setTextInputValue] = useState<any>(null);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);
    const { updateParametersAsync } = useTct();

    const handleSubmitForm = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const payload = [collectionInputValue, textInputValue].filter(Boolean);
        const handleModalSubmit = () =>
            setFrameData((prev: Modal) => {
                return {
                    ...prev,
                    components: prev.components.map((item: Component) => {
                        if (item.parameterId === collectionInputValue?.id) {
                            return {
                                ...item,
                                parameterValue: collectionInputValue.value,
                            };
                        }
                        if (item.parameterId === textInputValue?.id) {
                            return {
                                ...item,
                                parameterValue: textInputValue.value,
                            };
                        }
                        return item;
                    }),
                };
            });
        try {
            setLoading(true);
            await updateParametersAsync(layoutData?.id || 0, payload);
            handleModalSubmit();
            handleCloseModal();
            setAlert(AlertStatus.Success, "Updated successfully");
        } catch (error) {
            setFrameData(data);
            setAlert(AlertStatus.Critical, "Failed to update");
        }

        setLoading(false);
    };

    const renderContent = (componentData: Component) => {
        if (componentData.componentType === "TextBox") {
            return (
                <TextBox
                    multiline
                    data={componentData}
                    onChange={(e: { id: number; value: string }) =>
                        setTextInputValue({
                            id: e.id,
                            value: e.value,
                        })
                    }
                    onError={(error: string | null) =>
                        setIsSaveButtonDisabled(Boolean(error))
                    }
                />
            );
        }
        if (componentData.componentType === "Collection") {
            return (
                <Collection
                    data={componentData}
                    onChange={(e: { id: number; value: string }) =>
                        setCollectionInputValue({
                            id: e.id,
                            value: String(e.value),
                        })
                    }
                    hasNoDependencies
                />
            );
        }
        return null;
    };

    return (
        <Fragment>
            <CardSection data={frameData} onButtonClick={handleOpenModal} />
            <FormModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                onSubmit={handleSubmitForm}
                title={frameData.name || ""}
                description={frameData.description}
                secondaryButtonText={frameData.cancelCaption}
                primaryButtonText={frameData.saveCaption}
                isPrimaryButtonDisabled={isSaveButtonDisabled}
                isSubmitting={isLoading}
            >
                {frameData.components.map((component: Component, i: number) => (
                    <div
                        key={component.index}
                        css={css({
                            marginBottom:
                                i === frameData.components.length - 1
                                    ? "0"
                                    : "16px",
                        })}
                    >
                        {renderContent(component)}
                    </div>
                ))}
            </FormModal>
        </Fragment>
    );
};

export default SetSmsModalSection;
