import { createContext } from "react";

import { ColumnInstance } from "react-table";

import { InitialState } from "./inputOutputReducer";

export interface InputOutputColumn {
    name: string;
    isDisabled: boolean;
    value?: string;
    isActive?: boolean;
    isHidden?: boolean;
}

interface InputOutputContextState extends InitialState {
    filterColumnsByName: (columnInfo: {
        value: string;
        isActive: boolean;
    }) => void;
    setActiveFilters: (filters: string[]) => void;
    setActiveFilter: (filter: string) => void;
    setActivePriority: (priority: string[]) => void;
    setActiveOperand: (operand: string[]) => void;
    resetAllFilters: () => void;
    resetFilter: (filter: string) => void;
    resetPriority: (priority: string) => void;
    resetOperand: (operand: string) => void;
    setActiveStatus: (status: string) => void;
    setSearchValue: (value: string) => void;
    resetColumn: (column: string) => void;
    setColumns: (columns: ColumnInstance[]) => void;
}

const InputOutputContext = createContext({} as InputOutputContextState);

export default InputOutputContext;
