/** @jsxRuntime classic */

/** @jsx jsx */
import { useContext, useEffect, useRef, useState } from "react";

import { css, jsx } from "@emotion/react";

import { SelectChangeEvent } from "@mui/material";

import LayoutContext from "../../../../../context/layout/layoutContext";
import MenuItemContext from "../../../../../context/menuItem/menuItemContext";
import { Component, SearchPath } from "../../../../../generatedTypes";
import useDebounce from "../../../../../utils/useDebounce";
import useNavigation from "../../../../../utils/useNavigation";
import IdBadge from "../../../../MuiComponents/IdBadge";
import Label from "../../../../MuiComponents/Label";
import Switch from "../../../../MuiComponents/Switch";
import TooltipLarge from "../../../../MuiComponents/TooltipLarge";

export interface BinaryInputProps {
    data: Component;
    onChange?: (event: { id: number; value: string }) => void;
}

const BinaryInput = ({ data, onChange }: BinaryInputProps) => {
    const {
        label,
        parameterId,
        parameterValue,
        avlId,
        isDisabled,
        tooltip = "",
        disabledTooltip,
    } = data;
    const {
        parameters,
        updateParameter,
        findDisabledParameterById,
        setComponentVisibilities,
    } = useContext(MenuItemContext);
    const { setDrawerParameterId } = useContext(LayoutContext);

    const { handleSearchPath } = useNavigation();

    const debouncedChangeHandler = useDebounce(updateParameter, 500);

    const [value, setValue] = useState(parameterValue);
    const [previousValue, setPreviousValue] = useState(parameterValue);
    const [loading, setLoading] = useState(false);
    const [requestError, setRequestError] = useState(false);

    const isInputDisabled =
        findDisabledParameterById(parameterId) !== undefined
            ? findDisabledParameterById(parameterId)
            : isDisabled;

    const elementRef = useRef(null);

    const paramValFromContext = parameters[parameterId];

    useEffect(() => {
        setValue(paramValFromContext);
        setPreviousValue(paramValFromContext);
        // eslint-disable-next-line
    }, [paramValFromContext]);

    const disableDependencies = () => {
        const dependenciesToDisable =
            data.dependentParameterIds?.map((dependant: number) => ({
                parameterId: dependant,
                isDisabled: true,
            })) || [];

        dependenciesToDisable &&
            setComponentVisibilities(dependenciesToDisable);
    };

    const onSuccessfulRequest = (val: string) => {
        setPreviousValue(val);
        requestError && setRequestError(false);
    };

    const onFailedRequest = () => {
        setValue(previousValue);
        setRequestError(true);
    };

    const handleToggle = (e: SelectChangeEvent<any>) => {
        const newValue = value === "0" ? "1" : "0";
        setValue(newValue);

        if (onChange) {
            onChange({ id: parameterId, value: newValue });
        } else {
            setLoading(true);
            disableDependencies();
            debouncedChangeHandler(
                parameterId,
                newValue,
                label,
                elementRef,
                () => onSuccessfulRequest(newValue),
                onFailedRequest,
                () => setLoading(false),
                true,
                () => setValue(previousValue),
            );
        }
    };

    return (
        <TooltipLarge
            title={
                isInputDisabled && disabledTooltip ? disabledTooltip.title : ""
            }
            buttonText={
                disabledTooltip?.btnTitle ? disabledTooltip.btnTitle : ""
            }
            onBtnClick={() =>
                handleSearchPath(disabledTooltip as { searchPath: SearchPath })
            }
        >
            <span>
                <div
                    css={css({
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    })}
                    data-test={String(parameterId)}
                >
                    {label && (
                        <Label
                            title={label}
                            requesting={loading}
                            requestFailed={requestError}
                            info={tooltip}
                            onInfoIconClick={() =>
                                setDrawerParameterId(parameterId)
                            }
                        />
                    )}
                    <Switch
                        ref={elementRef}
                        checked={value !== "0"}
                        onChange={handleToggle}
                        size="medium"
                        labelPlacement="start"
                        label={
                            <IdBadge parameterId={parameterId} avlId={avlId} />
                        }
                        disabled={isInputDisabled}
                        idForTesting={parameterId}
                    />
                </div>
            </span>
        </TooltipLarge>
    );
};

export default BinaryInput;
