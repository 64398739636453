/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { Fragment, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import useApi from "../../../../utils/useApi";
import ThemeContext from "../../../../context/theme/themeContext";
import LanguageContext from "../../../../context/language/languageContext";
import LayoutContext from "../../../../context/layout/layoutContext";
import useMediaQueries from "../../../../utils/useMediaQueries";
import Box from "../../../Box";
import ModelImage from "../../ModelImage";
import Button from "../../../MuiComponents/Button";
import TypedGrid from "../../../MuiComponents/TypedGrid";
import {
    BluetoothConnectedRounded as BluetoothPortIcon,
    ChevronRightRounded as ChevronRightIcon,
    SettingsInputHdmiRounded as ComPortIcon,
} from "@mui/icons-material";
import CircularProgress from "@mui/material/CircularProgress";
import { EDITOR_STATUS } from "../../../../constants/routes";
import { DiscoveredDeviceData } from "../../../../utils/types";
import { ConnectionTypes } from "../../../../constants/constants";
import DeviceAuthorizationContext from "../../../../context/deviceAuthorization/deviceAuthorizationContext";
import useConfiguration from "../../../../utils/useConfiguration";
import Tooltip from "../../../MuiComponents/Tooltip";

interface SingleDiscoveredDeviceProps {
    data: DiscoveredDeviceData;
}

const SingleDiscoveredDevice = ({ data }: SingleDiscoveredDeviceProps) => {
    const {
        isAuthorized,
        hwversion,
        fmType,
        imei,
        firmware,
        specId,
        connectionType,
        portName,
        technologies,
        isConfigurable,
    } = data;

    const {
        colors: { white, blue700, gray300, gray200, gray700 },
    } = useContext(ThemeContext);

    const { selectedLanguage, t } = useContext(LanguageContext);

    const { setLayoutError, deviceConfigureButtonPortName } =
        useContext(LayoutContext);

    const history = useHistory();
    const { toMd } = useMediaQueries();
    const { showErrorMsg, disconnectFromDevice } = useApi();
    const { getConfigurationFromDevice } = useConfiguration();

    const [isLoading, setLoading] = useState(false);

    const {
        openEnterPasswordModal: openContextEnterPasswordModal,
        openFreshUnlockDeviceModal,
    } = useContext(DeviceAuthorizationContext);

    const openEnterPasswordModal = (additionalInfo: any) => {
        openContextEnterPasswordModal(
            portName,
            connectionType,
            getLayoutData,
            additionalInfo
        );
    };

    const openDeviceLockedModal = (code: string) => {
        openFreshUnlockDeviceModal(
            portName,
            connectionType,
            getLayoutData,
            code
        );
    };

    const tryConnectToDevice = async (redirect: boolean) => {
        // If not going to the loading screen, show spinner
        !redirect && setLoading(true);

        await getConfigurationFromDevice(
            portName,
            connectionType,
            false,
            firmware
        );

        // If it expected to not connect, but it did, go to editor
        !redirect && history.push(EDITOR_STATUS);
    };
    const handleConnectRequestError = async (error: any, redirect: boolean) => {
        const errorStatus = error.response.status;
        if (redirect) {
            // only set layout error if redirected to editor, otherwise the layout error is not visible to the user
            // and on the next successful request, the error is still visible, while loading the layout
            const responseErrorObject =
                errorStatus === 401
                    ? { response: { data: { detail: t.DeviceIsLocked } } }
                    : error;
            setLayoutError(responseErrorObject);
            await disconnectFromDevice(selectedLanguage, true);
        } else if (errorStatus === 401) {
            const errorData = error.response.data;
            const authorizationInfo = JSON.parse(errorData.detail);

            authorizationInfo.requiresUnlock
                ? openDeviceLockedModal(authorizationInfo.unlockCode)
                : openEnterPasswordModal(authorizationInfo);
        } else {
            showErrorMsg(error);
        }
    };

    const getLayoutData = async (redirect: boolean) => {
        // Go to editor, so it shows the loading screen
        redirect && history.push(EDITOR_STATUS);
        try {
            await tryConnectToDevice(redirect);
        } catch (error) {
            await handleConnectRequestError(error, redirect);
        }

        !redirect && setLoading(false);
    };

    const isConfigureButtonDisabled =
        !isConfigurable || deviceConfigureButtonPortName === portName;

    return (
        <TypedGrid
            item
            xs0={12}
            xl={6}
            css={css({
                "&.discovered-single-enter": {
                    opacity: "0",
                },

                "&.discovered-single-enter-active": {
                    opacity: "1",
                    transition: "opacity 300ms",
                },

                "&.discovered-single-exit": {
                    opacity: "1",
                },

                "&.discovered-single-exit-active": {
                    opacity: "0",
                    transition: "opacity 150ms",
                },
            })}
        >
            <Box
                css={css({
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    padding: "16px",
                    background: white,
                    border: `1px solid ${gray200}`,
                    borderRadius: "6px",

                    "@media (min-width: 840px)": {
                        boxShadow: "none",
                    },

                    "@media (max-width: 839px)": {
                        display: "block",
                        border: "none",
                    },
                })}
            >
                <div
                    css={css({
                        display: "flex",

                        "@media (max-width: 839px)": {
                            marginBottom: "16px",
                        },
                    })}
                >
                    <ModelImage
                        isConfigurable={isConfigurable}
                        fmType={fmType}
                        version={hwversion}
                        discovered
                    />

                    <div
                        css={css({
                            marginLeft: "16px",

                            "@media (max-width: 839px)": {
                                marginLeft: "0",
                            },
                        })}
                    >
                        <div
                            css={css({
                                color: blue700,
                                fontSize: "20px",
                                lineHeight: "20px",
                                fontWeight: "600",
                                letterSpacing: "0.15px",
                                marginBottom: "4px",
                            })}
                        >
                            {fmType}
                        </div>

                        <div
                            css={css({
                                color: gray700,
                                fontSize: "16px",
                                lineHeight: "20px",
                                fontWeight: "600",
                                letterSpacing: "0.15px",

                                "div:nth-of-type(1), div:nth-of-type(2), div:nth-of-type(3)":
                                    {
                                        span: {
                                            fontSize: "14px",
                                            fontWeight: "400",
                                            letterSpacing: "0.1px",
                                        },
                                    },

                                "div + div:not(:last-of-type)": {
                                    marginTop: "4px",
                                },
                            })}
                        >
                            <div>
                                <span>IMEI: </span>
                                {imei}
                            </div>

                            <div>
                                <span>Firmware: </span>
                                {firmware}
                            </div>
                            <div>
                                {(specId || 0) > 1 && (
                                    <Fragment>
                                        <span>Spec ID: </span>
                                        {specId}
                                    </Fragment>
                                )}
                            </div>

                            <div
                                css={css({
                                    display: "flex",
                                    alignItems: "center",
                                    flexWrap: "wrap",

                                    span: {
                                        display: "flex",
                                        alignItems: "center",
                                        padding: "0 4px",
                                        fontSize: "14px",
                                        letterSpacing: "0.1px",
                                        marginTop: "4px",
                                    },

                                    "span:first-of-type": {
                                        paddingLeft: "0",

                                        svg: {
                                            fontSize: "16px",
                                            marginRight: "4px",
                                        },
                                    },
                                })}
                            >
                                <span>
                                    {connectionType ===
                                    ConnectionTypes.Serial ? (
                                        <ComPortIcon />
                                    ) : (
                                        <BluetoothPortIcon />
                                    )}

                                    {connectionType === ConnectionTypes.Serial
                                        ? portName
                                        : "BT"}
                                </span>

                                {technologies?.map(
                                    (
                                        technology: string,
                                        i: number,
                                        allTechnologies: string[]
                                    ) => (
                                        <span
                                            key={technology}
                                            css={css({
                                                position: "relative",

                                                ...(i === 0
                                                    ? {
                                                          "&:before": {
                                                              content: '""',
                                                              position:
                                                                  "absolute",
                                                              left: "0",
                                                              top: "0",
                                                              bottom: "0",
                                                              width: "1px",
                                                              background:
                                                                  gray300,
                                                          },
                                                      }
                                                    : {}),

                                                ...(i !==
                                                allTechnologies.length - 1
                                                    ? {
                                                          "&:after": {
                                                              content: '""',
                                                              position:
                                                                  "absolute",
                                                              right: "0",
                                                              top: "0",
                                                              bottom: "0",
                                                              width: "1px",
                                                              background:
                                                                  gray300,
                                                          },
                                                      }
                                                    : {}),
                                            })}
                                        >
                                            {technology}
                                        </span>
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <Tooltip
                    title={
                        !isConfigurable
                            ? t.DeviceNotSupportedCannotConfigure
                            : ""
                    }
                >
                    <span>
                        <Button
                            fullWidth={toMd}
                            variant="iconRight"
                            size="small"
                            color="primary"
                            idForTesting={`configure-${portName}`}
                            icon={
                                isLoading ||
                                (isConfigureButtonDisabled &&
                                    isConfigurable) ? (
                                    <div
                                        css={css({
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            width: "24px",
                                            height: "24px",
                                        })}
                                    >
                                        <CircularProgress
                                            size={16}
                                            css={css({
                                                color: isConfigureButtonDisabled
                                                    ? gray300
                                                    : white,
                                            })}
                                        />
                                    </div>
                                ) : (
                                    <ChevronRightIcon />
                                )
                            }
                            onClick={() => {
                                getLayoutData(isAuthorized);
                            }}
                            css={css({
                                pointerEvents: isLoading ? "none" : "auto",
                            })}
                            disabled={isConfigureButtonDisabled}
                        >
                            {t.Configure}
                        </Button>
                    </span>
                </Tooltip>
            </Box>
        </TypedGrid>
    );
};

export default SingleDiscoveredDevice;
