/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx } from "@emotion/react";

import DesktopScheduleTable from "./DesktopScheduleTable";
import MobileScheduleTable from "./MobileScheduleTable";
import { ScheduleTableProps } from "./types";

const ScheduleTable = ({
    columns,
    tableRows,
    toMd,
    getRowValues,
    renderRowIds,
}: ScheduleTableProps) => {
    if (toMd) {
        return (
            <MobileScheduleTable
                tableRows={tableRows}
                getRowValues={getRowValues}
                renderRowIds={renderRowIds}
            />
        );
    }

    return (
        <DesktopScheduleTable
            columns={columns}
            tableRows={tableRows}
            getRowValues={getRowValues}
            renderRowIds={renderRowIds}
        />
    );
};

export default ScheduleTable;
