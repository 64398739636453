/** @jsxRuntime classic */

/** @jsx jsx */
import React, { useContext, useEffect, useState } from "react";

import { css, jsx } from "@emotion/react";

import {
    AlertStatus,
    MAC_ADDRESS_REGEX,
} from "../../../../../../../constants/constants";
import AlertContext from "../../../../../../../context/alert/alertContext";
import BluetoothViewContext from "../../../../../../../context/bluetoothView/bluetoothViewContext";
import LanguageContext from "../../../../../../../context/language/languageContext";
import {
    BleScenarioType,
    LinkBox,
    SearchPath,
} from "../../../../../../../generatedTypes";
import useNavigation from "../../../../../../../utils/useNavigation";
import useTct from "../../../../../../../utils/useTct";
import FormModal from "../../../../../../MuiComponents/Modals/FormModal";
import TypedGrid from "../../../../../../MuiComponents/TypedGrid";
import NewTextBox from "../../../../../../MuiComponents/reusableInputs/NewTextBox";

import ScenarioCard from "./components/ScenarioCard";

export interface ScenarioModalProps {}

const ScenarioModal: React.FunctionComponent<ScenarioModalProps> = () => {
    const {
        bluetoothScenarioModalInfo,
        setBluetoothScenarioModalInfo,
        setBluetoothDevices,
    } = useContext(BluetoothViewContext);
    const { t } = useContext(LanguageContext);
    const { setAlert } = useContext(AlertContext);

    const [scenarios, setScenarios] = useState<any[]>([]);

    const scenarioType =
        bluetoothScenarioModalInfo?.data?.scenarioType !== "Unknown"
            ? bluetoothScenarioModalInfo?.data?.scenarioType
            : "";

    const [activeScenario, setActiveScenario] = useState(scenarioType);

    const [macAddress, setMacAddress] = useState(
        bluetoothScenarioModalInfo.data.macAddress || "",
    );

    const [macAddressError, setMacAddressError] = useState<string | null>("");

    const {
        getBluetoothScenarioAsync,
        pairBluetoothDeviceAsync,
        getBluetoothDevicesAsync,
        editBluetoothDeviceAsync,
    } = useTct();

    const { handleSearchPath } = useNavigation();

    useEffect(() => {
        const fetchBluetoothScenarios = async () => {
            try {
                const data = await getBluetoothScenarioAsync();
                setScenarios(data);
            } catch (error) {
                console.log(error);
            }
        };

        fetchBluetoothScenarios();
    }, []);

    const isEdit = bluetoothScenarioModalInfo?.isEdit;
    const isAddManually = bluetoothScenarioModalInfo?.isAddManually;

    const handleFetchBluetoothDevices = async () => {
        const data = await getBluetoothDevicesAsync();
        setBluetoothDevices(data);
    };

    const handleSubmit = async () => {
        const objectForPairing = {
            macAddress: macAddress,
            scenarioType: activeScenario as BleScenarioType,
            deviceType: bluetoothScenarioModalInfo.data.deviceType,
            ...(bluetoothScenarioModalInfo.data.slotId && {
                slotId: bluetoothScenarioModalInfo.data.slotId,
            }),
        };
        try {
            let linkBoxData: LinkBox;
            if (isEdit) {
                const editResponse =
                    await editBluetoothDeviceAsync(objectForPairing);
                linkBoxData = editResponse;
            } else {
                const addResponse =
                    await pairBluetoothDeviceAsync(objectForPairing);
                linkBoxData = addResponse;
            }

            await handleFetchBluetoothDevices();
            setBluetoothScenarioModalInfo({ isOpen: false, data: null });

            setAlert(
                AlertStatus.Info,
                linkBoxData?.name,
                linkBoxData.description,
                linkBoxData.button,
                () =>
                    handleSearchPath({
                        searchPath: linkBoxData.searchPath as SearchPath,
                    }),
            );
        } catch (err: any) {
            const error = err.response?.data;
            setAlert(AlertStatus.Critical, error.detail);
        }
    };

    const renderAdditionalInputs = () => {
        if (isEdit || isAddManually) {
            return (
                <TypedGrid container>
                    <TypedGrid item xs={6}>
                        <NewTextBox
                            componentValue={macAddress}
                            onChange={(e) => setMacAddress(e.value)}
                            data={
                                {
                                    label: t.MacAddress,
                                    placeholder: "",
                                    validate: MAC_ADDRESS_REGEX,
                                    isDisabled: isEdit,
                                    disabledTooltip: {
                                        title: isEdit
                                            ? t.CannotEditMacAddress
                                            : "",
                                    },
                                } as any
                            }
                            onError={(e) => setMacAddressError(e)}
                            resetErrorOnUnmount
                        />
                    </TypedGrid>
                </TypedGrid>
            );
        }
        return null;
    };

    const renderTitle = () => {
        if (isEdit) {
            return t.EditAccessoryScenarioTitle;
        } else {
            return t.AddAccessoryScenarioTitle;
        }
    };

    const renderDescription = () => {
        if (isEdit) {
            return t.EditAccessoryScenarioDescription;
        } else {
            return t.AddAccessoryScenarioDescription;
        }
    };

    const isPrimaryButtonDisabled =
        (!isEdit && (!activeScenario || !macAddress)) ||
        Boolean(macAddressError);

    return (
        <FormModal
            isOpen={bluetoothScenarioModalInfo.isOpen}
            onClose={() =>
                setBluetoothScenarioModalInfo({ isOpen: false, data: null })
            }
            onSubmit={handleSubmit}
            title={renderTitle()}
            description={renderDescription()}
            secondaryButtonText={t.Cancel}
            primaryButtonText={t.Save}
            isPrimaryButtonDisabled={isPrimaryButtonDisabled}
            isSubmitting={false}
            customModalStyles={css({
                maxWidth: "920px",
            })}
            disabledTooltip={{
                title: t.AddScenarioDisabledTooltip,
            }}
        >
            <div
                css={css({
                    display: "flex",
                    gap: "16px",
                    flexDirection: "column",
                })}
            >
                {renderAdditionalInputs()}
                <div
                    css={css({
                        display: "flex",
                        gap: "16px",
                        flexWrap: "wrap",
                    })}
                >
                    {scenarios.map((scenario) => {
                        return (
                            <ScenarioCard
                                data={scenario}
                                key={scenario.name}
                                onClick={() => setActiveScenario(scenario.name)}
                                isActive={activeScenario === scenario.name}
                            />
                        );
                    })}
                </div>
            </div>
        </FormModal>
    );
};

export default ScenarioModal;
