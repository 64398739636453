/** @jsxRuntime classic */

/** @jsx jsx */
import { Fragment, useContext, useReducer } from "react";

import { css, jsx } from "@emotion/react";

import CircularProgress from "@mui/material/CircularProgress";
import DialogTitle from "@mui/material/DialogTitle";

import { AlertStatus } from "../../../../../../../constants/constants";
import AlertContext from "../../../../../../../context/alert/alertContext";
import LanguageContext from "../../../../../../../context/language/languageContext";
import ThemeContext from "../../../../../../../context/theme/themeContext";
import useApi from "../../../../../../../utils/useApi";
import DeviceLocked from "../DeviceLocked";
import SharedPinActions from "../SharedContent/SharedPinActions";
import SharedPinDialogContent from "../SharedContent/SharedPinDialogContent";
import {
    HANDLE_CURRENT_PIN,
    SET_LOADING,
    WRONG_CURRENT_PIN,
} from "../context/pinActions";
import enterPinReducer from "../context/pinReducer";
import { SimPinModalTypes } from "../index";

interface EnterPinProps {
    simId: number;
    close: () => void;
}

const EnterPin = ({ simId, close }: EnterPinProps) => {
    const {
        colors: { white },
    } = useContext(ThemeContext);

    const { selectedLanguage, t } = useContext(LanguageContext);
    const { setAlert } = useContext(AlertContext);

    const { postData } = useApi();

    const initialState = {
        isLoading: false,
        isDisabled: true,
        currentPin: null,
        errorMsg: null,
        retriesLeft: null,
    };

    const [state, dispatch] = useReducer(enterPinReducer, initialState);

    const { isLoading, isDisabled, currentPin, errorMsg, retriesLeft } = state;

    const setLoading = (isLoadingSet: boolean) =>
        dispatch({ type: SET_LOADING, payload: isLoadingSet });

    const handlePayload = (value: string | null) =>
        dispatch({ type: HANDLE_CURRENT_PIN, payload: value });

    const sendPin = async () => {
        try {
            setLoading(true);

            const { data } = await postData(
                `${selectedLanguage}/command/entersimpin`,
                {
                    simId: simId,
                    pin: currentPin,
                },
            );

            if (data.success) {
                setAlert(AlertStatus.Success, t.PinSuccessfullyEntered);
                close();
            } else {
                dispatch({
                    type: WRONG_CURRENT_PIN,
                    payload: data.retriesLeft,
                });
            }
        } catch (error) {
            setAlert(AlertStatus.Critical, t.ErrorOccurredEnteringPin);
            setLoading(false);
        }
    };

    const renderButtonText = () =>
        isLoading ? (
            <CircularProgress
                size={20}
                css={css({
                    color: white,
                })}
            />
        ) : (
            t.Enter
        );

    return retriesLeft && retriesLeft + 1 <= 1 ? (
        <DeviceLocked type={SimPinModalTypes.EnterPin} close={close} />
    ) : (
        <Fragment>
            <DialogTitle>{t.EnterPinCode}</DialogTitle>

            <SharedPinDialogContent
                step={1}
                handlePayload={handlePayload}
                errorMsg={errorMsg}
                retriesLeft={retriesLeft}
                explanatoryText={t.EnterPinCode}
            />

            <SharedPinActions
                isLoading={isLoading}
                isDisabled={isDisabled}
                cancelIdForTesting="cancelButton"
                submitIdForTesting="enterButton"
                handleClose={close}
                sendPin={sendPin}
                renderButtonText={renderButtonText}
            />
        </Fragment>
    );
};

export default EnterPin;
