/** @jsxRuntime classic */

/** @jsx jsx */
import React, { Fragment, useContext, useRef, useState } from "react";

import {
    TableInstance,
    UseFiltersInstanceProps,
    UseGlobalFiltersInstanceProps,
} from "react-table";

import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";

import { css, jsx } from "@emotion/react";

import SettingsContext from "../../../../../../context/settings/settingsContext";
import ThemeContext from "../../../../../../context/theme/themeContext";
import {
    ColumnLocalizedBase,
    Component,
} from "../../../../../../generatedTypes";
import IoTableHeader from "../IoTable/components/IoTableHeader";
import MemoizedRow from "../IoTable/components/MemoizedRow";
import { useGenericTable } from "../IoTable/hooks/useGenericTable";
import { useGenericTableEffects } from "../IoTable/hooks/useGenericTableEffects";
import useHandleTableScroll from "../hooks/useHandleTableScroll";
import { useIoTableItemRender } from "../hooks/useIoTableItemRender";

interface GenericTableProps {
    tableData: any;
    columns: Component[];
    ioColumnLocalization: ColumnLocalizedBase[];
    index: number;
}

const GenericTable = ({
    tableData,
    columns,
    ioColumnLocalization,
    index,
}: GenericTableProps) => {
    const { boxShadows, colors } = useContext(ThemeContext);
    const { isExplanatoryTextsHidden } = useContext(SettingsContext);

    const { tableInstance } = useGenericTable({
        tableData,
        dynamicTableColumns: columns,
    });

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        setGlobalFilter,
        setFilter,
        allColumns,
        toggleHideColumn,
        setHiddenColumns,
    } = tableInstance as TableInstance<any> & {
        setGlobalFilter: UseGlobalFiltersInstanceProps<any>["setGlobalFilter"];
        setFilter: UseFiltersInstanceProps<any>["setFilter"];
    };

    useGenericTableEffects({
        setFilter,
        setGlobalFilter,
    });

    const { renderToolbar } = useIoTableItemRender();

    const listRef = useRef<any>(null);
    const headerRef = useRef<HTMLDivElement>(null);

    const [visibleIndexes, setVisibleIndexes] = useState<any>(null);

    useHandleTableScroll(listRef, headerRef);

    return (
        <Fragment>
            {renderToolbar(index, {
                hasNoMoreFilters: true,
                hasNoViewColumns: false,
                columnSettings: {
                    activeColumns: allColumns,
                    onColumnChange: (columnValue: any) => {
                        toggleHideColumn(columnValue);
                    },
                    onReset: () => setHiddenColumns([]),
                },
            })}
            <div css={css({ maxWidth: "100%", marginBottom: "56px" })}>
                <div
                    css={css({
                        margin: "10px 0px",
                        filter: "drop-shadow(0px 1px 4px rgba(15, 23, 42, 0.12))",
                        backgroundColor: "white",
                    })}
                    {...getTableProps()}
                >
                    <IoTableHeader
                        fromGenericTable
                        headerGroups={headerGroups}
                        headerRef={headerRef}
                        ioColumnLocalization={ioColumnLocalization}
                    />

                    <div {...getTableBodyProps()}>
                        <div
                            style={{
                                height: "65vh",
                                width: "100%",
                            }}
                        >
                            <AutoSizer>
                                {({ height, width }: any) => (
                                    <FixedSizeList
                                        ref={listRef}
                                        height={height}
                                        itemCount={rows.length}
                                        itemSize={56}
                                        width={width}
                                        itemData={{
                                            rows,
                                            prepareRow,
                                            colors,
                                            boxShadows,
                                            isExplanatoryTextsHidden,
                                            visibleIndexes,
                                        }}
                                        onItemsRendered={({
                                            visibleStartIndex,
                                            visibleStopIndex,
                                        }) => {
                                            setVisibleIndexes({
                                                start: visibleStartIndex,
                                                end: visibleStopIndex,
                                            });
                                        }}
                                    >
                                        {MemoizedRow}
                                    </FixedSizeList>
                                )}
                            </AutoSizer>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default GenericTable;
