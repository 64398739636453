/** @jsxRuntime classic */

/** @jsx jsx */
import React, { useContext, useEffect } from "react";

import { css, jsx } from "@emotion/react";

import { AlertStatus } from "../../../../../../../constants/constants";
import AlertContext from "../../../../../../../context/alert/alertContext";
import LanguageContext from "../../../../../../../context/language/languageContext";
import LayoutContext from "../../../../../../../context/layout/layoutContext";
import MenuItemContext from "../../../../../../../context/menuItem/menuItemContext";
import { Component, Trigger } from "../../../../../../../generatedTypes";
import {
    getParameterIdsAndValuesFromLayout,
    toFirstLowerCase,
} from "../../../../../../../utils/helpers";
import { IoValue } from "../../../../../../../utils/types";
import useTct from "../../../../../../../utils/useTct";
import BannerView from "../../../../../../MuiComponents/BannerView";
import NewCollection from "../../../../../../MuiComponents/reusableInputs/NewCollection";
import NewNumberInput from "../../../../../../MuiComponents/reusableInputs/NewNumberInput";
import NewTextBox from "../../../../../../MuiComponents/reusableInputs/NewTextBox";
import SharedFormModal from "../../../SharedFormModal";
import TriggerContext from "../../context/triggerContext";

import SourceDropdown from "./inputs/SourceDropdown";

export interface TriggerModalProps {
    isOpen: boolean;
    data: Trigger;
    onClose: () => void;
    isEdit?: boolean;
}

const TriggerModal: React.FunctionComponent<TriggerModalProps> = ({
    isOpen,
    onClose,
    data,
    isEdit,
}) => {
    const { t } = useContext(LanguageContext);

    const { updateParameters, parameters, setComponentVisibilities } =
        useContext(MenuItemContext);

    const { layoutData } = useContext(LayoutContext);

    const { setAlert } = useContext(AlertContext);

    const {
        getSourceCollection,
        setModalData,
        modalData,
        setValues,
        values,
        submitLoading,
        setSubmitLoading,
        resetModalState,
        updateSourceCollection,
        setErrors,
        hasErrors,
    } = useContext(TriggerContext);

    const { getIoValuesAsync, checkParameterVisibilityAsync } = useTct();

    useEffect(() => {
        if (modalData) {
            const defaultValues: { value: string; id: string }[] =
                getParameterIdsAndValuesFromLayout(modalData);
            const formattedValues = defaultValues.reduce((acc: any, { id }) => {
                acc[id] = parameters[id];
                return acc;
            }, {});
            setValues(formattedValues);
            handleSourceChange(
                {
                    id: modalData?.source?.parameterId,
                    value: parameters[modalData?.source?.parameterId],
                },
                true,
            );
        }

        return () => {
            resetModalState();
        };
    }, []);

    useEffect(() => {
        const handleOperandVisibility = async () => {
            if (!values[modalData?.operand?.parameterId ?? ""]) {
                return;
            }
            const data = await checkParameterVisibilityAsync(
                layoutData?.id ?? 0,
                modalData?.operand?.parameterId ?? 0,
                values[modalData?.operand?.parameterId ?? ""],
            );
            setComponentVisibilities(data?.visibilities);
        };

        handleOperandVisibility();
    }, [values[modalData?.operand?.parameterId ?? ""]]);

    const createIoValueFinder = (defaultValues: IoValue[]) => {
        return (name: string) =>
            defaultValues.find((item) => item.name === name);
    };

    const resolveParameterValue = (
        ioValue: string | undefined,
        fallbackValue: string | undefined,
    ) => ioValue ?? fallbackValue;

    const resolveIoProperty = (
        finder: (name: string) => IoValue | undefined,
        ioName: string,
        propertyName: keyof IoValue,
        defaultValue: any,
    ) => {
        const item = finder(ioName);
        return (item?.[propertyName] as any) ?? defaultValue;
    };

    const handleSourceChange = async (
        event: { id: number; value: string },
        keepPreviousValues?: boolean,
    ) => {
        const { value, id } = event;

        if (value === "0") {
            return;
        }

        try {
            const overwriteLayoutData = await getIoValuesAsync(
                layoutData?.id ?? 0,
                value,
                (data.type as unknown as number) ?? 0,
            );

            const findIoItem = createIoValueFinder(
                overwriteLayoutData.values || [],
            );

            // Get parameter IDs
            const lowLevelId = modalData?.lowLevel?.parameterId ?? "";
            const highLevelId = modalData?.highLevel?.parameterId ?? "";
            const operandId = modalData?.operand?.parameterId ?? "";

            const lowLevelValue = resolveParameterValue(
                findIoItem("IoLowLevel")?.value,
                parameters[lowLevelId],
            );
            const highLevelValue = resolveParameterValue(
                findIoItem("IoHighLevel")?.value,
                parameters[highLevelId],
            );
            const operandValue = resolveParameterValue(
                findIoItem("IoOperand")?.value,
                parameters[operandId],
            );

            // Create updated modal data
            const newData = {
                ...modalData,
                operand: {
                    ...modalData.operand,
                    collectionConfig: {
                        ...modalData.operand?.collectionConfig,
                        ...overwriteLayoutData.operandOverride,
                    },
                },
                lowLevel: {
                    ...modalData.lowLevel,
                    min: resolveIoProperty(
                        findIoItem,
                        "IoLowLevel",
                        "minValue",
                        modalData.lowLevel?.min,
                    ),
                    max: resolveIoProperty(
                        findIoItem,
                        "IoHighLevel",
                        "maxValue",
                        modalData.highLevel?.max,
                    ),
                    validate: resolveIoProperty(
                        findIoItem,
                        "IoLowLevel",
                        "validate",
                        modalData.lowLevel?.validate ?? "",
                    ),
                    validationMessage: resolveIoProperty(
                        findIoItem,
                        "IoLowLevel",
                        "validationMessage",
                        modalData.highLevel?.validationMessage ?? "",
                    ),
                },
                highLevel: {
                    ...modalData.highLevel,
                    min: resolveIoProperty(
                        findIoItem,
                        "IoLowLevel",
                        "minValue",
                        modalData.lowLevel?.min,
                    ),
                    max: resolveIoProperty(
                        findIoItem,
                        "IoHighLevel",
                        "maxValue",
                        modalData.highLevel?.max,
                    ),
                    validate: resolveIoProperty(
                        findIoItem,
                        "IoHighLevel",
                        "validate",
                        modalData.highLevel?.validate ?? "",
                    ),
                    validationMessage: resolveIoProperty(
                        findIoItem,
                        "IoHighLevel",
                        "validationMessage",
                        modalData.highLevel?.validationMessage ?? "",
                    ),
                },
            };

            if (!keepPreviousValues) {
                setValues({
                    [id]: value,
                    [lowLevelId]: lowLevelValue,
                    [highLevelId]: highLevelValue,
                    [operandId]: operandValue,
                });
            }
            setModalData(newData as Trigger);
        } catch (error) {
            setAlert(AlertStatus.Critical, t.FailedToUpdateSource);
        }
    };

    const sourceId = data?.source?.parameterId;

    const handleChange = async (
        event: {
            id: number;
            value: string;
            error?: string;
        },
        _?: Component,
    ) => {
        const { id, value, error } = event;
        if (!error) {
            setValues({ [id]: value });
        }
        setErrors({ [id]: error });
    };

    const handleSubmit = async () => {
        setSubmitLoading(true);
        try {
            const transformedValues = Object.entries(values).map(
                ([id, value]) => ({
                    id: Number(id),
                    value: value,
                }),
            );
            updateParameters(
                transformedValues,
                () => setSubmitLoading(false),
                () => setSubmitLoading(false),
                false,
            );
            updateSourceCollection(
                modalData?.source.parameterId,
                values[sourceId],
            );
            setAlert(
                AlertStatus.Success,
                `${modalData.name} ${toFirstLowerCase(!isEdit ? t.SuccessfullyAdded : t.SuccessfullyUpdated || "")}`,
            );

            setSubmitLoading(false);
            onClose();
        } catch (error) {
            setSubmitLoading(false);
            setAlert(AlertStatus.Critical, t.FailedToUpdateParameters);
        }
    };

    const objectValuesArray = modalData
        ? Object.values(modalData).filter(
              (value) =>
                  typeof value === "object" &&
                  !Array.isArray(value) &&
                  value !== null &&
                  value.localizationKey !== "Logic" &&
                  value.localizationKey !== "Active",
          )
        : [];

    const componentsOfTrigger = modalData?.components || [];

    const sourceDataWithCollectionItems = {
        ...modalData?.source,
        collectionConfig: {
            ...getSourceCollection(
                parameters[data?.source?.parameterId],
                isEdit,
            ),
        },
    };

    const renderDynamicComponent = (component: Component) => {
        const componentValue =
            values[component.parameterId] ?? parameters[component.parameterId];
        if (component.componentType === "NumberInput") {
            const dependentMaxValue = values[component.maxId ?? ""];

            const dependentMinValue = values[component.minId ?? ""];

            return (
                <NewNumberInput
                    data={component}
                    onChange={handleChange}
                    componentValue={componentValue}
                    dependentMaxValue={dependentMaxValue}
                    dependentMinValue={dependentMinValue}
                    resetErrorOnUnmount
                />
            );
        }
        if (component.componentType === "TextBox") {
            return (
                <NewTextBox
                    data={component}
                    onChange={handleChange}
                    componentValue={componentValue}
                    resetErrorOnUnmount
                />
            );
        }
        if (component.componentType === "Collection") {
            if (component.localizationKey?.includes("source")) {
                return (
                    <SourceDropdown
                        data={sourceDataWithCollectionItems}
                        onChange={handleSourceChange}
                    />
                );
            }
            return (
                <NewCollection
                    data={component}
                    onChange={({ id, value }: { id: number; value: string }) =>
                        handleChange({ id, value }, component)
                    }
                    componentValue={componentValue}
                />
            );
        }
    };

    const renderContent = () => {
        return (
            <div
                css={css({
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                })}
            >
                <BannerView
                    data={{
                        bannerType: "Info",
                        description: t.TriggerNotificationBanner,
                    }}
                />
                {objectValuesArray.map((value) =>
                    renderDynamicComponent(value),
                )}
                {componentsOfTrigger.map((value) => {
                    return renderDynamicComponent(value);
                })}
            </div>
        );
    };

    return (
        <SharedFormModal
            data={data}
            sourceId={sourceId}
            values={values}
            submitLoading={submitLoading}
            hasErrors={hasErrors}
            isEdit={isEdit}
            isOpen={isOpen}
            handleSubmit={handleSubmit}
            handleClose={onClose}
            renderContent={renderContent}
        />
    );
};

export default TriggerModal;
