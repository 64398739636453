/** @jsxRuntime classic */

/** @jsx jsx */
import { Fragment, useContext } from "react";

import { ColumnInstance } from "react-table";

import { css, jsx } from "@emotion/react";

import parse from "html-react-parser";

import { IoTableColumns } from "../../../../../../../constants/constants";
import SettingsContext from "../../../../../../../context/settings/settingsContext";
import ThemeContext from "../../../../../../../context/theme/themeContext";
import Tag from "../../../../../../MuiComponents/Tag";
import Tooltip from "../../../../../../MuiComponents/Tooltip";

import TableSwitchInput from "./TableSwitchInput";

export interface DescriptionWithSwitchProps {
    row: any;
    onChange: (row: any) => void;
    allColumns?: ColumnInstance[];
    otherProps?: {
        index: number;
        data: any;
    };
    isGenericTable?: boolean;
}

const DescriptionWithSwitch = ({
    row,
    onChange,
    allColumns,
    otherProps,
    isGenericTable,
}: DescriptionWithSwitchProps) => {
    const {
        colors: { white },
    } = useContext(ThemeContext);
    const { isParameterIdsHidden, isExplanatoryTextsHidden } =
        useContext(SettingsContext);

    let keySeed = 1;

    const getIoItemNameElement = (): React.JSX.Element => {
        const avlId = row.original.avlId;
        return (
            <Fragment>
                <div key={(keySeed++).toString()}>{row.original.name}</div>
                {avlId ? (
                    <div key={(keySeed++).toString()}>AVL ID: {avlId}</div>
                ) : null}
            </Fragment>
        );
    };

    const getColumnName = (item: ColumnInstance) => {
        if (
            (isGenericTable || item.id !== IoTableColumns.name) &&
            item.isVisible
        ) {
            return item.id;
        }

        return "";
    };

    const ioPropertyNames =
        allColumns?.map((item) => getColumnName(item)).filter(Boolean) || [];

    const lastPropertyName = ioPropertyNames[ioPropertyNames.length - 1];

    const getPartTooltipLines = (propertyName: string): string[] => {
        const parameter =
            isGenericTable && otherProps?.data
                ? otherProps?.data[otherProps.index][propertyName]
                : row.original[propertyName];

        if (parameter) {
            let foundCell = allColumns?.find(
                (column: any) => column.id === propertyName,
            );
            if (foundCell)
                return [
                    `${parameter?.switchComponent?.label || foundCell.Header}:`,
                    `ID: ${parameter?.switchComponent?.parameterId || parameter.parameterId}`,
                ];
        }

        return [];
    };

    // Creates a tooltip title element for IO row
    const getTooltipTitleElement = (): JSX.Element => {
        return (
            <Fragment>
                {getIoItemNameElement()}
                <hr key={(keySeed++).toString()} />
                {ioPropertyNames.map((propertyName) => {
                    const tooltipLines = getPartTooltipLines(propertyName);
                    const divs = tooltipLines.map((line) => (
                        <div key={(keySeed++).toString()}>{line}</div>
                    ));
                    if (divs.length > 0 && propertyName !== lastPropertyName) {
                        divs.push(<hr key={(keySeed++).toString()} />);
                    }
                    return divs;
                })}
            </Fragment>
        );
    };

    return (
        <div
            css={css({
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor: white,
                width: "100%",
            })}
        >
            <div
                css={css({
                    display: "flex",
                    alignItems: "center",
                    maxWidth: "calc(100% - 60px)", // Leave space for the ID tag
                })}
            >
                <div css={css({ marginRight: "28px", flexShrink: 0 })}>
                    <TableSwitchInput
                        id={row.original.parameterValue.id}
                        label={String(row.original.parameterValue.id)}
                        onChange={onChange}
                    />
                </div>
                <div css={css({ minWidth: 0 })}>
                    <div
                        css={css({
                            fontWeight: 600,
                            fontSize: "14px",
                            lineHeight: "20px",
                            letterSpacing: "0.1px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        })}
                    >
                        {row.original.name}
                    </div>
                    {!isExplanatoryTextsHidden && (
                        <Tooltip
                            title={row.original.description || ""}
                            placement="bottom"
                            small
                        >
                            <div
                                css={css({
                                    fontWeight: 600,
                                    fontSize: "12px",
                                    lineHeight: "16px",
                                    letterSpacing: "0.4px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    cursor: "pointer",
                                    height: "16px",
                                })}
                            >
                                {parse(row.original.description || "")}
                            </div>
                        </Tooltip>
                    )}
                </div>
            </div>
            {!isParameterIdsHidden && (
                <Tooltip title={getTooltipTitleElement()} small placement="top">
                    <span>
                        <Tag size="tiny" color="white" title="ID" />
                    </span>
                </Tooltip>
            )}
        </div>
    );
};

export default DescriptionWithSwitch;
