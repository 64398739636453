/** @jsxRuntime classic */

/** @jsx jsx */
import { useContext, useState } from "react";

import { css, jsx } from "@emotion/react";

import {
    IoTableColumnTitles,
    IoTableColumns,
} from "../../../../../../../constants/constants";
import MenuItemContext from "../../../../../../../context/menuItem/menuItemContext";
import ThemeContext from "../../../../../../../context/theme/themeContext";
import { useMobileTableCards } from "../hooks/useMobileTableCards";
import { MobileTableCardCanProps } from "../types";

import DataMask from "./DataMask";
import TableCollection from "./TableCollection";
import TableInput from "./TableInput";
import TableSwitchInput, { TableSwitchDefaultValue } from "./TableSwitchInput";

const MobileTableCard = ({ row }: MobileTableCardCanProps) => {
    const {
        colors: { gray700, blue100, white, textDarkDisabled },
        boxShadows: { blueBoxShadow, ioTableCardBoxShadow },
    } = useContext(ThemeContext);

    const { findParameterValueById } = useContext(MenuItemContext);

    const [focusedRow, setFocusedRow] = useState("");

    const [rowsWithErrors, setRowsWithErrors] = useState<string[]>([]);

    const {
        renderCellBorder,
        renderCellTitle,
        returnOperandRow,
        returnPriorityRow,
    } = useMobileTableCards(row, focusedRow, rowsWithErrors);

    const isInputDisabled =
        findParameterValueById(row.parameterValue.id) ===
        TableSwitchDefaultValue.OFF;

    const handleFocus = (isOpen: boolean, colName: string) => {
        setFocusedRow(isOpen ? colName : "");
    };

    const handleError = (error: string, colName: string) => {
        setRowsWithErrors((prev) => {
            if (error) {
                if (!prev.includes(colName)) {
                    return [...prev, colName];
                }
                return prev;
            } else {
                return prev.filter((rowName) => rowName !== colName);
            }
        });
    };

    return (
        <div
            css={css({
                marginBottom: "8px",
                filter: "drop-shadow(0px 1px 4px rgba(15, 23, 42, 0.12))",
                fontWeight: 600,
                marginLeft: "1px",
                marginRight: "1px",
            })}
        >
            <div
                css={css({
                    backgroundColor: blue100,
                    padding: "12px 16px",
                    boxShadow: blueBoxShadow,
                })}
            >
                {row.name}
            </div>
            <div
                css={css({
                    display: "flex",
                    alignItems: "center",
                    padding: "8px 16px",
                    justifyContent: "space-between",
                    backgroundColor: white,
                    boxShadow: ioTableCardBoxShadow,
                })}
            >
                <div>
                    {renderCellTitle(IoTableColumnTitles.name)}
                    <div
                        css={css({
                            marginBotom: "6px",
                            fontWeight: 600,
                            fontSize: "14px",
                            lineHeight: "20px",
                            letterSpacing: "0.1px",
                            color: isInputDisabled ? textDarkDisabled : gray700,
                        })}
                    >
                        {row.name}
                    </div>
                    <div
                        css={css({
                            fontWeight: 600,
                            fontSize: "12px",
                            lineHeight: "16px",
                            letterSpacing: "0.4px",
                        })}
                    >
                        {row.description}
                    </div>
                </div>
                <div>
                    <TableSwitchInput
                        id={row.parameterValue.id}
                        label={row.parameterValue.id}
                    />
                </div>
            </div>

            {returnPriorityRow(handleFocus)}

            <div
                css={css({
                    padding: "12px 16px",
                    backgroundColor: white,
                    boxShadow: ioTableCardBoxShadow,
                    border: renderCellBorder(IoTableColumns.canType),
                })}
            >
                {renderCellTitle(IoTableColumnTitles.canType)}
                <div css={css({ fontSize: "14px" })}>
                    <TableCollection
                        data={row.canType}
                        switchId={row.parameterValue.id}
                        onFocus={(isOpen: boolean) =>
                            handleFocus(isOpen, IoTableColumns.canType)
                        }
                        anchorPositionHorizontal={"left"}
                        slotProps={{
                            paper: {
                                style: {
                                    marginLeft: "8px",
                                    marginTop: "13px",
                                },
                            },
                        }}
                        hasNoInputFocus
                        hasNoInputDisabledBackground
                    />
                </div>
            </div>
            <div
                css={css({
                    padding: "12px 16px",
                    backgroundColor: white,
                    boxShadow: ioTableCardBoxShadow,
                    border: renderCellBorder(IoTableColumns.canId),
                })}
            >
                {renderCellTitle(IoTableColumnTitles.canId)}
                <div css={css({ fontSize: "14px" })}>
                    {row.canId ? (
                        <TableInput
                            data={row.canId}
                            switchId={row.parameterValue.id}
                            onFocus={(isFocused) =>
                                handleFocus(isFocused, IoTableColumns.canId)
                            }
                            onError={(error) =>
                                handleError(error, IoTableColumns.canId)
                            }
                            hasNoInputFocus
                            hasNoInputErrorBorder
                            hasNoInputDisabledBackground
                        />
                    ) : null}
                </div>
            </div>
            <div
                css={css({
                    padding: "12px 16px",
                    backgroundColor: white,
                    boxShadow: ioTableCardBoxShadow,
                    border: renderCellBorder(IoTableColumns.dataMask),
                })}
            >
                {renderCellTitle(IoTableColumnTitles.dataMask)}
                <DataMask
                    switchId={row.parameterValue.id}
                    data={row.dataMask}
                />
            </div>

            {returnOperandRow(handleFocus)}
        </div>
    );
};

export default MobileTableCard;
