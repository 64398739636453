/** @jsxRuntime classic */

/** @jsx jsx */
import { MouseEvent, useContext } from "react";

import { css, jsx } from "@emotion/react";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";

import LanguageContext from "../../../context/language/languageContext";
import Tooltip from "../Tooltip";

interface ShowPasswordIconProps {
    passwordShow: boolean;
    handleMouseDown: (e: MouseEvent<HTMLButtonElement>) => void;
}

const ShowPasswordIcon = ({
    passwordShow,
    handleMouseDown,
}: ShowPasswordIconProps) => {
    const { t } = useContext(LanguageContext);

    return (
        <Tooltip
            title={passwordShow ? t.HidePassword : t.ShowPassword}
            placement="top-end"
            css={css({
                "& .MuiTooltip-tooltip": {
                    marginRight: "-6px",
                },
            })}
        >
            <IconButton
                disableRipple
                onMouseDown={handleMouseDown}
                size="large"
            >
                {passwordShow ? <VisibilityOff /> : <Visibility />}
            </IconButton>
        </Tooltip>
    );
};

export default ShowPasswordIcon;
