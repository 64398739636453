/** @jsxRuntime classic */

/** @jsx jsx */
import { ChangeEvent, MouseEvent, useContext, useReducer, useRef } from "react";

import { css, jsx } from "@emotion/react";

import {
    AlertStatus,
    HttpStatusCodes,
} from "../../../../../../../constants/constants";
import AlertContext from "../../../../../../../context/alert/alertContext";
import LanguageContext from "../../../../../../../context/language/languageContext";
import LayoutContext from "../../../../../../../context/layout/layoutContext";
import MenuItemContext from "../../../../../../../context/menuItem/menuItemContext";
import {
    convertAxiosErrorToErrorDetails,
    noop,
} from "../../../../../../../utils/helpers";
import { DeviceFamily } from "../../../../../../../utils/types";
import useApi from "../../../../../../../utils/useApi";
import useTct from "../../../../../../../utils/useTct";
import InputField from "../../../../../../MuiComponents/InputField";
import PasswordModal from "../../../../../../MuiComponents/Modals/PasswordModal";
import ShowPasswordIcon from "../../../../../../MuiComponents/SharedComponents/ShowPasswordIcon";
import PasswordValidation from "../PasswordValidation";
import { Data } from "../index";

import {
    HANDLE_CHANGE_PASSWORD,
    HANDLE_DISABLE,
    HANDLE_ERROR,
    HANDLE_VISIBILITY,
} from "./setPasswordActions";
import setPasswordReducer from "./setPasswordReducer";

interface SetPasswordProps {
    isOpen: boolean;
    close: () => void;
    togglePassword: (value: boolean) => void;
    data: Data;
    isChangePassword?: boolean;
    isRecoveryPassword?: boolean;
}

const SetPassword = ({
    isOpen,
    close,
    togglePassword,
    data,
    isChangePassword,
    isRecoveryPassword,
}: SetPasswordProps) => {
    const {
        component: { parameterId, label },
    } = data;

    const { t } = useContext(LanguageContext);
    const { setAlert } = useContext(AlertContext);
    const { layoutData } = useContext(LayoutContext);
    const { updateParameter, parameters } = useContext(MenuItemContext);

    const { isAxiosError } = useApi();
    const { validateParameterAsync } = useTct();

    const initialState = {
        isLoading: false,
        isDisabled: true,
        password: "",
        passwordRepeat: "",
        passwordShow: false,
        passwordRepeatShow: false,
        currentPassword: "",
        currentPasswordShow: false,
        currentPasswordError: null,
    };

    const [state, dispatch] = useReducer(setPasswordReducer, initialState);

    const elementRef = useRef(null);

    const {
        isLoading,
        isDisabled,
        password,
        passwordRepeat,
        passwordShow,
        passwordRepeatShow,
        currentPassword,
        currentPasswordShow,
        currentPasswordError,
    } = state;

    const handleChange = (e: ChangeEvent<HTMLInputElement>) =>
        dispatch({
            type: HANDLE_CHANGE_PASSWORD,
            payload: {
                name: e.target.name,
                value: e.target.value,
            },
        });

    const handleToggleVisibility = (
        e: MouseEvent<HTMLButtonElement>,
        name: "passwordShow" | "passwordRepeatShow" | "currentPasswordShow",
    ) => {
        dispatch({ type: HANDLE_VISIBILITY, payload: name });
        e.preventDefault();
    };

    const handleRulesMet = (value: boolean) =>
        dispatch({ type: HANDLE_DISABLE, payload: value });

    const handleCurrentPasswordError = (value: string | null) =>
        dispatch({ type: HANDLE_ERROR, payload: value });

    const handleClose = () => {
        handleCurrentPasswordError(null);
        close();
    };

    const currentPasswordValue = parameters[parameterId];

    const shouldValidatePasswordWithDevice = () =>
        isChangePassword && isFtcDeviceFamily && layoutData?.isOnline;
    const shouldValidatePasswordLocally = () =>
        isChangePassword && (!isFtcDeviceFamily || !layoutData?.isOnline);

    const sendPayload = async () => {
        if (
            shouldValidatePasswordLocally() &&
            currentPasswordValue !== currentPassword
        ) {
            handleCurrentPasswordError("Wrong password");
            return;
        }
        try {
            //check if passwords are correct on device
            if (shouldValidatePasswordWithDevice()) {
                try {
                    await validateParameterAsync(
                        layoutData!.id,
                        parameterId,
                        currentPassword,
                    );
                } catch (err: any) {
                    if (
                        err.response.status !=
                        HttpStatusCodes.UnprocessableEntity
                    ) {
                        const error = convertAxiosErrorToErrorDetails(err);
                        handleCurrentPasswordError(error.description);
                        return;
                    }
                }
                handleCurrentPasswordError("");
            }

            updateParameter(
                parameterId,
                password,
                label,
                elementRef,
                () => {
                    setAlert(
                        AlertStatus.Success,
                        isChangePassword ? t.PasswordChanged : t.PasswordSet,
                    );

                    !isChangePassword && togglePassword(true);
                    handleClose();
                },
                (error: any) => {
                    if (isAxiosError(error) && error.response) {
                        const errorMsg = error.response.data;

                        setAlert(AlertStatus.Critical, errorMsg);
                    }
                    handleClose();
                },
                null,
                true,
            );
        } catch (err: any) {
            const error = err.response?.data;
            setAlert(AlertStatus.Critical, error.detail);
        }
    };

    const isFtcDeviceFamily =
        layoutData?.deviceFamily &&
        (layoutData?.deviceFamily as unknown) === DeviceFamily.FTC;

    const renderTitle = () => {
        if (isChangePassword) {
            const changeTitleBasedOnDeviceGroup = isFtcDeviceFamily
                ? t.ChangePrimaryPassword
                : t.ChangePassword;

            return isRecoveryPassword
                ? t.ChangePasswordRecovery
                : changeTitleBasedOnDeviceGroup;
        } else {
            const setTitleBasedOnDeviceGroup = isFtcDeviceFamily
                ? t.SetPasswordPrimary
                : t.SetPassword;

            return isRecoveryPassword
                ? t.SetPasswordRecovery
                : setTitleBasedOnDeviceGroup;
        }
    };

    const renderLabel = () => {
        if (isRecoveryPassword) {
            return "Enter current recovery password";
        }
        return isFtcDeviceFamily
            ? t.EnterCurrentPrimaryPassword
            : t.EnterCurrentPassword;
    };
    return (
        <PasswordModal
            isOpen={isOpen}
            isDisabled={isDisabled}
            isLoading={isLoading}
            title={renderTitle()}
            submitBtnText={isChangePassword ? t.Change : t.Set}
            close={handleClose}
            sendPayload={sendPayload}
        >
            {isChangePassword && (
                <InputField
                    fullWidth
                    size="medium"
                    label={renderLabel()}
                    placeholder={t.EnterHere}
                    type={currentPasswordShow ? "text" : "password"}
                    name="currentPassword"
                    onChange={handleChange}
                    error={currentPasswordError}
                    onFocus={(e) =>
                        currentPasswordError
                            ? handleCurrentPasswordError(null)
                            : noop()
                    }
                    wrapperStyle={css({
                        marginBottom: "16px",
                    })}
                    iconRight={
                        <ShowPasswordIcon
                            passwordShow={currentPasswordShow}
                            handleMouseDown={(e) =>
                                handleToggleVisibility(e, "currentPasswordShow")
                            }
                        />
                    }
                />
            )}
            <InputField
                fullWidth
                size="medium"
                label={isChangePassword ? t.EnterNewPassword : t.EnterPassword}
                placeholder={t.EnterHere}
                type={passwordShow ? "text" : "password"}
                name="password"
                onChange={handleChange}
                iconRight={
                    <ShowPasswordIcon
                        passwordShow={passwordShow}
                        handleMouseDown={(e) =>
                            handleToggleVisibility(e, "passwordShow")
                        }
                    />
                }
            />

            <InputField
                fullWidth
                size="medium"
                label={
                    isChangePassword ? t.RepeatNewPassword : t.RepeatPassword
                }
                placeholder={t.EnterHere}
                type={passwordRepeatShow ? "text" : "password"}
                name="passwordRepeat"
                onChange={handleChange}
                iconRight={
                    <ShowPasswordIcon
                        passwordShow={passwordRepeatShow}
                        handleMouseDown={(e) =>
                            handleToggleVisibility(e, "passwordRepeatShow")
                        }
                    />
                }
                wrapperStyle={css({
                    margin: "16px 0 24px 0",
                })}
            />

            <PasswordValidation
                ruleTitles={[
                    isFtcDeviceFamily ? t.Rule1FCT : t.Rule1,
                    t.Rule2,
                    t.Rule3,
                ]}
                currentPassword={isChangePassword ? currentPassword : undefined}
                password={password}
                passwordRepeat={passwordRepeat}
                onRulesMet={handleRulesMet}
                isFtcDevice={!!isFtcDeviceFamily}
            />
        </PasswordModal>
    );
};

export default SetPassword;
