/** @jsxRuntime classic */

/** @jsx jsx */
import { useContext, useEffect } from "react";

import { jsx } from "@emotion/react";

import MenuItemContext from "../../../../../context/menuItem/menuItemContext";
import { CustomScenario as CustomScenarioData } from "../../../../../generatedTypes";

import TriggerInput from "./components/TriggerInput";
import TriggerContext from "./context/triggerContext";

export interface CustomScenarioProps {
    data: CustomScenarioData;
}

const CustomScenario: React.FunctionComponent<CustomScenarioProps> = ({
    data,
}) => {
    const { triggers, ioCollection } = data;

    const { parameters, componentVisibilities } = useContext(MenuItemContext);
    const { setSourceCollection } = useContext(TriggerContext);

    const triggersSourceValues = triggers.reduce<Record<string, string>>(
        (acc, trigger) => {
            if (trigger.source?.parameterId && trigger.source?.parameterValue) {
                acc[trigger.source.parameterId] = trigger.source.parameterValue;
            }
            return acc;
        },
        {},
    );

    const visibleTriggers = triggers.filter((trigger, index) => {
        return (
            index === 0 || parameters[trigger.source?.parameterId || ""] !== "0"
        );
    });

    useEffect(() => {
        setSourceCollection(ioCollection, triggersSourceValues);

        return () => {
            setSourceCollection({}, {});
        };
    }, []);

    return (
        <div>
            {triggers.map((trigger, index) => {
                const { lowLevel, highLevel, ...rest } = trigger;
                const isTriggerHidden = () => {
                    const isHidden = componentVisibilities?.find(
                        (item: {
                            uiName: string;
                            isHidden: boolean;
                            dependenceName: string;
                        }) => trigger.dependenceName === item.uiName,
                    )?.isHidden;

                    if (isHidden === undefined) {
                        return trigger.isHidden;
                    }
                    return isHidden;
                };

                if (isTriggerHidden()) {
                    return null;
                }

                const canBeRemoved = () => {
                    if (trigger.switchHidden) {
                        return true;
                    }
                    return index === visibleTriggers.length - 1;
                };

                const triggerData = {
                    ...rest,
                    lowLevel,
                    highLevel,
                };

                return (
                    <TriggerInput
                        key={trigger?.name ?? ""}
                        data={triggerData}
                        canBeRemoved={canBeRemoved()}
                        index={index}
                    />
                );
            })}
        </div>
    );
};

export default CustomScenario;
