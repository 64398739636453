/** @jsxRuntime classic */

/** @jsx jsx */
import { Fragment, useContext, useState } from "react";

import { css, jsx } from "@emotion/react";

import {
    InputLabel,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Popover,
} from "@mui/material";

import ColumnIcon from "../../../../../../../assets/icons/ColumnIcon";
import { IoTableColumns } from "../../../../../../../constants/constants";
import LanguageContext from "../../../../../../../context/language/languageContext";
import ThemeContext from "../../../../../../../context/theme/themeContext";
import Checkbox from "../../../../../../MuiComponents/Checkbox";
import Divider from "../../../../../../MuiComponents/Divider";
import LengthBubble from "../../../../../../MuiComponents/LengthBubble";

export interface Column {
    name: string;
    value?: string;
    isDisabled?: boolean;
    isActive?: boolean;
    isVisibleInFilters?: boolean;
}

interface ColumnSelectProps {
    activeColumns: any[];
    onColumnChange: (columnName: string) => void;
    onReset: () => void;
}

const ColumnSelect = ({
    activeColumns: columns,
    onColumnChange,
    onReset,
}: ColumnSelectProps) => {
    const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLDivElement | null>(
        null,
    );

    const { t } = useContext(LanguageContext);
    const { colors } = useContext(ThemeContext);

    const hiddenSelectColumns = [
        IoTableColumns.name,
        IoTableColumns.currentValue,
        "0",
    ];

    const activeColumns = columns.map((item) => {
        return {
            isDisabled: false,
            name: item.Header,
            value: item.id,
            isActive: item.isVisible,
            isHidden: hiddenSelectColumns.includes(item.id),
        };
    });

    const filteredUnhiddenColumns = activeColumns.filter(
        (column) => column.isActive && !column.isHidden,
    );

    return (
        <Fragment>
            <InputLabel
                onClick={(e) => setMenuAnchorEl(e.currentTarget as any)}
                css={css({
                    position: "unset",
                    display: "inline-flex",
                    overflow: "unset",
                })}
            >
                <div
                    css={css({
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                        padding: "8px 12px",
                        cursor: "pointer",
                        position: "relative",
                        borderRadius: "6px",
                        "&:hover": {
                            backgroundColor: colors.gray100,
                        },
                        "&:active": {
                            backgroundColor: colors.gray200,
                        },
                    })}
                >
                    <LengthBubble bottomPosition={6} leftPosition={25}>
                        {filteredUnhiddenColumns.length}
                    </LengthBubble>
                    <ColumnIcon css={css({ width: "18px", height: "14px" })} />
                    <div
                        css={css({
                            marginLeft: "12px",
                            fontWeight: 600,
                            fontSize: "14px",
                            lineHeight: "20px",
                            letterSpacing: "0.1px",
                            color: colors.gray700,
                        })}
                    >
                        {t.ViewColumns}
                    </div>
                </div>
            </InputLabel>
            <Popover
                onClose={() => setMenuAnchorEl(null)}
                open={!!menuAnchorEl}
                anchorEl={menuAnchorEl}
                anchorPosition={{ top: 0, left: 0 }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                sx={{
                    "& .MuiPaper-root": {
                        maxWidth: "211px",
                        width: "100%",
                        marginTop: "8px",
                        maxHeight: "529px",
                        "&::-webkit-scrollbar": {
                            width: "18px",
                        },
                        "&::-webkit-scrollbar-thumb": {
                            backgroundColor: colors.blue200,
                            borderRadius: "100px",
                            border: `5px solid ${colors.white}`,
                            minHeight: "48px",
                        },
                        "&::-webkit-scrollbar-thumb:hover": {
                            backgroundColor: colors.blue400,
                        },
                    },
                }}
            >
                <Fragment>
                    <MenuItem
                        onClick={() => {
                            onReset();
                        }}
                        css={css({
                            color: colors.blue700,
                            padding: "14px 2px 14px 16px",
                        })}
                    >
                        {t.ResetToDefault}
                    </MenuItem>
                    <Divider />
                </Fragment>
                {activeColumns.map((column) => {
                    if (column.isHidden) {
                        return null;
                    }
                    return (
                        <MenuItem
                            key={column.name}
                            disabled={column.isDisabled}
                            css={css({ padding: "14px 2px 14px 16px" })}
                            onClick={() => {
                                onColumnChange(column.value);
                            }}
                        >
                            <ListItemText
                                primary={column.name}
                                sx={{
                                    ".MuiListItemText-primary": {
                                        fontWeight: "600",
                                        fontSize: "14px",
                                    },
                                }}
                            />
                            <ListItemIcon>
                                <Checkbox checked={column.isActive} />
                            </ListItemIcon>
                        </MenuItem>
                    );
                })}
            </Popover>
        </Fragment>
    );
};

export default ColumnSelect;
