import { useEffect } from "react";

const useHandleTableScroll = (
    listRef: React.RefObject<any>,
    headerRef: React.RefObject<HTMLElement>,
) => {
    useEffect(() => {
        let ticking = false;

        const handleScroll = () => {
            if (!ticking) {
                window.requestAnimationFrame(() => {
                    if (listRef.current?._outerRef && headerRef.current) {
                        headerRef.current.scrollLeft =
                            listRef.current._outerRef.scrollLeft;
                    }
                    ticking = false;
                });
                ticking = true;
            }
        };

        const outerRef = listRef.current?._outerRef;
        if (outerRef) {
            outerRef.addEventListener("scroll", handleScroll);
            return () => {
                outerRef.removeEventListener("scroll", handleScroll);
            };
        }
    }, [listRef.current, headerRef.current]);
};

export default useHandleTableScroll;
