import { useMemo } from "react";

import {
    TableInstance,
    TableOptions,
    UseTableRowProps,
    useFilters,
    useFlexLayout,
    useGlobalFilter,
    useRowState,
    useTable,
} from "react-table";

import { useAtom } from "jotai";

import { IoStatusAtom } from "../../../../../../../context/webSockets/webSocketsState";
import { useGenericTableColumns } from "../columns/genericTableColumns";

interface UseGenericTableProps {
    tableData: any[];
    dynamicTableColumns: any[];
}

interface UseGenericTableReturn {
    tableInstance: TableInstance<{ [key: string]: any }>;
}

export const useGenericTable = ({
    tableData,
    dynamicTableColumns,
}: UseGenericTableProps): UseGenericTableReturn => {
    const memoizedData = useMemo(() => tableData, []);

    const columns: any[] = useGenericTableColumns(dynamicTableColumns);

    const [currentValues] = useAtom(IoStatusAtom);

    const memoizedColumns = useMemo(() => columns, []);

    const hiddenDynamicColumns = columns
        .map((column) => (column.hiddenByDefault ? column.id : ""))
        .filter(Boolean);

    const tableInstance = useTable(
        {
            columns: memoizedColumns,
            data: memoizedData || [],
            initialState: {
                hiddenColumns: hiddenDynamicColumns,
            },
            globalFilter: (
                rows: UseTableRowProps<any>[],
                _: any[],
                value: string,
            ) => {
                return rows.filter((row) => {
                    const parameterName = row.original[0].name.toLowerCase();
                    const filterValue = value?.toLowerCase();
                    return parameterName?.includes(filterValue);
                });
            },
            useControlledState: (state: any) => {
                return useMemo(
                    () => ({
                        ...state,
                        currentValues,
                    }),
                    [state, currentValues],
                );
            },
        } as TableOptions<any>,
        useFlexLayout,
        useFilters,
        useGlobalFilter,
        useRowState,
    );

    return { tableInstance };
};
