/** @jsxRuntime classic */

/** @jsx jsx */
import { Fragment, useContext, useEffect } from "react";

import ReactGA from "react-ga4";

import { css, jsx } from "@emotion/react";

import ConfigurationContext from "../../context/configuration/configurationContext";
import DeviceAuthorizationState from "../../context/deviceAuthorization/deviceAuthorizationState";
import FeaturesContext from "../../context/features/featuresContext";
import UserInfoContext from "../../context/userInfo/userInfoContext";
import Container from "../Container";
import TopBar from "../TopBar/StartUpTopBar";

import ExperimentalFirmwareModal from "./ExperimentalFirmwareModal";
import Offline from "./Offline";
import Online from "./Online";

const StartUp = () => {
    const {
        isDesktopApplication,
        isDeviceDisconnected,
        setDeviceDisconnected,
        configuratorVersion,
        setVisitCount,
        isFirstTimeOnStartup,
    } = useContext(UserInfoContext);

    const { setEcoCalculatorValues } = useContext(FeaturesContext);
    const {
        isExperimentalModalOpen,
        getExperimentalConfigurations,
        setIsExperimentalModalOpen,
    } = useContext(ConfigurationContext);

    useEffect(() => {
        ReactGA.gtag("event", "tct_launch", {
            version: configuratorVersion,
        });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setEcoCalculatorValues(null);
        setVisitCount();
        if (isDeviceDisconnected) {
            setDeviceDisconnected(false);
        }
    }, []);

    useEffect(() => {
        const handleSuccess = (data: any[]) => {
            const defaultItem =
                data.find((item) => item.default === true) || false;

            if (defaultItem) {
                return () => {};
            } else {
                !!data.length && setIsExperimentalModalOpen(true, false);
            }
        };

        isFirstTimeOnStartup && getExperimentalConfigurations(handleSuccess);
    }, [isFirstTimeOnStartup]);

    return (
        <Fragment>
            <TopBar />

            <Container
                className="remaining-height"
                css={css({
                    padding: "24px",
                    "@media (max-width: 599px)": {
                        padding: "24px 16px",
                    },
                })}
            >
                {isDesktopApplication && (
                    <DeviceAuthorizationState>
                        <Online />
                    </DeviceAuthorizationState>
                )}
                <Offline />
            </Container>
            {isExperimentalModalOpen && <ExperimentalFirmwareModal />}
        </Fragment>
    );
};

export default StartUp;
