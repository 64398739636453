import { useContext, useMemo } from "react";

import {
    TableOptions,
    UseTableRowProps,
    useFilters,
    useFlexLayout,
    useGlobalFilter,
    useRowState,
    useTable,
} from "react-table";

import { useAtom } from "jotai";

import InputOutputContext from "../../../../../../../context/inputOutput/inputOutputContext";
import LayoutContext from "../../../../../../../context/layout/layoutContext";
import ThemeContext from "../../../../../../../context/theme/themeContext";
import { IoStatusAtom } from "../../../../../../../context/webSockets/webSocketsState";
import { getCanColumns } from "../columns/canColumns";
import { getIoTableColumns } from "../columns/ioTableColumns";

interface UseIoTableProps {
    name: string;
    data: any[];
    isCanTable?: boolean;
}

interface UseIoTableReturn {
    tableInstance: any;
    currentValues: Record<string, any>;
    layoutData: any;
    activeFilters: any[];
    activePriority: any;
    activeOperand: any;
    activeStatus: any;
    searchValue: string;
}

export const useIoTable = ({
    name,
    data,
    isCanTable = false,
}: UseIoTableProps): UseIoTableReturn => {
    const {
        colors: { gray100 },
    } = useContext(ThemeContext);

    const {
        activeFilters,
        activePriority,
        activeOperand,
        activeStatus,
        searchValue,
    } = useContext(InputOutputContext);

    const { layoutData } = useContext(LayoutContext);

    const [currentValues] = useAtom(IoStatusAtom);

    const memoizedData = useMemo(() => data, []);

    const columns = isCanTable
        ? getCanColumns(name, data)
        : getIoTableColumns(name, currentValues, gray100);

    const memoizedColumns = useMemo(() => columns, []);

    const tableInstance = useTable(
        {
            columns: memoizedColumns,
            data: memoizedData,
            initialState: {
                hiddenColumns: [!layoutData?.isOnline ? "currentValue" : ""],
            },
            globalFilter: (
                rows: UseTableRowProps<any>[],
                _: any[],
                value: string,
            ) => {
                return rows.filter((row) => {
                    const parameterName = row.original?.name?.toLowerCase();
                    const filterValue = value?.toLowerCase();
                    return parameterName?.includes(filterValue);
                });
            },
            useControlledState: (state: any) => {
                return useMemo(
                    () => ({
                        ...state,
                        currentValues,
                    }),
                    [state, currentValues],
                );
            },
        } as TableOptions<any>,
        useFlexLayout,
        useFilters,
        useGlobalFilter,
        useRowState,
    );

    return {
        tableInstance,
        currentValues,
        layoutData,
        activeFilters,
        activePriority,
        activeOperand,
        activeStatus,
        searchValue,
    };
};
