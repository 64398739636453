/** @jsxRuntime classic */

/** @jsx jsx */
import { useContext } from "react";

import { css, jsx } from "@emotion/react";

import MenuItemContext from "../../../../../../../context/menuItem/menuItemContext";
import { Component } from "../../../../../../../generatedTypes";
import Tag from "../../../../../../MuiComponents/Tag";
import Tooltip from "../../../../../../MuiComponents/Tooltip";
import { ConvertedCellValue } from "../types";

const useMobileScheduleTable = () => {
    const { findDisabledParameterById } = useContext(MenuItemContext);

    const renderMobileCellTitle = (title: string | undefined) => (
        <span
            css={css({
                fontSize: "10px",
                fontWeight: 600,
                lineHeight: "12px",
                letterSpacing: "1px",
                paddingBottom: "4px",
            })}
        >
            {title}
        </span>
    );

    const renderMobileHeader = (
        cell: any,
        rowValues: ConvertedCellValue[],
        renderRowIds: (rowValues: ConvertedCellValue[]) => string,
    ) => {
        return (
            <div
                css={css({
                    display: "flex",
                    justifyContent: "space-between",
                })}
            >
                <div
                    css={css({
                        display: "flex",
                        flexDirection: "column",

                        "> span:last-of-type": {
                            fontSize: "14px",
                            letterSpacing: "0.1px",
                            fontWeight: 600,
                        },
                    })}
                >
                    {renderMobileCellTitle(cell.columnLocalizationKey)}
                    <span>{cell.name}</span>
                </div>

                <div
                    css={css({
                        display: "flex",
                        alignItems: "center",
                        padding: "6px 0",
                        marginLeft: "auto",
                    })}
                >
                    <Tooltip
                        title={renderRowIds(rowValues)}
                        small
                        keepListenerEnabled
                        placement="top"
                    >
                        <span>
                            <Tag size="tiny" color="white" title="ID" />
                        </span>
                    </Tooltip>
                </div>
            </div>
        );
    };

    const checkIfCellCanBeDisplayed = (
        colIndex: number,
        cell: Component,
        otherCell: Component | undefined,
    ) => {
        return (
            colIndex === 1 ||
            cell.parameterId === 0 ||
            (otherCell && !findDisabledParameterById(otherCell.parameterId))
        );
    };

    const checkIfNextCellIsDisabled = (
        cell: Component,
        nextCell: Component,
    ) => {
        return (
            nextCell &&
            findDisabledParameterById(nextCell?.parameterId) &&
            findDisabledParameterById(cell?.parameterId)
        );
    };

    const checkIfCellExists = (tableRow: Component[], index: number) =>
        tableRow[index] ?? undefined;

    return {
        checkIfCellCanBeDisplayed,
        checkIfCellExists,
        checkIfNextCellIsDisabled,
        renderMobileCellTitle,
        renderMobileHeader,
    };
};

export default useMobileScheduleTable;
