/** @jsxRuntime classic */

/** @jsx jsx */
import { ColumnInstance, Row } from "react-table";

import { jsx } from "@emotion/react";

import {
    IoTableColumnTitles,
    NOT_AVAILABLE_VALUE,
} from "../../../../../../../constants/constants";
import { Component } from "../../../../../../../generatedTypes";
import CurrentValue from "../components/CurrentValue";
import DataMask from "../components/DataMask";
import DescriptionWithSwitch from "../components/DescriptionWithSwitch";
import TableCollection from "../components/TableCollection";
import TableInput from "../components/TableInput";

const renderComponent = (
    cellData: any,
    switchComponent: any,
    row: any,
    allColumns: ColumnInstance<{}>[],
    props: any,
) => {
    const formatDataForSwitch = {
        original: {
            ...cellData,
            parameterValue: {
                ...switchComponent,
                id: switchComponent?.parameterId || "",
            },
        },
    };

    const componentMap: {
        [key: string]: () => React.JSX.Element | string;
    } = {
        Label: () => {
            const filteredColumns = allColumns
                .filter(
                    (column) =>
                        column.Header !== IoTableColumnTitles.currentValue,
                )
                .slice(1);

            return (
                <DescriptionWithSwitch
                    isGenericTable
                    otherProps={{
                        index: Number(row.id),
                        data: props.data,
                    }}
                    row={formatDataForSwitch}
                    allColumns={filteredColumns}
                    onChange={(e: boolean) => handleLabelChange(e, row)}
                />
            );
        },
        TextBox: () => (
            <TableInput
                switchId={switchComponent.parameterId}
                data={cellData}
                key={cellData.parameterId}
                inputType="text"
            />
        ),
        NumberInput: () => (
            <TableInput
                switchId={switchComponent.parameterId}
                data={cellData}
                key={cellData.parameterId}
                inputType="number"
            />
        ),
        Collection: () => (
            <TableCollection
                data={cellData}
                switchId={switchComponent.parameterId}
                key={switchComponent.parameterId}
                onOptionClick={(e) => handleCollectionClick(e, row)}
            />
        ),
        DataMask: () => (
            <DataMask
                data={cellData}
                switchId={formatDataForSwitch.original.parameterValue.id}
            />
        ),
        ValueMonitor: () => (
            <CurrentValue
                props={props}
                formatDataForSwitch={formatDataForSwitch}
            />
        ),
        None: () => <div />,
    };

    return componentMap[cellData.componentType]?.() || NOT_AVAILABLE_VALUE;
};

const handleLabelChange = (e: boolean, row: any) => {
    row.setState((state: any) => ({
        cellState: {
            ...state.cellState,
            disabled: !e,
            priorityItem: !e ? "0" : "1",
        },
    }));
};

const handleCollectionClick = (e: any, row: any) => {
    row.setState((state: any) => ({
        cellState: {
            ...state?.cellState,
            priorityItem: e,
        },
    }));
};

const filterRows = (row: any, filterValue: string) => {
    if (row.state?.cellState?.disabled !== undefined) {
        const disabledValue = row.state.cellState.disabled ? "0" : "1";
        return disabledValue === filterValue;
    }
    return row.original[0].switchComponent.parameterValue === filterValue;
};

export const useGenericTableColumns = (columns: Component[]) => {
    const getWidth = (index: number, columnLabel: string | undefined) => {
        if (columnLabel === IoTableColumnTitles.dataMask) {
            return 340;
        }

        return index === 0 ? 300 : 150;
    };

    return columns.map((columnData: Component, index: number) => ({
        id: String(index),
        Header: columnData.name ?? "",
        width: getWidth(index, columnData.label),
        headerTooltip: columnData.tooltip,
        hiddenByDefault: columnData?.hiddenByDefault,
        Cell: ({ row, allColumns, ...props }: any) => {
            const switchComponent = row.original[0].switchComponent;
            const cellData = row.original[index];
            return renderComponent(
                cellData,
                switchComponent,
                row,
                allColumns,
                props,
            );
        },
        filter: (allRows: Row<any>[], _: any[], filterValue: string) =>
            allRows.filter((row) => filterRows(row, filterValue)),
    }));
};
