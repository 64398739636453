/** @jsxRuntime classic */

/** @jsx jsx */
import { ChangeEvent, MouseEvent, useContext, useReducer } from "react";

import { useHistory } from "react-router-dom";

import { css, jsx } from "@emotion/react";

import { AlertStatus } from "../../../../../../constants/constants";
import { START_UP } from "../../../../../../constants/routes";
import AlertContext from "../../../../../../context/alert/alertContext";
import LanguageContext from "../../../../../../context/language/languageContext";
import useApi from "../../../../../../utils/useApi";
import PasswordValidation from "../../../../../Editor/MenuItemView/Segment/PasswordBox/PasswordBoxModal/PasswordValidation";
import InputField from "../../../../../MuiComponents/InputField";
import PasswordModal from "../../../../../MuiComponents/Modals/PasswordModal";
import ShowPasswordIcon from "../../../../../MuiComponents/SharedComponents/ShowPasswordIcon";

import {
    HANDLE_CHANGE_PASSWORD,
    HANDLE_DISABLE,
    HANDLE_VISIBILITY,
} from "./setNewPasswordActions";
import setNewPasswordReducer from "./setNewPasswordReducer";

interface SetNewPasswordProps {
    isOpen: boolean;
    close: () => void;
    connectAction: (redirect: boolean) => void;
    shouldRedirectToStartupOnCancel?: boolean;
}

const SetNewPassword = ({
    isOpen,
    close,
    connectAction,
    shouldRedirectToStartupOnCancel,
}: SetNewPasswordProps) => {
    const { t, selectedLanguage } = useContext(LanguageContext);
    const { setAlert } = useContext(AlertContext);

    const { isAxiosError, postData, disconnectFromDevice } = useApi();
    const history = useHistory();

    const initialState = {
        isLoading: false,
        isDisabled: true,
        password: "",
        passwordRepeat: "",
        passwordShow: false,
        passwordRepeatShow: false,
    };

    const [state, dispatch] = useReducer(setNewPasswordReducer, initialState);

    const {
        isLoading,
        isDisabled,
        password,
        passwordRepeat,
        passwordShow,
        passwordRepeatShow,
    } = state;

    const handleChange = (e: ChangeEvent<HTMLInputElement>) =>
        dispatch({
            type: HANDLE_CHANGE_PASSWORD,
            payload: {
                name: e.target.name,
                value: e.target.value,
            },
        });

    const handleToggleVisibility = (
        e: MouseEvent<HTMLButtonElement>,
        name: "passwordShow" | "passwordRepeatShow",
    ) => {
        dispatch({ type: HANDLE_VISIBILITY, payload: name });
        e.preventDefault();
    };

    const handleRulesMet = (value: boolean) =>
        dispatch({ type: HANDLE_DISABLE, payload: value });

    const sendPayload = async () => {
        try {
            await postData(
                `${selectedLanguage}/command/setpassword`,
                JSON.stringify(password),
            );
            connectAction(true);
        } catch (err) {
            if (isAxiosError(err) && (err as any).response) {
                const errorMsg = (err as any).response.data;

                setAlert(AlertStatus.Critical, errorMsg);
            }
        }
    };

    const handleClose = async () => {
        await disconnectFromDevice(selectedLanguage, true);
        close();
        shouldRedirectToStartupOnCancel && history.replace(START_UP);
    };

    return (
        <PasswordModal
            isOpen={isOpen}
            isDisabled={isDisabled}
            isLoading={isLoading}
            title={"Set new primary password"}
            submitBtnText={t.Set}
            close={handleClose}
            sendPayload={sendPayload}
            description={
                "The primary password was disabled due to recovery password entering. Please set a new primary password."
            }
        >
            <InputField
                fullWidth
                size="medium"
                label={t.EnterNewPassword}
                placeholder={t.EnterHere}
                type={passwordShow ? "text" : "password"}
                name="password"
                onChange={handleChange}
                iconRight={
                    <ShowPasswordIcon
                        passwordShow={passwordShow}
                        handleMouseDown={(e) =>
                            handleToggleVisibility(e, "passwordShow")
                        }
                    />
                }
            />

            <InputField
                fullWidth
                size="medium"
                label={t.RepeatNewPassword}
                placeholder={t.EnterHere}
                type={passwordRepeatShow ? "text" : "password"}
                name="passwordRepeat"
                onChange={handleChange}
                iconRight={
                    <ShowPasswordIcon
                        passwordShow={passwordRepeatShow}
                        handleMouseDown={(e) =>
                            handleToggleVisibility(e, "passwordRepeatShow")
                        }
                    />
                }
                wrapperStyle={css({
                    margin: "16px 0 24px 0",
                })}
            />

            <PasswordValidation
                ruleTitles={[t.Rule1FCT, t.Rule2, t.Rule3]}
                password={password}
                passwordRepeat={passwordRepeat}
                onRulesMet={handleRulesMet}
                isFtcDevice
            />
        </PasswordModal>
    );
};

export default SetNewPassword;
