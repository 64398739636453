/** @jsxRuntime classic */

/** @jsx jsx */
import { Fragment, useContext, useEffect, useRef, useState } from "react";

import { useHistory } from "react-router-dom";

import { css, jsx } from "@emotion/react";

import CircularProgress from "@mui/material/CircularProgress";

import { useSetAtom } from "jotai";

import { ReactComponent as OfflineStatusIcon } from "../../../../assets/OfflineStatus.svg";
import { ReactComponent as OnlineStatusIcon } from "../../../../assets/OnlineStatus.svg";
import { NOT_AVAILABLE_VALUE } from "../../../../constants/constants";
import { START_UP } from "../../../../constants/routes";
import AppTypeContext from "../../../../context/appType/appTypeContext";
import LanguageContext from "../../../../context/language/languageContext";
import LayoutContext from "../../../../context/layout/layoutContext";
import ThemeContext from "../../../../context/theme/themeContext";
import UserInfoContext from "../../../../context/userInfo/userInfoContext";
import { IoStatusAtom } from "../../../../context/webSockets/webSocketsState";
import useApi from "../../../../utils/useApi";
import useMediaQueries from "../../../../utils/useMediaQueries";
import useTct from "../../../../utils/useTct";
import ConfirmModal from "../../../MuiComponents/Modals/ConfirmModal";
import NavigationListButton from "../../../MuiComponents/NavigationListButton";

const Info = () => {
    const {
        colors: { white, gray100 },
    } = useContext(ThemeContext);

    const { t, selectedLanguage } = useContext(LanguageContext);

    const { isDesktopApplication, isDeviceDisconnected } =
        useContext(UserInfoContext);

    const {
        layoutData,
        actionLoading,
        resetLayoutData,
        setDeviceConfigureButtonPortName,
    } = useContext(LayoutContext);

    const { isEditor } = useContext(AppTypeContext);

    const history = useHistory();

    const { postData } = useApi();
    const { getModifiedParameters } = useTct();

    const { fromMd } = useMediaQueries();

    const setIoCurrentValues = useSetAtom(IoStatusAtom);
    const [loading, setLoading] = useState(false);
    const [isNavigationDisabledModalOpen, setNavigationDisabledModal] =
        useState(false);

    const isSubscribed = useRef(true);

    useEffect(() => {
        return () => {
            isSubscribed.current = false;
        };
    }, []);

    const goToStartup = () => {
        history.replace(START_UP);
        resetLayoutData();
    };

    const disconnectDevice = async () => {
        try {
            setLoading(true);
            await postData(`${selectedLanguage}/device/disconnect`);
            goToStartup();
        } catch (error) {
            goToStartup();
        } finally {
            setDeviceConfigureButtonPortName(layoutData?.port || "");
            setIoCurrentValues({});
        }

        isSubscribed.current && setLoading(false);
    };

    const leaveEditor = async (ignoreModifiedParameters: boolean) => {
        if (!layoutData) {
            goToStartup();
        }

        const modifiedParameters = await getModifiedParameters(layoutData!.id);
        if (modifiedParameters.parameters.length && !ignoreModifiedParameters) {
            setNavigationDisabledModal(true);
        } else {
            layoutData!.isOnline ? await disconnectDevice() : goToStartup();
        }
    };

    const handleOnModalClose = () => setNavigationDisabledModal(false);
    const handleOnModalSubmit = () => leaveEditor(true);

    const disconnectBtnText =
        layoutData?.isOnline && !isDeviceDisconnected
            ? t.Disconnect
            : "Change device";

    const renderButton = () => {
        if (!isDesktopApplication && isEditor) {
            return null;
        }
        if (loading) {
            return (
                <NavigationListButton
                    fullWidth
                    disabled={actionLoading}
                    idForTest={"loadingBtn"}
                    isActive
                    narrow
                    icon={
                        <div
                            css={css({
                                width: "20px",
                                height: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            })}
                        >
                            <CircularProgress
                                size={20}
                                css={css`
                                    color: ${white};
                                `}
                            />
                        </div>
                    }
                    css={css({
                        pointerEvents: "none",
                    })}
                ></NavigationListButton>
            );
        }
        return (
            <NavigationListButton
                fullWidth
                disabled={actionLoading}
                onClick={() => leaveEditor(false)}
                idForTest={"disconnectBtn"}
                narrow
                isActive
            >
                {disconnectBtnText}
            </NavigationListButton>
        );
    };

    return (
        <div
            css={css({
                "> div": {
                    color: gray100,
                    fontSize: "12px",
                    lineHeight: "16px",
                    letterSpacing: "0.16px",
                    paddingLeft: "8px",
                    wordBreak: "break-word",
                },
            })}
        >
            {fromMd && (
                <Fragment>
                    <div
                        css={css({
                            display: "flex",
                            marginBottom: "6px",
                            gap: "8px",
                            alignItems: "center",
                        })}
                    >
                        {layoutData?.isOnline && !isDeviceDisconnected ? (
                            <OnlineStatusIcon />
                        ) : (
                            <OfflineStatusIcon />
                        )}
                        <span
                            css={css({
                                fontWeight: 600,
                                fontSize: "14px",
                                lineHeight: "20px",
                                letterSpacing: "0.1px",
                            })}
                        >
                            {layoutData?.fmType}
                        </span>
                    </div>
                </Fragment>
            )}
            <div
                css={css`
                    margin: 4px 0;
                `}
            >
                {"IMEI"}: {layoutData?.imei || NOT_AVAILABLE_VALUE}
            </div>
            <div
                css={css({
                    marginBottom: "4px",
                })}
            >
                {t.Firmware}: {layoutData?.title}
            </div>
            <div
                css={css({
                    marginBottom: "8px",
                })}
            >
                {(layoutData?.specId || 0) > 1 &&
                    `Spec ID: ${layoutData?.specId}`}
            </div>

            {renderButton()}
            <ConfirmModal
                open={isNavigationDisabledModalOpen}
                close={handleOnModalClose}
                submit={handleOnModalSubmit}
                title={t.LeaveWithoutSaving}
                description={t["LeaveWithoutSaving.description"]}
            />
        </div>
    );
};

export default Info;
