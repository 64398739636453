/** @jsxRuntime classic */

/** @jsx jsx */
import { ChangeEvent, Fragment, useContext, useState } from "react";

import { css, jsx } from "@emotion/react";

import { MoreVert } from "@mui/icons-material";
import { Menu, MenuItem } from "@mui/material";

import LanguageContext from "../../context/language/languageContext";
import ThemeContext from "../../context/theme/themeContext";

import Label from "./Label";
import SwitchComponentWithLabel from "./Switch/SwitchComponentWithLabel";
import Tooltip from "./Tooltip";

export interface InputBlockWithActionsProps {
    actions: {
        icon: any;
        label: string;
        onClick: () => void;
        disabledTooltipText?: string;
        isDisabled?: boolean;
    }[];
    label: string;
    onSwitchChange?: (value: ChangeEvent<HTMLInputElement>) => void;
    checkedValue?: boolean;
    switchLabel?: string;
    isSwitchDisabled?: boolean;
    disabledSwitchTooltip?: string;
    valueLabel?: string;
    info?: any;
}

const InputBlockWithActions: React.FunctionComponent<
    InputBlockWithActionsProps
> = ({
    actions,
    label,
    onSwitchChange,
    checkedValue,
    switchLabel,
    isSwitchDisabled,
    disabledSwitchTooltip,
    valueLabel,
    info,
}) => {
    const {
        colors: { gray100 },
    } = useContext(ThemeContext);
    const { t } = useContext(LanguageContext);

    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = (e: any) => setAnchorEl(e.currentTarget);
    const closeMenu = () => setAnchorEl(null);

    return (
        <Fragment>
            <div
                css={css({
                    width: "100%",
                    display: "flex",
                    gap: "8px",
                    flexDirection: "column",
                    marginBottom: "24px",
                })}
            >
                <Label title={label} info={info} />
                <div
                    css={css({
                        padding: "9px 12px",
                        borderRadius: "6px",
                        border: "1px solid #D9D9D9",
                        display: "flex",
                        gap: "8px",
                        justifyContent: "space-between",
                    })}
                >
                    {onSwitchChange && (
                        <SwitchComponentWithLabel
                            checked={checkedValue}
                            idForTesting={label}
                            size="medium"
                            label={switchLabel}
                            onChange={onSwitchChange}
                            isDisabled={isSwitchDisabled}
                            disabledSwitchTooltip={disabledSwitchTooltip}
                        />
                    )}
                    {valueLabel && <Label title={valueLabel} />}
                    <Tooltip title={t.Actions}>
                        <span
                            css={css({
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center",
                                padding: "4px",
                                borderRadius: "6px",
                                "&:hover": {
                                    backgroundColor: gray100,
                                },
                            })}
                            onClick={openMenu}
                        >
                            <MoreVert
                                css={css({
                                    width: "16px",
                                    height: "16px",
                                })}
                            />
                        </span>
                    </Tooltip>
                </div>
            </div>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={closeMenu}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                {actions.map((action) => {
                    return (
                        <Tooltip
                            title={action.disabledTooltipText}
                            key={action.label}
                        >
                            <span
                                css={css({
                                    maxWidth: "166px",
                                    width: "100%",
                                })}
                            >
                                <MenuItem
                                    onClick={action.onClick}
                                    disabled={action.isDisabled}
                                >
                                    {action.icon}
                                    {action.label}
                                </MenuItem>
                            </span>
                        </Tooltip>
                    );
                })}
            </Menu>
        </Fragment>
    );
};

export default InputBlockWithActions;
