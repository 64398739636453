import { useContext, useEffect } from "react";

import {
    UseFiltersInstanceProps,
    UseGlobalFiltersInstanceProps,
} from "react-table";

import InputOutputContext from "../../../../../../../context/inputOutput/inputOutputContext";

interface UseGenericTableEffectsProps {
    setFilter: UseFiltersInstanceProps<any>["setFilter"];
    setGlobalFilter: UseGlobalFiltersInstanceProps<any>["setGlobalFilter"];
}

export const useGenericTableEffects = ({
    setFilter,
    setGlobalFilter,
}: UseGenericTableEffectsProps): void => {
    const { searchValue, activeStatus } = useContext(InputOutputContext);

    useEffect(() => {
        setGlobalFilter(searchValue);
    }, [searchValue]);

    useEffect(() => {
        setFilter("0", activeStatus !== "2" ? activeStatus : undefined);
    }, [activeStatus]);
};
